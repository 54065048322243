export const debugCategories = {
  NET: 'NET',
  MAP: 'MAP',
  MISC: 'MISC',
};
const debugColors = {
  NET: '#0085ff',
  MAP: '#11b700',
  MISC: '#777',
};

export function createDebugLogger(category = 'MISC') {
  return (...args) => {
    if (process.env.VUE_APP_DEBUG) {
      // eslint-disable-next-line
      console.info(
        '%c[ DEBUG ]%c %c[ %s ]%c',
        'background-color: #e15d26; color: #fff',
        'color: auto',
        `background-color: ${debugColors[category]}; color: #fff`,
        category,
        'color: auto',
        ...args,
      );
    }
  };
}
