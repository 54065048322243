<template>
  <div>
    <form
      enctype="multipart/form-data"
      novalidate
    >
      <div class="dropbox">
        <input
          type="file"
          :multiple="!singleFile"
          :name="uploadFieldName"
          :disabled="uploading"
          :accept="joinedFileExts"
          class="input-file"
          @change="handleFileChange"
        >
        <p v-show="!uploading">
          <slot>Drag your file<span v-if="!singleFile">s</span> here</slot>
          <br>or click to browse
        </p>
        <div
          v-show="uploading"
          class="uploading-group"
        >
          <div class="upload-details">
            Uploading {{ fileList.length }} files ({{
              bytesLoaded | filesize
            }}
            / {{ bytesTotal | filesize }})
          </div>
          <div class="upload-progress">
            <el-progress v-bind="progressProps" />
          </div>
        </div>
      </div>
    </form>

    <div class="file-list">
      <div
        v-for="(file, index) in fileList"
        :key="file.name + file.size"
      >
        <el-tag
          closable
          @close="handleRemoveFile(index)"
        >
          {{ file.name }} - {{ file.size | filesize }}
        </el-tag>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

function upload(formData) {
  return axios.post(
    process.env.VUE_APP_ADONIS_ENDPOINT + this.apiEndpoint,
    formData,
    {
      headers: {
        Authorization: `Bearer ${this.$store.state.auth.token}`,
      },
      onUploadProgress: progress => {
        this.bytesTotal = progress.total;
        this.bytesLoaded = progress.loaded;
      },
    },
  );
}

export default {
  props: {
    acceptFileExts: {
      type: Array,
      default: () => ['.csv'],
    },
    apiEndpoint: { type: String, required: true },
    singleFile: { type: Boolean, default: false },
    uploadFieldName: { type: String, required: true },
  },

  data() {
    return {
      bytesLoaded: 0,
      bytesTotal: 0,
      fileList: [],
      uploadError: null,
      uploading: false,
    };
  },

  computed: {
    joinedFileExts() {
      return this.acceptFileExts.join(',');
    },

    percentComplete() {
      return this.bytesTotal === 0
        ? 0
        : parseInt((this.bytesLoaded * 100) / this.bytesTotal);
    },

    progressProps() {
      return {
        percentage: this.percentComplete,
        showText: false,
        ...this.percentComplete ? { status: 'success' } : {},
      };
    },
  },

  watch: {
    uploading(isUploading) {
      this.$emit('upload-state-change', isUploading);
    },
  },

  mounted() {
    this.reset();
  },

  methods: {
    handleFileChange({ target }) {
      // Strictly enforce defined file extensions
      const files = [...target.files];
      let invalid = false;
      for (let i = files.length - 1; i >= 0; i--) {
        if (
          !this.acceptFileExts.includes(`.${files[i].name.split('.').pop()}`)
        ) {
          files.splice(i, 1);
          invalid = true;
        }
      }
      if (invalid) {
        this.$notify.warning({
          message: `Please select only files with extension${
            this.acceptFileExts.length > 1 ? 's' : ''
          }: ${this.acceptFileExts.join(', ')}`,
        });
      }
      this.fileList = this.singleFile
        ? [...files]
        : [...this.fileList, ...files];

      this.$emit('file-change', this.fileList);

      /**
       * In case they clear a file & re-add it again. Otherwise, onChange won't
       * be fired.
       */
      target.value = '';
    },

    handleRemoveFile(index) {
      this.fileList.splice(index, 1);
    },

    reset() {
      // reset form to initial state
      this.fileList = [];
      this.uploadError = null;
    },

    async startUpload(additionalData) {
      // upload data to the server
      const formData = new FormData();

      if (!this.fileList.length) return;

      for (const file of this.fileList) {
        formData.append(this.uploadFieldName, file, file.name);
      }

      if (
        typeof additionalData === 'object' &&
        Object.keys(additionalData).length > 0
      ) {
        for (const key in additionalData) {
          formData.append(key, additionalData[key]);
        }
      }

      try {
        this.uploading = true;
        await this.$store.dispatch('auth/refreshToken');
        const response = await upload.call(this, formData);
        this.$emit('complete', response.data);
      } catch (e) {
        this.$reportError(e);
        this.uploadError = e.response;
        this.$emit('error', { message: e.response.data.error });
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: #eee;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #ddd; /* when mouse over to the drop zone, change color */
}

.dropbox p,
.uploading-group {
  // font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.file-list {
  margin-top: 20px;

  > div:not(:last-child) {
    margin-bottom: 6px;
  }
}

.upload-progress {
  margin: 20px 20px 0 20px;
}
</style>
