export default {
  scroller(el, binding) {
    el.style.boxSizing = 'border-box';
    el.style.height = '100%';
    el.style.overflow = 'auto';
    el.style.padding = '1em';

    if (binding.arg === 'max-height') {
      el.style.maxHeight = `${binding.expression}px`;
    }
  },
};
