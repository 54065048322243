import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      $_jw_jobIds: [],
    };
  },

  computed: {
    ...mapGetters('mqtt', ['getJobs']),
    watchedJobs() {
      return this.getJobs(this.$data.$_jw_jobIds);
    },
  },

  methods: {
    clearWatchedJobs() {
      this.$data.$_jw_jobIds = [];
    },

    watchJob(jobId) {
      if (!this.$data.$_jw_jobIds.includes(jobId)) {
        this.$data.$_jw_jobIds.push(jobId);
      }
    },

    setWatchedJobs(jobIds) {
      this.$data.$_jw_jobIds = jobIds;
    },
  },
};
