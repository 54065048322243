<template>
  <div>
    <h3>Publish Schedule</h3>
    <div>
      <table class="options">
        <tbody>
          <tr>
            <td>Publish Type</td>
            <td v-if="schedule.allowedTypes.length === 1">
              {{ schedule.allowedTypes[0] }}
              <publish-scheduler-explainer :type="form.scheduleType" />
            </td>
            <td v-else>
              <el-select
                v-model="form.scheduleType"
                size="mini"
              >
                <el-option
                  v-for="schedType in schedule.allowedTypes"
                  :key="schedType"
                  :label="schedType"
                  :value="schedType"
                />
              </el-select>
              <publish-scheduler-explainer
                left-padding
                :type="form.scheduleType"
              />
            </td>
          </tr>
          <tr>
            <td>Starting</td>
            <td>
              <el-date-picker
                v-model="form.startDate"
                :clearable="false"
                :picker-options="startOptions"
                size="mini"
              />
              <help-tip style="margin-left: 0.5em">
                If the publish start date you select is in the future,
                publishing will be delayed until that date.
              </help-tip>
            </td>
          </tr>
          <tr>
            <td>Frequency</td>
            <!-- 6 mo max -->
            <td>
              Every
              <el-select
                v-model="form.freqValue"
                class="left-spacing"
                style="width: 64px"
                size="mini"
              >
                <el-option
                  v-for="num in frequencyMax"
                  :key="num"
                  :label="num"
                  :value="num"
                />
              </el-select>
              <el-select
                v-model="form.freqUnit"
                class="left-spacing"
                style="width: 100px"
                size="mini"
              >
                <el-option value="days" />
                <el-option value="weeks" />
                <el-option value="months" />
              </el-select>
            </td>
          </tr>
          <tr v-if="lookbackEnabled">
            <td>Lookback</td>
            <!-- 30 days max -->
            <td>
              <el-select
                v-model="form.lookbackValue"
                style="width: 64px"
                size="mini"
              >
                <el-option
                  v-for="num in lookbackMax"
                  :key="num"
                  :label="num"
                  :value="num"
                />
              </el-select>
              <el-select
                v-model="form.lookbackUnit"
                class="left-spacing"
                style="width: 100px"
                size="mini"
              >
                <el-option value="days" />
                <el-option value="weeks" />
                <el-option value="months" />
              </el-select>
            </td>
          </tr>
          <tr>
            <td>Until</td>
            <td>
              <el-date-picker
                v-model="form.untilDate"
                :clearable="false"
                :picker-options="untilOptions"
                size="mini"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="summary">
        <el-alert
          :closable="false"
          title="Publishing Summary"
          type="success"
        >
          <template v-if="publishSummaryCount > 1">
            This audience ({{ scopeDetail }}) will begin publishing {{ publishSummaryStart }}, and
            publish {{ publishSummaryCount }} more times until
            {{ publishSummaryUntil }}.
          </template>
          <template v-else-if="publishSummaryCount === 1">
            This audience ({{ scopeDetail }}) will begin publishing {{ publishSummaryStart }}, and
            publish one more time until
            {{ publishSummaryUntil }}.
          </template>
          <template v-else>
            This audience ({{ scopeDetail }}) will be published once, {{ publishSummaryStart }}.
          </template>
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import HelpTip from '../global/HelpTip.vue';
import { dateToString } from '@/helpers';
import PublishSchedulerExplainer from './PublishSchedulerExplainer.vue';

const moment = require('moment-recur');

export default {
  components: { HelpTip, PublishSchedulerExplainer },
  props: {
    schedule: { type: Object, default: () => ({}) },
    scope: { type: Array, default: () => ['Regular'] },
  },

  data() {
    const lookbackUnit = _get(this.schedule, 'lookback[1]');
    const lookbackValue = _get(this.schedule, 'lookback[0]');

    return {
      form: {
        freqUnit: this.schedule.frequency[1],
        freqValue: this.schedule.frequency[0],
        lookbackUnit,
        lookbackValue,
        scheduleType: this.schedule.allowedTypes
          ? this.schedule.allowedTypes[0]
          : null,
        startDate: this.schedule.from,
        untilDate: this.schedule.until,
      },
      startOptions: {
        disabledDate(date) {
          return moment(date).isBefore(moment(), 'day');
        },
      },
      untilOptions: {
        disabledDate(date) {
          return moment(date).isBefore(moment(), 'day');
        },
      },
    };
  },

  computed: {
    frequency() {
      return `Every ${this.schedule.frequency.join(' ')}`;
    },

    frequencyMax() {
      switch (this.form.freqUnit) {
        case 'days':
        default:
          return 30;
        case 'weeks':
          return 12;
        case 'months':
          return 6;
      }
    },

    lookbackEnabled() {
      return (
        this.schedule.type !== 'RENEW' &&
        this.form.lookbackValue &&
        this.form.lookbackUnit
      );
    },

    lookbackMax() {
      switch (this.form.lookbackUnit) {
        case 'days':
        default:
          return 30;
        case 'weeks':
          return 24;
        case 'months':
          return 6;
      }
    },

    publishSummaryCount() {
      return moment()
        .recur({ start: this.schedule.from, end: this.schedule.until })
        .every(...this.schedule.frequency)
        .all().length - 1;
    },

    publishSummaryStart() {
      return moment(this.schedule.from)
        .startOf('day')
        .isSame(moment().startOf('day'))
        ? 'immediately'
        : `on ${dateToString(this.schedule.from, {
          formatOverride: 'dddd, MMM Do YYYY',
        })}`;
    },

    publishSummaryUntil() {
      return dateToString(this.schedule.until, {
        formatOverride: 'dddd, MMM Do YYYY',
      });
    },

    scopeDetail() {
      return this.scope.join(', ');
    },
  },

  watch: {
    form: {
      handler(form, oldForm) {
        if (form.startDate > form.untilDate) form.untilDate = form.startDate;
        const options = {
          allowedTypes: this.schedule.allowedTypes, // hacky, but whatever
          frequency: [form.freqValue, form.freqUnit],
          from: form.startDate,
          type: form.scheduleType,
          until: form.untilDate,
        };

        if (this.lookbackEnabled) {
          options.lookback = [form.lookbackValue, form.lookbackUnit];
        }

        this.$emit('update:schedule', options);
      },
      deep: true,
    },

    'form.freqUnit'(value, oldValue) {
      if (value !== oldValue) this.form.freqValue = 1;
    },

    'form.lookbackUnit'(value, oldValue) {
      if (value !== oldValue) this.form.lookbackValue = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  line-height: 1.8;

  tr > td:first-of-type {
    font-weight: bold;
    padding-right: 1em;
  }
}

.left-spacing {
  margin-left: 0.4em;
}

.summary {
  margin-top: 1em;
}
</style>
