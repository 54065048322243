<template>
  <div :class="jobClass">
    <div class="status">
      <div v-if="jobRunning && jobPercentage === 0">
        <indeterminate-progress :stroke-width="3.5" />
      </div>
      <el-progress
        v-else
        v-bind="progressProps"
      >
        <div class="icon">
          <i
            v-if="jobFailed"
            class="fa fa-fw fa-exclamation"
          />
          <i
            v-else-if="jobComplete"
            class="fa fa-fw fa-check"
          />
          <i
            v-else
            class="far fa-fw fa-ellipsis-h pulsate"
          />
        </div>
      </el-progress>
    </div>
    <div class="content">
      <div class="label">
        {{ job.payload.label }}
      </div>
      <div class="details">
        <span v-show="jobPercentage < 100 || jobPercentage === 100 && jobComplete">
          {{ jobPercentage }}% complete
        </span>
        <span v-show="!jobComplete && !jobFailed">
          (ETA {{ eta }})
        </span>
      </div>
    </div>
    <div
      v-if="isInternal"
      class="view-job-btn"
    >
      <el-button
        size="mini"
        @click="handleViewJob"
      >
        View Job
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IndeterminateProgress from '../IndeterminateProgress.vue';
import { secondsToString } from '@/helpers';

export default {
  components: { IndeterminateProgress },
  props: {
    job: { type: Object, required: true },
  },

  computed: {
    ...mapGetters('user', ['isInternal']),

    eta() {
      if (!this.job.payload.eta) {
        return 'unknown';
      }

      if (this.job.payload.eta && this.job.payload.eta.eta && !this.job.payload.eta.etah) {
        return secondsToString(this.job.payload.eta.eta);
      }
      return this.job.payload.eta.etah
        ? this.job.payload.eta.etah.toLowerCase()
        : 'unknown';
    },

    jobClass() {
      return {
        job: true,
        spacing: true,
        failed: this.job.payload.status === 'FAILED',
      };
    },

    jobComplete() {
      return this.job.payload.status === 'COMPLETE';
    },

    jobFailed() {
      return this.job.payload.status === 'FAILED';
    },

    jobPercentage() {
      if (this.job.payload.status === 'FAILED') return 100;
      if (!this.job.payload.eta) {
        if (this.jobComplete) return 100;
        return 0;
      }

      const percentage = parseFloat(this.job.payload.eta.progress);
      return Number((percentage * 100).toFixed(2));
    },

    jobRunning() {
      return !this.jobComplete && !this.jobFailed;
    },

    progressProps() {
      return {
        percentage: this.jobPercentage,
        ...this.jobFailed ? { status: 'exception' } : {},
        strokeWidth: 2,
        type: 'circle',
        width: 32,
      };
    },
  },

  methods: {
    handleViewJob() {
      const jobRoute = this.$router.resolve({
        path: `/jobs/${this.job.id}`,
      });

      window.open(jobRoute.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.job {
  border-radius: 4px;
  // color: darken($--color-primary, 30%);
  display: flex;
  font-size: 0.9em;
  padding: 8px;

  &.failed {
    color: darken($--color-danger, 10%);
  }
}

.status {
  margin-right: 8px;

  .icon {
    color: darken($--color-primary, 30%);
    font-size: 3em;
  }
}

.content {
  flex: 1;
}

.view-job-btn {
  margin-left: 8px;
}

.spacing:not(:first-child) {
  margin-top: 12px;
}

.fa-cog {
  left: -1px;
  position: relative;
  top: -1px;
}

.percentage {
  font-size: 0.7em;
  position: relative;
  top: -1px;
}

.label {
  font-size: 1.1em;
  font-weight: bold;
}

.details {
  margin-top: 4px;
}

:deep( .is-exception .el-progress__text) {
  // font-size: 16px !important;
}

:deep( .el-progress-circle__track) {
  stroke: rgba(255, 255, 255, 0.7);
}

.pulsate {
  animation: slidein 1s ease infinite alternate;
}

@keyframes slidein {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0.2;
  }
}
</style>
