import * as mutation from './mutation-types';

const state = {
  chosenAudience: {},
  bulkIds: [],
  cloneJobId: 0,
  dialog: '',
  dialogStack: [],
  savedAudienceIds: {},
};

const actions = {
  closeDialog({ commit }) {
    commit(mutation.CLOSE_DIALOG);
  },

  completedSave() {}, // don't care, this is just for components to subscribe to

  showDialog({ commit }, payload) {
    if (!Array.isArray(payload)) {
      commit(mutation.SHOW_DIALOG, {
        dialog: payload.dialog,
        audience: payload.audience,
        bulkIds: payload.bulkIds ? payload.bulkIds : [],
      });
    } else {
      // Array means we'll show the n+1th dialog after the nth closes
      commit(mutation.PUSH_DIALOGS, payload.slice(1));
      commit(mutation.SHOW_DIALOG, {
        dialog: payload[0].dialog,
        audience: payload[0].audience,
      });
    }
  },

  startedClone({ commit }, jobId) {
    commit(mutation.STARTED_CLONE, jobId);
  },

  saveAudienceConfigs({ commit }, audienceId) {
    commit(mutation.SET_SAVED_AUDIENCE, audienceId);
  },
};

const mutations = {
  [mutation.CLOSE_DIALOG](state) {
    if (state.dialogStack.length === 0) {
      state.chosenAudience = {};
      state.dialog = '';
    } else {
      const opts = state.dialogStack.shift();

      state.chosenAudience = opts.audience;
      state.dialog = opts.dialog;
    }

    state.bulkIds = [];
  },

  [mutation.PUSH_DIALOGS](state, dialogs) {
    state.dialogStack.push(...dialogs);
  },

  [mutation.SHOW_DIALOG](state, { dialog, audience, bulkIds }) {
    state.chosenAudience = audience;
    state.dialog = dialog;
    state.bulkIds = bulkIds;
  },

  [mutation.STARTED_CLONE](state, jobId) {
    state.cloneJobId = jobId;
  },

  [mutation.SET_SAVED_AUDIENCE](state, audienceId) {
    state.savedAudienceIds[audienceId] = true;
  },
};

const getters = {
  isAudienceSaved: (state) => (audienceId) => {
    return state.savedAudienceIds[audienceId] || false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
