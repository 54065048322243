import apiCall from './api-call';
import { createCrud } from './common';

export default {
  name: 'geoframe',
  ...createCrud('geoframes'),

  async getCount(geoframe) {
    let result;

    if (typeof geoframe === 'object') {
      const data = {
        geojson: geoframe.geojson,
        name: geoframe.name,
        organization_id: geoframe.organizationId,
        start_date: geoframe.startDate,
        end_date: geoframe.endDate,
      };
      result = await apiCall('POST', '/geoframes/count', null, data);
    } else {
      // geoframe is an id
      result = await apiCall('PUT', `/geoframes/${geoframe}/count`);
    }

    return result.data; // job
  },

  exportGeojson(geoframeId) {
    return apiCall('GET', `/geoframes/${geoframeId}/export`);
  },

  import({
    uuid,
    historical,
    namingPattern,
    organizationId,
    start,
    end,
    tags,
  }) {
    return apiCall('POST', '/geoframes/import', null, {
      name: namingPattern,
      historical,
      start_date: start,
      end_date: end,
      uuid,
      type: 'GEOJSON',
      tags,
      organization_id: organizationId,
    });
  },
};
