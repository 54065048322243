import { createCrud } from './common';
import apiCall from './api-call';

export default {
  name: 'dashboard',
  ...createCrud('dashboard'),

  async getMetrics(startDate, endDate, orgId, incDesc) {
    return apiCall('GET', '/dashboard', {
      start_date: startDate,
      end_date: endDate,
      organization_id: orgId,
      include_descendants: incDesc,
    });
  },

  async generateReport(startDate, endDate, selectedOrg) {
    return apiCall('GET', '/dashboard/report', {
      start_date: startDate,
      end_date: endDate,
      organization_id: selectedOrg,
    });
  },
};
