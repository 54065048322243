<template>
  <div>
    <el-dialog
      title="Publish"
      :visible="showDialog === 'publish'"
      :before-close="closeDialog"
    >
      <el-form>
        <el-form-item label="Publish To">
          <publish-picker
            :audience-type="audience.type"
            :organization="audience.organization"
            @select="handleCredentialChoice"
          />
        </el-form-item>
        <el-form-item
          v-show="publishScopeOptions.length > 1"
          label="Publish Scope"
        >
          <div>
            <!-- Should only display if extended publish is available for audience type (ie: not a demographic or import audience) -->
            <el-checkbox-group
              v-model="publishScope"
              size="small"
            >
              <el-checkbox-button
                v-for="scope in publishScopeOptions"
                :key="scope"
                :label="scope"
                :disabled="
                  scope === 'Lookalike Extension' && lookalikeLimitNotice
                "
              >
                {{ scope }}
              </el-checkbox-button>
            </el-checkbox-group>
            <div
              v-if="lookalikeLimitNotice"
              class="lookalike-notice"
            >
              <strong>Note:</strong> Source audiences for lookalike extension
              processing can not exceed 150K devices. For more information,
              please contact your support rep.
            </div>
          </div>
          <el-form-item v-if="canSplit">
            <el-checkbox v-model="splitAudience">
              Split audience &amp; publish separate geoframes
            </el-checkbox>
          </el-form-item>
        </el-form-item>
        <el-form-item
          v-if="desktopPublishAllowed"
          label="Device Type"
        >
          <el-radio-group v-model="deviceType">
            <el-radio label="MOBILE">
              Mobile
            </el-radio>
            <el-radio label="DESKTOP">
              Desktop/CTV
            </el-radio>
            <el-radio label="BOTH">
              Both
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div v-if="openx">
        <el-switch
          v-model="ctv"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="CTV"
        />

        <el-autocomplete
          v-model="selectedDsp"
          style="margin-left:25px"
          :fetch-suggestions="querySearch"
          placeholder="Select DSP"
          @select="addDspTag"
        />
        <div
          v-if="xandr"
          style="margin-top: 25px; margin-bottom: 25px; width: 25%"
        >
          <el-input
            v-model="xandrBuyerId"
            placeholder="Xandr Buyer ID"
          />
          <br>
        </div>
        <el-tag
          v-for="tag in dspTags"
          :key="tag"
          style="margin-right: 15px"
          closable
          :disable-transitions="false"
          @close="handleCloseDSPTag(tag)"
        >
          {{ tag }}
        </el-tag>
      </div>

      <div v-if="tiktok">
        <h3>TikTok Ad Account</h3>
        <el-button
          v-if="!tiktokConnected"
          :loading="tiktokLoading"
          round
          @click="tiktokOauth"
        >
          Connect to TikTok
        </el-button>
        <el-button
          v-if="tiktokConnected"
          round
          style="margin-bottom:1em;"
          @click="tiktokDisconnect"
        >
          Disconnect TikTok
        </el-button>
        <div
          v-if="tiktokConnected"
          v-loading="tiktokLoading"
        >
          <el-select
            v-if="tiktokAdAccounts.length > 0"
            v-model="tiktok_account"
            filterable
          >
            <el-option
              v-for="account in tiktokAdAccounts"
              :key="account.id"
              :value="account.id"
              :label="account.name"
            />
          </el-select>
        </div>
      </div>

      <div v-if="facebook">
        <h3>Facebook Ad Account</h3>
        <v-facebook-login
          v-model="facebookStatus"
          class="facebook-button"
          :app-id="facebookAppId"
          :login-options="{
            scope: 'ads_management,ads_read,business_management',
          }"
          @sdk-init="handleFacebookInit"
          @login="handleFacebookLogin"
          @logout="handleFacebookLogout"
        >
          <template v-slot:login>
            Connect to Facebook
          </template>
          <template v-slot:logout>
            Disconnect from Facebook
          </template>
        </v-facebook-login>
        <div
          v-if="fbConnected"
          v-loading="facebookLoading"
        >
          <el-select
            v-if="facebookAdAccounts.length > 0"
            v-model="facebook_account"
            filterable
          >
            <el-option
              v-for="account in facebookAdAccounts"
              :key="account.id"
              :value="account.id"
              :label="account.name"
            />
          </el-select>
        </div>
      </div>

      <div v-if="hasScheduleRules && !hideScheduler">
        <publish-scheduler
          :schedule.sync="schedule"
          :scope="publishScope"
        />
      </div>
      <div v-if="hideScheduler">
        <p>
          There are no scheduling options available for the selected publish
          partner. Your publication will run one time, immediately.
        </p>
      </div>

      <div
        v-if="daily_batch"
        class="summary"
      >
        <el-alert
          :closable="false"
          title="Batched Publish"
          type="warning"
        >
          All publications to this publisher will be collected and run together
          in a daily batch at midnight UTC.
        </el-alert>
      </div>

      <span slot="footer">
        <el-button @click="closeDialog">Cancel</el-button>
        <el-button
          :disabled="!partnerInfo.id || busy || fbNotReady || ttNotReady"
          :loading="busy"
          type="primary"
          @click="startPublish"
        >
          {{ futurePublishDate ? 'Schedule' : 'Publish' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import { audience as audienceApi, facebook as facebookApi, tiktok as tiktokApi } from '@/adonis-api';
import PublishScheduler from './PublishScheduler.vue';
import PublishPicker from '../global/PublishPicker.vue';
import { dateToString, isDeviceAudience } from '@/helpers';
import {
  batchedPublishPartners,
  facebookAppId,
  lookalikeLimit,
  dspOptions,
} from '@/constants';
import VFacebookLogin from 'vue-facebook-login-component';

const initialState = {
  busy: false,
  ctv: false,
  deviceType: 'MOBILE',
  dspTags: [],
  xandr: false,
  xandrBuyerId: null,
  selectedDsp: null,
  facebookAdAccounts: [],
  facebookLoading: false,
  facebook_account: '',
  partnerInfo: {},
  partners: [],
  publishCredentials: [],
  publishScope: ['Regular'],
  schedule: {},
  splitAudience: false,
  tiktok_account: '',
};

export default {
  components: { PublishPicker, PublishScheduler, VFacebookLogin },
  props: {
    audience: { type: Object, default: () => {} },
    ids: { type: Array, default: () => [] },
    showDialog: { type: String, required: true },
  },

  data() {
    return {
      busy: false,
      ctv: false,
      deviceType: 'MOBILE',
      dspTags: [],
      xandr: false,
      selectedDsp: null,
      xandrBuyerId: null,
      facebookAppId: facebookAppId,
      facebookAdAccounts: [],
      facebookLoading: false,
      facebookStatus: {},
      facebook_account: '',
      partnerInfo: {},
      partners: [],
      publishCredentials: [],
      publishScope: ['Regular'],
      schedule: {},
      splitAudience: false,
      tiktok_account: '',
      tiktokAdAccounts: [],
      tiktokLoading: false,
      tiktokOauthUrl: null,
      tiktokOauthWindow: null,
    };
  },

  computed: {
    ...mapGetters('user', ['permissions', 'hasPermission', 'facebookId', 'tiktokConnected']),
    ...mapGetters('mqtt', ['getJobs']),

    // publishJobs() {
    //   return this.getJobs(this.watchJobIds);
    // },

    loadDspOptions() {
      return dspOptions;
    },

    canSplit() {
      return (
        this.hasPermission('split_publish') && // User has permission
        this.polygonAudience && // Audience is polygon-based
        !this.audience.was_split && // Audience was not already split
        !this.audience.parent_audience_id
      ); // Audience is not child of a split audience
    },

    desktopPublishAllowed() {
      return (
        this.hasPermission('ip_audience_publish') &&
        !!_get(this.partnerInfo, 'ipPublishing')
      );
    },

    daily_batch() {
      return (
        !!_get(this.partnerInfo, 'partner') &&
        batchedPublishPartners.includes(_get(this.partnerInfo, 'partner'))
      );
    },

    fbConnected() {
      return !!_get(this.facebookStatus, 'connected');
    },

    polygonAudience() {
      return !isDeviceAudience(this.audience.type);
    },

    facebook() {
      return !!(_get(this.partnerInfo, 'partner') === 'facebook');
    },

    tiktok() {
      return !!(_get(this.partnerInfo, 'partner') === 'tiktok');
    },

    openx() {
      return !!(_get(this.partnerInfo, 'partner') === 'openx');
    },

    fbNotReady() {
      return !!(this.facebook && !this.facebook_account);
    },

    ttNotReady() {
      return !!(this.tiktok && !this.tiktok_account);
    },

    futurePublishDate() {
      return moment(this.schedule.from).isAfter(moment(), 'day');
    },

    hasScheduleRules() {
      return (
        _get(this.partnerInfo, 'scheduleRules') &&
        Object.keys(this.partnerInfo.scheduleRules).length > 0
      );
    },

    hideScheduler() {
      return (
        _get(this.schedule, 'allowedTypes', []).length === 1 &&
        this.schedule.allowedTypes[0] === 'RENEW' &&
        !_get(this.partnerInfo, 'scheduleRules.allowed_types', []).includes(
          'RENEW',
        )
      );
    },

    lookalikeLimitNotice() {
      return (
        this.hasPermission('extend_lookalike') &&
        this.audience.count > lookalikeLimit
      );
    },

    publishScopeOptions() {
      const options = ['Regular'];

      if (
        this.hasPermission('extend_household') &&
        (this.polygonAudience || !this.neustar)
      ) {
        options.push('Household Extension');
      }

      if (!this.neustar) {
        if (this.hasPermission('extend_lookalike')) {
          options.push('Lookalike Extension');
        }

        if (this.hasPermission('extend_social')) {
          options.push('Social Extension');
        }

        // if (this.hasPermission('extend_legacy') && this.polygonAudience) {
        //   options.push('Legacy HH Extension');
        // }
      }

      return options;
    },

    neustar() {
      return !!(_get(this.partnerInfo, 'partner') === 'neustar');
    },
  },

  watch: {
    facebook(value) {
      if (
        value === true &&
        this.fbConnected &&
        !this.facebookLoading &&
        this.facebookAdAccounts.length < 1
      ) {
        this.loadFacebookAccounts();
      }
    },

    fbConnected: {
      async handler(fb) {
        if (fb && this.facebookId) {
          this.loadFacebookAccounts();
        }
      },
      immediate: true,
    },

    tiktok(value) {
      if (
        value === true &&
        this.tiktokConnected &&
        !this.tiktokLoading &&
        this.tiktokAdAccounts.length < 1
      ) {
        this.loadTikTokAccounts();
      }
    },

    tiktokConnected: {
      async handler(tt) {
        if (tt && this.tiktok) {
          this.loadTikTokAccounts();
        }
      },
      immediate: true,
    },
  },

  methods: {
    closeDialog() {
      this.busy = false;
      this.reset();
      this.$emit('close');
    },

    handleCredentialChoice(orgPubPartner) {
      const { scheduleRules } = orgPubPartner;
      this.partnerInfo = orgPubPartner;
      this.schedule = {
        allowedTypes: scheduleRules.type
          ? Object.freeze([scheduleRules.type])
          : Object.freeze(scheduleRules.allowed_types),
        frequency: scheduleRules.frequency,
        from: new Date(),
        type: scheduleRules.type || scheduleRules.allowed_types[0],
        until: moment()
          .add(...scheduleRules.duration)
          .toDate(),
      };

      if (scheduleRules.lookback) {
        this.schedule.lookback = scheduleRules.lookback;
      }

      // Device based audience?
      if (isDeviceAudience(this.audience.type)) {
        this.schedule.allowedTypes = ['RENEW'];
      }
    },

    tiktokDisconnect() {
      tiktokApi.disconnect()
        .then(() => {
          this.tiktok_account = '';
          this.tiktokAdAccounts = [];
          this.tiktokLoading = false;
        });
    },

    async tiktokOauth() {
      const uri = await tiktokApi.getAuthUrl();
      const left = (screen.width / 2 - 400);
      const top = (screen.height / 2 - 400);
      this.tiktokOauthWindow = window.open(uri, 'oauth window', 'resizable=yes, width=800, height=800, top=' + top + ', left=' + left);
    },

    async handleFacebookLogin(response) {
      if (response.status === 'connected') {
        this.facebookLoading = true;
        facebookApi
          .userLogin({
            facebook_id: response.authResponse.userID,
            facebook_token: response.authResponse.accessToken,
          })
          .then((data) => {
            if (data.success) {
              this.facebookLoading = false;
              this.facebookAdAccounts = data.accounts;
              // Default to first if only one option
              if (data.accounts.length === 1) {
                this.facebook_account = data.accounts[0].id;
              } else if (data.accounts.length < 1) {
                this.facebookAdAccounts = [];
                this.facebook_account = '';
                return this.$message({
                  type: 'warning',
                  message:
                    'You do not have any available business-associated ad accounts available to your facebook user account.',
                });
              }
            }
          })
          .catch((err) => {
            this.$message({
              type: 'error',
              message: 'Unable to load facebook ad accounts',
            });
            this.$reportError(err);
          });
      }
    },

    handleFacebookLogout() {
      facebookApi.userLogout();
    },

    loadFacebookAccounts() {
      this.facebookLoading = true;
      facebookApi
        .getAdAccounts()
        .then((data) => {
          if (data.success) {
            this.facebookLoading = false;
            this.facebookAdAccounts = data.accounts;
            // Default to first if only one option
            if (data.accounts.length === 1) {
              this.facebook_account = data.accounts[0].id;
            } else if (data.accounts.length < 1) {
              this.facebookAdAccounts = [];
              this.facebook_account = '';
              return this.$message({
                type: 'warning',
                message:
                  'You do not have any available business-associated ad accounts available to your facebook user account.',
              });
            }
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: 'Unable to load facebook ad accounts',
          });
          this.$reportError(err);
          this.facebookLoading = false;
        });
    },

    loadTikTokAccounts() {
      this.tiktokLoading = true;
      tiktokApi
        .getAdAccounts()
        .then((data) => {
          if (data.success) {
            this.tiktokLoading = false;
            this.tiktokAdAccounts = data.accounts;
            // Default to first if only one option
            if (data.accounts.length === 1) {
              this.tiktok_account = data.accounts[0].id;
            } else if (data.accounts.length < 1) {
              this.tiktokAdAccounts = [];
              this.tiktok_account = '';
              return this.$message({
                type: 'warning',
                message:
                  'You do not have any ad accounts available to your TikTok user account.',
              });
            }
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: 'Unable to load tiktok ad accounts',
          });
          this.$reportError(err);
          this.tiktokLoading = false;
        });
    },

    querySearch(queryString, cb) {
      const links = this.loadDspOptions;
      const results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },

    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    handleCloseDSPTag(tag) {
      if (tag === 'Xandr') {
        this.xandr = false;
      }
      this.dspTags.splice(this.dspTags.indexOf(tag), 1);
    },

    addDspTag() {
      if (this.dspTags.includes(this.selectedDsp)) {
        return;
      }
      if (this.selectedDsp === 'Xandr') {
        this.xandr = true;
      }
      this.dspTags.push(this.selectedDsp);
    },

    reset() {
      for (const key in initialState) {
        this.$set(this, key, initialState[key]);
      }
    },

    async startPublish() {
      this.busy = true;
      try {
        const params = {
          scope: this.publishScope.map((ps) => ps.toUpperCase()),
          organization_publish_partner_id: this.partnerInfo.id,
          ...this.schedule,
          from: dateToString(this.schedule.from),
          until: dateToString(this.schedule.until),
          device_type: this.deviceType,
        };

        // adding addition opts if openx
        if (this.openx) {
          if (this.xandrBuyerId) {
            params.xandrBuyerId = this.xandrBuyerId;
          }
          params.ctv = this.ctv;
          params.tokens = this.dspTags;
        }

        // Inject selected facebook ad account if appropriate
        if (this.facebook) {
          params.facebook_account = this.facebook_account;
        }

        // Inject selected tiktok ad account if appropriate
        if (this.tiktok) {
          params.tiktok_account = this.tiktok_account;
        }

        params.recur_type = params.type;
        delete params.type;

        let jobs;
        let conflicts = [];
        if (this.ids.length > 0) {
          // Bulk Publish
          params.ids = this.ids;
          const result = await audienceApi.bulkPublish(params);
          jobs = result.jobs;
          conflicts = result.conflicts;
        } else {
          jobs = await (!this.splitAudience
            ? audienceApi.publish
            : audienceApi.splitAndPublish)(this.audience.id, params);

          const mixpanelMeta = {
            ID: this.audience.id,
            Name: this.audience.name,
            Scope: this.publishScope,
            'Publish Partner ID': this.partnerInfo.id,
            From: dateToString(this.schedule.from),
            Until: dateToString(this.schedule.until),
            Type: this.schedule.type,
            Frequency: this.schedule.frequency,
            Lookback: this.schedule.lookback ? this.schedule.lookback : 'N/A',
            'Job IDs': jobs.map((job) => job.id),
            'Device Type': this.deviceType,
          };
          window.mixpanel.track(
            !this.splitAudience ? 'Publish Audience' : 'Split & Publish Audience',
            mixpanelMeta,
          );
        }

        jobs.forEach((job) => this.$store.dispatch('mqtt/addRawJob', job));

        if (this.ids) {
          if (jobs.length > 0) {
            this.$message({
              type: 'success',
              message: 'Publish Schedule Created',
            });
          }
          if (conflicts.length > 0) {
            const message = `Publish schedule conflicts have been detected for the following audience${conflicts.length !== 1 ? 's' : ''}: ${conflicts.join(', ')}`;
            this.$alert(message, 'Scheduling Conflicts Detected', {
              confirmButton: 'Ok',
              type: 'error',
            });
          }
        } else {
          this.$message({
            type: 'success',
            message: `Audience ${
              this.splitAudience ? 'Split &' : ''
            } Publish Initiated.`,
          });
        }
        this.eventBus.$emit('clear-selection');
        this.closeDialog();
      } catch (e) {
        if (
          e.response &&
          e.response.status === 409 &&
          ['SCHEDULE_CONFLICT', 'ALREADY_SPLIT'].includes(e.response.data.code)
        ) {
          const title = e.response.data.code.toLowerCase().split('_');
          for (let i = 0; i < title.length; i++) {
            title[i] = title[i].charAt(0).toUpperCase() + title[i].slice(1);
          }
          this.$alert(e.response.data.message, title.join(' '), {
            confirmButtonText: 'OK',
            type: 'error',
          });
        } else {
          this.$notify.error({
            message:
              'There was a problem while publishing. This issue has been reported.',
          });
          this.$reportError(e);
        }
        this.busy = false;
      }
    },
  },
};
</script>

<style scoped>
.narrow {
  line-height: 0;
}

.indented {
  margin-left: 2em;
}

.lookalike-notice {
  background: #ffffcc;
  padding: 1em;
  margin: 1em 0 0 7em;
  width: 552px;
  line-height: 1.2em;
}

.split-explanation {
  margin-top: 1em;
}

.v-facebook-login {
  margin-bottom: 1em;
}
</style>
