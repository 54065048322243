import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import modules from './modules';
import { setDefaultGeoframeOrg } from './plugins/setDefaultGeoframeOrg';

const persistedState = createPersistedState({
  key: 'onspot',
  paths: ['auth'],
});

Vue.use(Vuex);

const actions = {
  event() {
    // Do nothing - must subscribe to this action
  },
};

const store = {
  modules,
  actions,
  plugins: [setDefaultGeoframeOrg, persistedState],
};

export default new Vuex.Store(store);
