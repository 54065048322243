<template>
  <div>
    <audience-dialog-clone
      :audience="chosenAudience"
      :show-dialog="dialog"
      @clone="handleStartClone"
      @close="handleDialogClose"
    />
    <audience-dialog-proposal
      :audience="chosenAudience"
      :show-dialog="dialog"
      @clone="handleStartClone"
      @close="handleDialogClose"
    />
    <audience-dialog-download
      :audience="chosenAudience"
      :show-dialog="dialog"
      @close="handleDialogClose"
    />
    <audience-dialog-dwell-download
      :audience="chosenAudience"
      :show-dialog="dialog"
      @close="handleDialogClose"
    />
    <audience-dialog-extend
      :audience="chosenAudience"
      :show-dialog="dialog"
      @close="handleDialogClose"
    />
    <audience-dialog-publish
      :audience="chosenAudience"
      :show-dialog="dialog"
      :ids="bulkIds"
      @close="handleDialogClose"
    />
    <audience-dialog-report-link
      :audience="chosenAudience"
      :show-dialog="dialog"
      @close="handleDialogClose"
    />
    <audience-dialog-date-fixer
      :audience="chosenAudience"
      :range-start="rangeStart"
      :range-end="rangeEndDate"
      :show-dialog="dialog"
      @close="handleDialogClose"
    />

    <audience-dialog-trade-area
      :audience="chosenAudience"
      :show-dialog="dialog"
      @close="handleDialogClose"
    />

    <edit-dialog
      :data="chosenAudience"
      :el-rules="elementRules"
      object-name="Audience"
      :visible="dialog === 'edit'"
      width="50%"
      @request-close="handleDialogClose"
      @save="handleSave"
    >
      <template slot-scope="{ formData }">
        <el-form-item
          label="Name"
          prop="name"
        >
          <el-input
            v-model="formData.name"
            placeholder="Audience Name"
          />
          <div>
            <dedupe-control v-model="dedupe" />
          </div>
        </el-form-item>
        <el-form-item :error="formErrors.tags">
          <tag-picker :tags.sync="formData.tags" />
        </el-form-item>
      </template>
    </edit-dialog>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AudienceDialogClone from './AudienceDialogClone.vue';
import AudienceDialogDownload from './AudienceDialogDownload.vue';
import AudienceDialogDwellDownload from './AudienceDialogDwellDownload.vue';
import AudienceDialogExtend from './AudienceDialogExtend.vue';
import AudienceDialogPublish from './AudienceDialogPublish.vue';
import AudienceDialogDateFixer from './AudienceDialogDateFixer.vue';
import AudienceDialogReportLink from './AudienceDialogReportLink.vue';
import AudienceDialogProposal from './AudienceDialogProposal.vue';
import AudienceDialogTradeArea from './AudienceDialogTradeArea.vue';
import EditDialog from '../global/EditDialog.vue';
import DedupeControl from '../global/DedupeControl.vue';
import TagPicker from '../global/TagPicker.vue';
import { audience as audienceApi } from '@/adonis-api';

export default {
  components: {
    AudienceDialogClone,
    AudienceDialogDownload,
    AudienceDialogDwellDownload,
    AudienceDialogExtend,
    AudienceDialogPublish,
    AudienceDialogDateFixer,
    AudienceDialogReportLink,
    AudienceDialogProposal,
    AudienceDialogTradeArea,
    DedupeControl,
    EditDialog,
    TagPicker,
  },

  data() {
    return {
      dedupe: true,
      editingAudience: {},
      elementRules: Object.freeze({
        name: [
          {
            required: true,
            message: 'Audience name is required.',
            trigger: 'blur',
          },
        ],
      }),
      formErrors: {},
    };
  },

  computed: {
    ...mapState('audience', ['chosenAudience', 'dialog', 'bulkIds']),
    ...mapGetters('settings', ['rangeStart', 'rangeEndDate']),
  },

  methods: {
    handleDialogClose() {
      this.$store.dispatch('audience/closeDialog');
    },

    async handleSave(data) {
      const response = await audienceApi.update(data.id, {
        ...data,
        auto_dedupe: this.dedupe,
      });

      this.$store.dispatch('audience/closeDialog');
      this.$store.dispatch('event', {
        eventType: 'SAVED_AUDIENCE',
        audience: response.data,
      });
    },

    handleStartClone(jobId) {
      this.$store.dispatch('audience/startedClone', jobId);
    },
  },
};
</script>

<style>
</style>
