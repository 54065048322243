<template>
  <div>
    <el-table
      v-loading="busy"
      :data="tableData"
      :row-class-name="tableRowClassName"
      size="mini"
      style="width: 100%"
    >
      <el-table-column
        prop="type"
        label="File Name"
      >
        <template slot-scope="{ row }">
          {{ row?.fileName || 'null' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="Time Length"
      >
        <template slot-scope="{ row }">
          {{ row?.averageDwellTimeDistribution || 'null' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="Duration"
      >
        <template slot-scope="{ row }">
          {{ row?.adDuration || '0' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="Headers"
      >
        <template slot-scope="{ row }">
          <el-tag
            v-for="head in row.headers"
            :key="head.value"
            size="mini"
          >
            {{ head.label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Created">
        <template slot-scope="{ row }">
          {{ row.created_at | dateString({ includeTime: true }) }}
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template slot-scope="{ row }">
          {{ row.status || 'COMPLETE' }}
        </template>
      </el-table-column>
      <el-table-column label="Operations">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot-scope="{ row }">
          <div v-if="!row.status">
            <el-button
              size="mini"
              @click="downloadFile(row.file.signed_url)"
            >
              Download
            </el-button>
            <el-button
              class="danger"
              size="mini"
              type="text"
              @click="deleteDownload(row.id)"
            >
              Delete
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _some from 'lodash/some';
import _indexOf from 'lodash/indexOf';
import { DTRHeaders } from '@/constants';
import { audience as audienceApi } from '@/adonis-api';

export default {
  name: 'DwellTimeReportListing',
  props: {
    downloads: { type: Array, default: () => [] },
    busy: { type: Boolean },
  },
  computed: {
    tableData: {
      cache: false,
      get() {
        const mappedData = this.downloads.map((i) => {
          const fileName = i?.file?.path?.split('/')?.[2];
          const headers = DTRHeaders.filter((j) => {
            return _some(
              i.meta?.headers ?? [],
              (element) => _indexOf(j.value, element) !== -1);
          });
          return { ...i, ...i.meta, fileName, headers };
        });
        return mappedData;
      },
    },
  },
  methods: {
    downloadFile(url) {
      window.location.href = url;
    },
    async deleteDownload(downloadId) {
      try {
        await this.$confirm(
          'Are you sure you want to delete this download?',
          'Delete',
        );
        await audienceApi.deleteDownload(downloadId);
        this.$emit('delete', downloadId);
        this.$message('Download has been deleted.');
      } catch (e) {
        if (e !== 'cancel') {
          this.$reportError(e);
        }
      }
    },
    tableRowClassName({ row }) {
      if (!row.hashed) {
        return 'unhashed-download';
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
@import '~$element';

.new-download {
  margin-top: 1.5em;

  .el-checkbox {
    margin-bottom: 12px;
  }
}

.dialog-footer small {
  margin-right: 10px;
}

.danger {
  color: $--color-danger;
}

.unhashed-download {
  background: lighten($--color-warning, 35%) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
