export default [
  {
    path: '/geoframes/library',
    meta: {
      group: 'Geoframes',
      title: 'Geoframe Library',
      permissions: ['view_geoframes'],
    },
    component: () => import(/* webpackChunkName: "geoframeLibrary" */ '../components/pages/geoframes/PageGeoframeLibrary'),
  },
  {
    path: '/geoframes/create',
    meta: {
      group: 'Geoframes',
      title: 'Create Geoframe',
      permissions: ['create_geoframes'],
    },
    component: () => import(/* webpackChunkName: "geoframeCreate" */ '../components/pages/geoframes/PageGeoframeCreate.vue'),
  },
];
