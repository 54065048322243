import { createCrud } from './common';
import apiCall from './api-call';

export default {
  name: 'user',
  ...createCrud('users'),

  setChangelog(uid, changelog) {
    return apiCall('PUT', `/users/${uid}/changelog`, null, { changelog });
  },
};
