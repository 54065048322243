import * as mutation from './mutation-types';

const state = {
  dialog: '',
  dialogStack: [],
  chosenAttribution: {},
};

const actions = {
  closeDialog({ commit }) {
    commit(mutation.CLOSE_DIALOG);
  },

  completedSave() {}, // don't care, this is just for components to subscribe to

  showDialog({ commit }, payload) {
    if (!Array.isArray(payload)) {
      commit(mutation.SHOW_DIALOG, {
        dialog: payload.dialog,
        attribution: payload.attribution,
      });
    } else {
      // Array means we'll show the n+1th dialog after the nth closes
      commit(mutation.PUSH_DIALOGS, payload.slice(1));
      commit(mutation.SHOW_DIALOG, {
        dialog: payload[0].dialog,
        attribution: payload[0].attribution,
      });
    }
  },

  startedClone({ commit }, jobId) {
    commit(mutation.STARTED_CLONE, jobId);
  },
};

const mutations = {
  [mutation.CLOSE_DIALOG](state) {
    if (state.dialogStack.length === 0) {
      state.chosenAttribution = {};
      state.dialog = '';
    } else {
      const opts = state.dialogStack.shift();

      state.chosenAttribution = opts.attribution;
      state.dialog = opts.dialog;
    }
  },

  [mutation.PUSH_DIALOGS](state, dialogs) {
    state.dialogStack.push(...dialogs);
  },

  [mutation.SHOW_DIALOG](state, { dialog, attribution }) {
    state.chosenAttribution = attribution;
    state.dialog = dialog;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
