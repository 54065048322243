<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible="showDialog === 'dwell-report-download'"
      :before-close="handleClose"
      width="75%"
    >
      <dwell-time-report-listing
        :downloads="downloadData"
        :busy="busy"
        @delete="handleDelete"
      />
      <el-form
        inline
        class="new-download"
      >
        <div>
          <el-form-item>
            <el-select
              v-model="averageDwellTimeDistribution"
              placeholder="Average Dwell Time"
            >
              <el-option
                v-for="atype in distributionOptions"
                :key="'dist-' + atype.value"
                :label="atype.label"
                :value="atype.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="adDuration"
              placeholder="Ad Duration (Seconds)"
              type="number"
              :min="1"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="headers"
              placeholder="Output"
              multiple
            >
              <el-option
                v-for="atype in outputHeaderOptions"
                :key="'header-' + atype.value"
                :label="atype.label"
                :value="atype.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              v-popover:busyPopover
              type="primary"
              @blur.native="showNoDownloadMsg = false"
              @click="requestDownload"
            >
              Process
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <span>
          <small>
            <strong>Note:</strong> You may close this window before requested
            download processing completes.
          </small>
        </span>

        <el-button @click="handleClose"> Close </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { audience as audienceApi } from '@/adonis-api';
import { isDeviceAudience } from '@/helpers';
import { deviceDownloads, downloadTypes, DTRHeaders, dwellDownloadType } from '@/constants';
import jobWatcher from '@/mixins/job-watcher';
import HelpTip from '../global/HelpTip.vue';
import DwellTimeReportListing from './DwellTimeReportListing.vue';

export default {
  components: { HelpTip, DwellTimeReportListing },
  filters: {
    typeLabel() {
      return 'OOH Measurement';
    },
  },
  mixins: [jobWatcher],
  props: {
    audience: { type: Object, required: true },
    showDialog: { type: String, required: true },
  },

  data() {
    return {
      availableDownloads: [],
      busy: false,
      downloadJobs: [],
      downloadType: dwellDownloadType,
      averageDwellTimeDistribution: '',
      adDuration: null,
      headers: [],
      showNoDownloadMsg: false,
      types: downloadTypes,
      // Static
      distributionOptions: [
        {
          value: 'minimum',
          label: 'Minimum',
        },
        {
          value: 'low',
          label: 'Low',
        },
        {
          value: 'medium',
          label: 'Medium',
        },
        {
          value: 'aboveAverage',
          label: 'Above Average',
        },
        {
          value: 'high',
          label: 'High',
        },
      ],
      outputHeaderOptions: DTRHeaders,
    };
  },

  computed: {
    ...mapGetters('mqtt', ['getJobs']),
    ...mapGetters('user', ['permissions']),

    dialogTitle() {
      return `Download "${this.audience.name}" Audience OOH Measurement`;
    },

    downloadData() {
      return this.availableDownloads.filter(i => i.type === dwellDownloadType);
    },

    watchedDownloadJobs() {
      return this.getJobs(this.watchJobIds);
    },
  },

  watch: {
    async audience(audience) {
      if (this.showDialog !== 'dwell-report-download' || !audience.id) return;
      this.getAvailableDownloads(audience.id);
    },

    downloadType() {
      this.showNoDownloadMsg = false;
    },

    watchedJobs: {
      handler(jobs) {
        const removeJobIds = [];

        for (const jobDetails of jobs) {
          const job = this.downloadJobs.find((j) => j.id === jobDetails.id);

          if (job) {
            const { payload } = jobDetails;

            job.status = payload.status;

            if (payload.status === 'COMPLETE') {
              const result = payload.result;
              this.availableDownloads = this.availableDownloads.map((item, idx) => {
                if (idx === this.availableDownloads.length - 1) {
                  return { ...result.download, ...result.meta };
                }

                return item;
              });
              removeJobIds.push(job.id);
            }
          }
        }

        if (removeJobIds.length > 0) {
          this.downloadJobs = this.downloadJobs.filter(
            (j) => !removeJobIds.includes(j.id),
          );
        }
      },
      deep: true,
    },
  },

  methods: {
    async getAvailableDownloads(audienceId) {
      this.busy = true;
      const result = await audienceApi.getAvailableDownloads(audienceId);
      this.availableDownloads = result.downloads;
      this.downloadJobs = result.jobs;
      this.busy = false;

      if (this.downloadJobs.length > 0) {
        this.setWatchedJobs(this.downloadJobs.map((j) => j.id));
      }
    },

    handleClose() {
      this.$emit('close');
    },

    downloadFile(url) {
      window.location.href = url;
    },

    async requestDownload() {
      try {
        const defaultValues = {
          hashed: true,
          chunked: false,
        };
        const uniqueHeaders = this.headers.map((i) => (i[0]));
        const impressionHeaders = this.headers.map((i) => (i[1]));
        const headers = ['location', ...uniqueHeaders, 'mean dwell time (seconds)', ...impressionHeaders];

        const downloadObj = {
          ...defaultValues,
          type: dwellDownloadType,
          averageDwellTimeDistribution: this.averageDwellTimeDistribution,
          adDuration: this.adDuration || 0,
          headers,
        };

        const isInValid = !downloadObj.adDuration || !downloadObj.averageDwellTimeDistribution || !(downloadObj.headers.length > 2);

        if (isInValid) {
          const errorObj = { error: 'invalid-input' };
          throw errorObj;
        }

        const job = await audienceApi.requestDownload(
          this.audience.id,
          downloadObj,
        );

        this.$store.dispatch('mqtt/addRawJob', job);
        const jobDetail = {
          ...job,
          ...defaultValues,
          type: dwellDownloadType,
        };
        this.downloadJobs.push(jobDetail);
        this.availableDownloads.push(jobDetail);
        this.watchJob(job.id);

        window.mixpanel.track('Download Audience', {
          ID: this.audience.id,
          Name: this.audience.name,
          Hashed: defaultValues.hashed,
          Chunked: defaultValues.chunked,
          Type: dwellDownloadType,
        });
      } catch (e) {
        switch (e.error) {
          case 'no-such-audience':
            this.$notify.error({
              title: 'No Such Audience',
              message:
                "That audience doesn't exist, or it used to but is no longer available.",
            });
            break;
          case 'invalid-input':
            this.$notify.error({
              title: 'Input valid inputs',
              message:
                'Please make sure to fulfill all inputs.',
            });
            break;
          case 'date-range-error':
            this.$notify.error({
              title: 'Date Range Error',
              message:
                'Unable to process download on this audience because the dates are out of range.',
              duration: 60,
            });
            break;
          default:
            break;
        }
      }
    },

    handleDelete(id) {
      this.availableDownloads = this.availableDownloads.filter((d) => d.id !== id);
    },
  },
};
</script>

<style lang="scss">
@import '~$element';

.new-download {
  margin-top: 1.5em;

  .el-checkbox {
    margin-bottom: 12px;
  }
}

.dialog-footer small {
  margin-right: 10px;
}

.danger {
  color: $--color-danger;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
