export default [
  {
    path: '/admin/users',
    meta: {
      group: 'Administrative',
      title: 'Administer Users',
      permissions: ['administer_users'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../components/pages/admin/PageAdminUsers'),
  },
  {
    path: '/admin/organizations',
    meta: {
      group: 'Administrative',
      title: 'Administer Organizations',
      permissions: ['administer_organizations'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../components/pages/admin/PageAdminOrganizations.vue'),
  },
  {
    path: '/admin/create-changelog',
    meta: {
      group: 'Administrative',
      title: 'Create Changelog Entries',
      permissions: ['write_changelog'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../components/pages/changelog/PageChangelogCreate.vue'),
  },
  {
    path: '/admin/organizations/:id/edit',
    meta: {
      group: 'Administrative',
      title: 'Edit Organization',
      permissions: ['administer_organizations'],
      exclude: true,
    },
    component: () => import(/* webpackChunkName: "admin" */ '../components/pages/admin/PageEditOrganization.vue'),
  },
  {
    path: '/admin/organizations/new',
    meta: {
      group: 'Administrative',
      title: 'Add Organization',
      permissions: ['administer_organizations'],
      exclude: true,
    },
    component: () => import(/* webpackChunkName: "admin" */ '../components/pages/admin/PageEditOrganization.vue'),
  },
  {
    path: '/admin/reporting',
    meta: {
      group: 'Administrative',
      title: 'Reporting',
      permissions: ['audience_creation_reports', 'audience_publication_reports', 'eula_reports', 'geoframe_creation_reports', 'attribution_usage_reports'],
      partial: true,
    },
    component: () => import(/* webpackChunkName: "reporting" */ '../components/pages/admin/PageReporting.vue'),
  },
  // {
  //   path: '/admin/data',
  //   meta: {
  //     title: 'Data Operations',
  //     permissions: ['admin', 'admin_data'],
  //     super: false,
  //   },
  //   component: PageAdminDataOps,
  // },
  // {
  //   path: '/admin/audits',
  //   meta: {
  //     title: 'Audits',
  //     permissions: ['admin', 'admin_audits'],
  //     super: false,
  //   },
  //   component: PageAdminAudits,
  // },

];
