<template>
  <svg
    viewBox="25 25 50 50"
    class="circular"
    :style="cssVars"
  >
    <circle
      cx="50"
      cy="50"
      r="20"
      fill="none"
      class="path"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: { type: String, default: '#409eff' },
    size: { type: Number, default: 32 },
    strokeWidth: { type: Number, default: 2 },
  },
  computed: {
    cssVars() {
      return {
        '--height': `${this.size}px`,
        '--width': `${this.size}px`,
        '--stroke-width': this.strokeWidth,
        '--color': this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.circular {
  height: var(--height);
  width: var(--width);
  animation: loading-rotate 2s linear infinite;

  .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: var(--stroke-width);
    stroke: var(--color);
    stroke-linecap: round;
  }
}
</style>
