export default [
  {
    path: '/user/guide',
    meta: {
      title: 'User Guide',
      exclude: true,
      nonPage: true,
    },
    beforeEnter: (to, from, next) => {
      next(false);
    },
  },
  {
    path: '/user/password',
    meta: {
      title: 'Change Password',
      exclude: true,
      nonPage: true,
    },
    beforeEnter: (to, from, next) => {
      next(false);
    },
  },
];
