<template>
  <div
    class="report-configurations"
  >
    <el-form label-position="top">
      <el-row
        :gutter="20"
        class="row-spacing"
      >
        <el-col :span="12">
          <el-form-item label="Report Title">
            <el-input
              v-model="reportTitle"
              placeholder="Report Title"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Company URL">
            <el-input
              v-model="reportURL"
              placeholder="Company URL"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="row-spacing"
      >
        <el-col :span="24">
          <el-form-item label="Logo Upload">
            <uploader
              ref="pdfCoverLogo"
              :accept-file-exts="fileExts"
              api-endpoint="/api/v1/audiences/pdf/pdf-logo"
              single-file
              upload-field-name="pdf-logo"
              @complete="uploadComplete"
              @file-change="handleFileChange"
              @upload-state-change="handleStateChange"
            />
            <p class="upload-instructions">
              Recommended format: SVG or PNG with a minimum size of 500x500 pixels for best results when stretched.
            </p>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="row-spacing"
      >
        <el-col :span="12">
          <el-form-item label="Audience Insight Description">
            <el-input
              v-model="insightDescription"
              type="textarea"
              placeholder="Audience Insight Description"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Audience Demographic Profile Description">
            <el-input
              v-model="demographicDescription"
              type="textarea"
              placeholder="Audience Demographic Profile Description"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="row-spacing"
      >
        <el-col :span="12">
          <el-form-item label="Audience Heatmap Description">
            <el-input
              v-model="heatmapDescription"
              type="textarea"
              placeholder="Audience Heatmap Description"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Audience Political Profile Description">
            <el-input
              v-model="politicalDescription"
              type="textarea"
              placeholder="Audience Political Profile Description"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="row-spacing"
      >
        <el-col :span="16">
          <el-form-item label="End Page Description">
            <el-input
              v-model="endPageDescription"
              type="textarea"
              placeholder="End Page Description"
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="Email">
            <el-input
              v-model="email"
              type="textarea"
              placeholder="john@example.com"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="row-spacing"
      >
        <el-col :span="12">
          <el-form-item label="Select Start Hour for Visits">
            <el-select
              v-model="startHour"
              placeholder="Select Start Hour"
            >
              <el-option
                v-for="hour in hours"
                :key="hour"
                :label="hour"
                :value="hour"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Select End Hour for Visits">
            <el-select
              v-model="endHour"
              placeholder="Select End Hour"
            >
              <el-option
                v-for="hour in hours"
                :key="hour"
                :label="hour"
                :value="hour"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="row-spacing save-button-container"
      >
        <el-col :span="24">
          <el-button
            type="success"
            :disabled="!canSave"
            @click="saveConfigs"
          >
            Save Settings
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import Uploader from '@/components/global/Uploader.vue';
import { reporting as reportingApi } from '@/adonis-api';
import { mapGetters } from 'vuex';

export default {
  components: {
    Uploader,
  },
  props: {
    audienceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      reportTitle: this.audienceData.name || '',
      reportURL: '',
      insightDescription: '',
      demographicDescription: '',
      heatmapDescription: '',
      politicalDescription: '',
      endPageDescription: '',
      email: '',
      fileExts: ['.jpg', '.png', '.svg'],
      loading: false,
      logo_url: '',
      logo: null,
      fileList: [],
      configSaved: false,
      initialConfig: {},
      startHour: '8AM',
      endHour: '5PM',
      hours: this.generateHoursOptions(),
    };
  },
  computed: {
    ...mapGetters('audience', ['isAudienceSaved']),

    canSave() {
      return !this.configSaved || this.configHasChanged();
    },
  },
  watch: {
    audienceData: {
      deep: true,
      handler() {
        this.setInitialConfig();
      },
    },
  },
  created() {
    this.setInitialConfig();
    const that = this;
    this.eventBus.$on('saveInsightConfigs', () => {
      that.saveConfigs();
    });
  },
  methods: {
    generateHoursOptions() {
      return [
        '12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM',
        '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM',
      ];
    },
    setInitialConfig() {
      this.initialConfig = JSON.stringify({
        reportTitle: this.reportTitle,
        reportURL: this.reportURL,
        insightDescription: this.insightDescription,
        demographicDescription: this.demographicDescription,
        heatmapDescription: this.heatmapDescription,
        politicalDescription: this.politicalDescription,
        endPageDescription: this.endPageDescription,
        logo_url: this.logo_url,
        email: this.email,
        startHour: this.startHour,
        endHour: this.endHour,
      });
    },
    configHasChanged() {
      const currentConfig = JSON.stringify({
        reportTitle: this.reportTitle,
        reportURL: this.reportURL,
        insightDescription: this.insightDescription,
        demographicDescription: this.demographicDescription,
        heatmapDescription: this.heatmapDescription,
        politicalDescription: this.politicalDescription,
        endPageDescription: this.endPageDescription,
        logo_url: this.logo_url,
        email: this.email,
        startHour: this.startHour,
        endHour: this.endHour,
      });
      return this.initialConfig !== currentConfig;
    },
    handleFileChange(fileList) {
      this.logo = fileList[0];
    },
    uploadComplete(result) {
      this.logo_url = result.data.logo_url;
    },
    handleStateChange(isUploading) {
      this.loading = isUploading;
    },
    async saveConfigs() {
      if (this.canSave) {
        await this.$refs.pdfCoverLogo.startUpload({
          organization_id: this.audienceData.organization_id,
          name: this.reportTitle,
          report_id: this.audienceData.id,
        });
        const configData = {
          reportId: this.audienceData.id,
          reportTitle: this.reportTitle,
          reportURL: this.reportURL,
          insightDescription: this.insightDescription,
          demographicDescription: this.demographicDescription,
          heatmapDescription: this.heatmapDescription,
          politicalDescription: this.politicalDescription,
          endPageDescription: this.endPageDescription,
          logoUrl: this.logo_url,
          email: this.email,
          startHour: this.startHour,
          endHour: this.endHour,
        };
        await reportingApi.saveConfigs(configData);

        this.configSaved = true;
        this.$alert('Your report configurations have been saved.');
        this.$emit('configsSaved');
        this.setInitialConfig();
      }
    },
  },
};
</script>

<style scoped>
.row-spacing {
  margin-bottom: 20px;
}

.save-button-container {
  text-align: right;
}
.upload-instructions {
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
  margin-bottom: 12px;
}

</style>
