import apiCall from './api-call';

export default {
  getPermissionAudit() {
    return apiCall('GET', '/reporting/permission-audit');
  },

  getReportData(reportId) {
    return apiCall('GET', `/request-insight/report-data/${reportId}`, null, null, {
      noAuth: true,
    });
  },

  saveConfigs(data) {
    return apiCall('POST', '/audiences/save-report-configs', null, data);
  },

  historicalWeather(options) {
    return apiCall('GET', '/historical-weather', options);
  },

  getConfigs(reportId) {
    return apiCall('GET', `/audiences/get-report-configs/${reportId}`);
  },
};
