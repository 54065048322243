<template>
  <div>
    <div v-if="inIframe">
      Please do not embed this application in an iframe.
    </div>
    <div v-else-if="!settingsFetched" />
    <div v-else-if="loggedIn && maintenanceActive && !isInternal">
      <el-dialog
        :show-close="false"
        title="Service Interruption"
        center
        fullscreen
        visible
        class="maintenance-screen"
      >
        <div class="content">
          <div>
            <img src="/images/maintenance.png">
          </div>
          <div>
            <h3>The application is currently unavailable.</h3>
            <p>
              <strong>Reason:</strong> {{ maintenanceReason }}
            </p>
            <p>
              <countdown :time="maintenanceTime">
                <template slot-scope="props">
                  Anticipated completion in
                  <span v-if="props.days > 0">{{ props.days }} day{{ props.days !== 1 ? 's' : '' }}, </span>
                  {{ props.hours }} hour{{ props.hours !== 1 ? 's' : '' }},
                  {{ props.minutes }} minute{{ props.minutes !== 1 ? 's' : '' }},
                  {{ props.seconds }} second{{ props.seconds !== 1 ? 's' : '' }}.
                </template>
              </countdown>
            </p>
          </div>
        </div>
      </el-dialog>
    </div>
    <div
      v-else-if="loggedIn && !$route.meta.noLayout"
      v-loading.fullscreen.lock="authBusy"
    >
      <div class="os-container">
        <div
          v-if="shouldShowMaintenanceAlert"
          class="maintenance-alert"
        >
          <i class="icon el-icon-warning is-big" />
          <div class="content">
            <span class="title">
              <span v-if="maintenanceScheduled">Upcoming</span>
              Service Interruption for {{ maintenanceReason }}
            </span>
            <p class="description">
              <countdown :time="maintenanceTime">
                <template slot-scope="props">
                  <span v-if="maintenanceScheduled">Beginning in</span>
                  <span v-if="maintenanceActive">Ending in</span>
                  <span v-if="props.days > 0">{{ props.days }} day{{ props.days !== 1 ? 's' : '' }}, </span>
                  {{ props.hours }} hour{{ props.hours !== 1 ? 's' : '' }},
                  {{ props.minutes }} minute{{ props.minutes !== 1 ? 's' : '' }},
                  {{ props.seconds }} second{{ props.seconds !== 1 ? 's' : '' }}.
                </template>
              </countdown>
            </p>
          </div>
        </div>

        <div>
          <the-header :whitelabels="whitelabels" />
        </div>
        <div class="horiz-layout">
          <the-left-nav />
          <div class="os-content-wrapper">
            <div class="os-content">
              <transition name="fade">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        :show-close="false"
        title="Terms of Use"
        :visible="showTOS"
      >
        <el-alert
          class="tos-alert"
          title="Our Terms of Service have been updated. Please review and accept the terms below."
          type="warning"
        />
        <div
          class="terms-of-service"
          v-html="eula"
        />
        <div class="accept-checkboxes">
          <div>
            <div class="fine-print">
              By checking this box User acknowledges that a fee is charged for
              Insight Reports and that all reports generated from or by User
              will be billed to User's company. User acknowledges and affirms
              that they have the authority or approval to make these purchases.
            </div>
            <el-checkbox
              v-model="accept1"
              class="tos-checkbox"
            >
              I agree
            </el-checkbox>
          </div>
          <div>
            <div class="fine-print">
              By checking this box, I understand the terms of service as
              described above and agree to abide by and follow these terms.
            </div>
            <el-checkbox
              v-model="accept2"
              class="tos-checkbox"
            >
              I agree
            </el-checkbox>
          </div>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            :disabled="!accept1 || !accept2"
            type="primary"
            @click="acceptTOS"
          >
            Accept
          </el-button>
        </span>
      </el-dialog>

      <div class="version">
        <the-version />
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>

    <div
      v-if="newVersion && shouldShowVersion"
      class="app-update"
    >
      <div class="icon">
        <span class="fa-stack fa-lg">
          <i class="fa fa-stack-2x fa-cloud" />
          <i class="fa fa-stack-1x fa-sync-alt refresh-icon" />
        </span>
      </div>
      <div class="content">
        <div class="instructions">
          A newer version of this application is available! Please wrap up any
          work, and click the button below to reload the page.
        </div>
        <div class="buttons">
          <el-button
            plain
            size="medium"
            @click="handleRefreshPage"
          >
            Refresh Page
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import moment from 'moment';

import TheHeader from './components/global/TheHeader.vue';
// import MaintenanceMessage from './components/global/MaintenanceMessage.vue';
import { mapState, mapGetters } from 'vuex';
import TheLeftNav from './components/global/TheLeftNav.vue';
import TheVersion from './components/global/TheVersion.vue';
import { user as userApi, whitelabel } from '@/adonis-api';
import { getMqttClient } from '@/mqtt';
import Hashids from 'hashids';

const hashids = new Hashids(
  process.env.VUE_APP_HASHIDS_SALT,
  Number(process.env.VUE_APP_HASHIDS_PADDING),
);

let maintenanceTimer;

export default {
  components: {
    TheHeader,
    TheLeftNav,
    TheVersion,
    // MaintenanceMessage,
  },

  data() {
    return {
      accept1: false,
      accept2: false,
      isLoggingIn: false,
      maintenanceTime: 0,
      maintenanceActive: false,
      maintenanceScheduled: false,
      newVersion: false,
      settingsFetched: false,
      showTOS: false,
      whitelabels: [],
    };
  },

  computed: {
    ...mapState('auth', ['loggedIn', 'authBusy', 'token']),
    ...mapState('user', ['orgDetails', 'userDetails']),
    ...mapGetters('user', ['fullName', 'userCreatedAt', 'isInternal']),
    ...mapGetters('settings', ['eula', 'eulaUpdated', 'maintenance']),
    ...mapGetters('auth', ['impersonating']),

    inIframe() {
      try {
        // Allow iframe embed if it's an audience or attribution report only
        const path = window.location.pathname.split('/');
        if (
          path[1] &&
          ['audience-reports', 'report-viewer'].includes(path[1])
        ) {
          return false;
        }
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },

    maintenanceReason() {
      const start = moment(this.maintenance.window[0]).format('MMM D, YYYY h:mma');
      const end = moment(this.maintenance.window[1]).format('MMM D, YYYY h:mma');
      return `${this.maintenance.reason} from ${start} - ${end}.`;
    },

    shouldShowMaintenanceAlert() {
      return (
        this.maintenance?.enabled &&
        (this.maintenanceScheduled || this.maintenanceActive)
      );
    },

    shouldShowVersion() {
      return (
        process.env.NODE_ENV !== 'development' && !this.$route.meta.noLayout
      );
    },
  },

  watch: {
    loggedIn(value) {
      if (!value) {
        this.$router.push('/auth');
      }
    },

    maintenance: {
      deep: true,
      immediate: true,
      handler(maint) {
        if (!maint) {
          return;
        }
        if (maint.enabled) {
          if (moment().isBefore(maint.window[0])) {
            this.maintenanceScheduled = true;
            this.maintenanceActive = false;
            this.updateMaintenanceTimer();
          } else if (moment().isBefore(maint.window[1])) {
            this.maintenanceScheduled = false;
            this.maintenanceActive = true;
            this.updateMaintenanceTimer();

            window.document.title = `Service Interuption: ${this.maintenance.reason}`;
          }
        } else {
          this.maintenanceActive = false;
          this.maintenanceScheduled = false;
          this.maintenanceTime = 0;
        }
      },
    },

    async 'userDetails.id'(value) {
      if (value) {
        const client = await getMqttClient();

        // Set up global MQTT subscriptions
        client.subscribe('settings-updated');
        client.subscribe('whitelabel-update');
        client.subscribe('ui-deployment');
        client.on('message', (topic, message) => {
          if (topic === 'whitelabel-update') {
            this.whitelabels = JSON.parse(message);
          } else if (topic === 'ui-deployment') {
            this.newVersion = true;
          }
        });
        this.$store.dispatch('settings/mqttListen', client);

        // We have Vuex data on the user. Boot up Intercom
        const whitelabel = this.whitelabels.find(obj =>
          window.location.href.match(new RegExp(obj.domain)),
        );

        if (
          process.env.VUE_APP_ENV === 'production' && // There's no reason to load intercom outside of production!
          (!this.$route.meta.noLayout &&
            ((!this.impersonating &&
              /onspotdata\.com/.test(window.location.href)) ||
              (whitelabel && whitelabel.intercom)))
        ) {
          this.$intercom.boot({
            user_id: this.userDetails.id,
            user_hash: this.userDetails.intercomHash,
            name: this.fullName,
            email: this.userDetails.email,
            Platform: 'OnSpot Data',
            company: {
              'Company Platform': 'OnSpot Data',
              id: this.orgDetails.id,
              name: this.orgDetails.name,
              created_at: this.orgDetails.createdAt / 1000,
            },
          });
        }

        if (!this.impersonating) {
          if (
            !this.userDetails.tos_acceptance ||
            moment(this.userDetails.tos_acceptance).isBefore(
              new Date(this.eulaUpdated),
            )
          ) {
            this.showTOS = true;
          }

          // Mixpanel init
          window.mixpanel.identify(hashids.encode(this.userDetails.id));
          window.mixpanel.people.set({
            $email: this.userDetails.email,
            $name: this.fullName,
            $last_login: new Date(),
            Organization: this.orgDetails.name,
          });
        }
      }
    },
  },

  async created() {
    await this.$store.dispatch('settings/init');
    this.settingsFetched = true;
    this.fetchWhitelabels();
    window.mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
  },

  methods: {
    async acceptTOS() {
      await userApi.update(this.userDetails.id, {
        tos_accepted: true,
      });
      this.showTOS = false;
    },

    async fetchWhitelabels() {
      try {
        const response = await whitelabel.find();
        this.whitelabels = response.data.whitelabels;
      } catch (e) {
        this.$reportError(e);
      }
    },

    handleRefreshPage() {
      window.location.reload();
    },

    updateMaintenanceTimer() {
      if (moment().isBefore(this.maintenance.window[0])) {
        this.maintenanceTime = Number(moment(this.maintenance.window[0])) - (new Date()).valueOf();
        clearTimeout(maintenanceTimer);
        this.maintenanceScheduled = true;
        this.maintenanceActive = false;
        maintenanceTimer = setTimeout(this.updateMaintenanceTimer, this.maintenanceTime);
        return;
      } else if (moment().isBefore(this.maintenance.window[1])) {
        this.maintenanceTime = Number(moment(this.maintenance.window[1])) - (new Date()).valueOf();
        clearTimeout(maintenanceTimer);
        this.maintenanceScheduled = false;
        this.maintenanceActive = true;
        maintenanceTimer = setTimeout(this.updateMaintenanceTimer, this.maintenanceTime);
        return;
      }

      clearTimeout(maintenanceTimer);
      this.maintenanceTime = 0;
      this.maintenanceActive = false;
      this.maintenanceScheduled = false;
    },

    maintenanceCountdown() {
      if (moment().isBefore(moment(this.maintenance.window[0]))) {
        return Number(moment(this.maintenance.window[0]).format('x')) - (new Date()).valueOf();
      } else if (moment().isBefore(moment(this.maintenance.window[1]))) {
        return Number(moment(this.maintenance.window[1]).format('x')) - (new Date()).valueOf();
      }
      return 0;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/main.scss';
@import 'assets/styles/fa-light.css';
@import 'assets/styles/fa-solid.css';
@import 'assets/styles/fa-regular.css';
@import 'assets/styles/fontawesome.css';

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  margin: 0 auto;
  max-width: 1600px;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style lang="scss" scoped>
@import '~$custom';

.os-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.horiz-layout {
  display: flex;
  flex: 1;
}

.os-content-wrapper {
  flex: 1;
  overflow: auto;
  position: relative;
}

.os-content {
  left: 0;
  padding: 1em;
  position: absolute;
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.15s;
}

.fade-enter-active {
  transition-delay: 0.15s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.accept-checkboxes {
  >div {
    margin-top: 1em;
  }

  .fine-print {
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 0.4em;
  }
}

.tos-alert {
  margin-bottom: 20px;
}

.main {
  flex: 1;
  margin: 1em 1em 0 1em;
}

.version {
  position: fixed;
  bottom: 6px;
  left: 12px;
  font-size: 0.7em;
  color: #aaa;
}

.maintenance-alert {
  background-color: #fdf6ec;
  color: #E6A23C;
  width: 100%;
  padding: 8px 16px;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: table-cell;
    padding: 0 8px;
  }

  .icon {
    font-size: 28px;
    width: 28px;
  }

  .title {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
  }

  .description {
    font-size: 12px;
    margin: 5px 0 0;
  }
}

.maintenance-screen {
  .content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;

    img {
      margin: 24px 64px;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.app-update {
  background-color: $--color-primary;
  bottom: 0;
  color: #fff;
  display: flex;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;

  .icon {
    padding: 2em;

    .refresh-icon {
      color: $--color-primary;
    }
  }

  .content {
    padding: 1em;
  }

  .instructions {
    font-size: 0.9em;
    line-height: 1.5;
  }

  .buttons {
    margin-top: 1em;
  }
}
</style>
