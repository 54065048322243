<template>
  <div>
    <el-select
      v-if="selectedOrgId !== -1"
      v-model="selectedOrgId"
      class="org-picker"
    >
      <el-option
        v-for="org in filteredOrgList"
        :key="org.id"
        :label="org.name"
        :value="org.id"
      />
    </el-select>
    <div v-else>
      N/A
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { organization } from '@/adonis-api';
import { isEmpty } from '@/helpers';

export default {
  props: {
    hasPixelId: {
      type: Boolean,
      default: false,
    },
    historicalOnly: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      orgList: [],
      selectedOrgId: null,
    };
  },

  computed: {
    ...mapState('user', ['orgDetails']),

    filteredOrgList() {
      if (this.hasPixelId) {
        return this.orgList.filter(org => org.pixel_organization_id);
      }

      if (this.historicalOnly) {
        return this.orgList.filter(org => org.historical_access);
      }

      return this.orgList;
    },
  },

  watch: {
    hasPixelId(val) {
      if (val) {
        /**
         * If we're showing historical orgs only, and we currently have a
         * non-historical org selected, we need to change the dropdown to a
         * valid selection.
         */
        const org = this.orgList.find(org => org.id === this.selectedOrgId);

        if (!org) return;
        if (!org.pixel_organization_id) {
          this.selectedOrgId = this.filteredOrgList[0];
        }
      }
    },

    historicalOnly(val) {
      if (val) {
        /**
         * If we're showing historical orgs only, and we currently have a
         * non-historical org selected, we need to change the dropdown to a
         * valid selection.
         */
        const org = this.orgList.find(org => org.id === this.selectedOrgId);

        if (!org) return;
        if (!org.historical_access) {
          this.selectedOrgId = this.filteredOrgList[0];
        }
      }
    },

    organization: {
      handler(org) {
        this.selectedOrgId = org ? org.id : -1;
      },
      immediate: true,
    },

    orgDetails: {
      handler(org) {
        if ((!this.organization || isEmpty(this.organization)) && org) {
          this.$emit('update:organization', org);
        }
      },
      immediate: true,
    },

    selectedOrgId(id) {
      // const org = this.orgList.find(org => org.id === id);
      // if (!org) return;

      const org = this.orgList.find(org => org.id === id);

      if (org) {
        this.$emit('update:organization', org);
      }
    },
  },

  async created() {
    const result = await organization.find({ picker: true });

    if (result) this.orgList = Object.freeze(result.data);
  },
};
</script>

<style scoped>
.org-picker {
  width: 100%;
}
</style>
