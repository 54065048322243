import Vue from 'vue';
const eventBus = new Vue();

const GlobalEventBus = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          eventBus,
        };
      },
    });
  },
};

export default GlobalEventBus;
