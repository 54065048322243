import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  'ResizeObserver loop limit exceeded', // Element UI nonsense
  'IgnoreMe', // because Element UI is dumb and will have unhandled rejections
  '_handleConnack', // mqtt
  '_leaflet_pos', // leaflet
];
const ignoreUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
    release: process.env.VERSION_SHA,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    // beforeSend(event) {
    //   // Check if it is an exception, if so, show the report dialog
    //   if (process.env.NODE_ENV !== 'development' && event.exception) {
    //     Sentry.showReportDialog();
    //   }
    //   return event;
    // },
    ignoreErrors,
    ignoreUrls,
  });
}

export function reportError(exception, metadata) {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-console */
    console.warn('Error reported via $reportError:');
    console.error(exception);
    console.info('Metadata:', metadata);
    /* eslint-enable no-console */
  } else {
    Sentry.withScope(scope => {
      if (typeof metadata === 'object') {
        for (const key in metadata) {
          scope.setExtra(key, metadata[key]);
        }
      }

      Sentry.captureException(exception);
    });
  }
}

Vue.use({
  install(Vue) {
    Vue.prototype.$reportError = reportError;
  },
});
