export default {
  namespaced: true,

  state: {
    appUpdate: false,
    lastPageNum: 0,
    logoUrl: '/images/onspot-logo.svg',
  },

  getters: {
    appUpdate(state) {
      return state.appUpdate;
    },

    environment(state) {
      if (process.env.VUE_APP_ENV) {
        return process.env.VUE_APP_ENV;
      }
      return process.env.NODE_ENV;
    },

    logoUrl(state) {
      return state.logoUrl;
    },
  },

  actions: {
    notifyAppUpdate({ commit }) {
      commit('notifyAppUpdate');
    },

    setLastPageNum({ commit }, pageNum) {
      commit('setLastPageNum', pageNum);
    },

    setLogoUrl({ commit }, url) {
      commit('setLogoUrl', url);
    },
  },

  mutations: {
    notifyAppUpdate(state) {
      state.appUpdate = true;
    },

    setLastPageNum(state, pageNum) {
      state.lastPageNum = pageNum;
    },

    setLogoUrl(state, url) {
      state.logoUrl = url;
    },
  },
};
