<template>
  <el-checkbox
    :value="checked"
    @change="val => $emit('check', val)"
  >
    Deduplicate name
    <help-tip>
      If checked, a numeral will be appended to the end of the name if
      this name already exists within the organization. For example,
      "My Audience" would become "My Audience (1)".
    </help-tip>
  </el-checkbox>
</template>

<script>
import HelpTip from './HelpTip.vue';

export default {
  components: { HelpTip },
  model: {
    event: 'check',
    prop: 'checked',
  },
  props: {
    checked: { type: Boolean, required: true },
  },
};
</script>
