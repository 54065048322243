export const setDefaultGeoframeOrg = store => {
  // Set the initial organization value for geoframe creation on user login
  store.subscribe(mutation => {
    if (mutation.type === 'auth/login') {
      store.dispatch(
        'geoframes/create/updateOrganization',
        mutation.payload.user.organization,
      );
    }
  });
};
