import { createCrud } from './common';
import apiCall from './api-call';

export default {
  name: 'settings',
  ...createCrud('application-settings', { noAuth: true }),

  updateBulk(data) {
    return apiCall('PUT', '/application-settings/bulk', null, {
      settings: data,
    });
  },
};
