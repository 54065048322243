import { createCrud } from './common';
import apiCall from './api-call';

export default {
  name: 'billing',
  ...createCrud('billing'),

  async getBillingRates(orgId) {
    return apiCall('GET', '/billing', {
      organization_id: orgId,
    });
  },

  async setBillingRates(orgId, billingRates) {
    return apiCall('POST', '/billing/', {
      organization_id: orgId,
      billing_rates: billingRates,
    });
  },

};
