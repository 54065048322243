<template>
  <span>
    <el-tooltip
      :open-delay="500"
      placement="top"
      :style="lineHeight"
    >
      <div slot="content">
        <slot />
      </div>
      <i class="fa fa-question-circle ht-icon" />
    </el-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    height: { type: String, required: false, default: '1' },
  },

  computed: {
    lineHeight() {
      return 'line-height:' + this.height;
    },
  },
};
</script>

<style lang="scss">
@import '~$custom';

.el-tooltip__popper {
  line-height: 1.5 !important;
  max-width: 550;
}

.ht-icon {
  color: $--color-primary;
  font-size: 1rem; // maintain consistent size for the icon
  vertical-align: top;
}
</style>
