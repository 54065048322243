<template>
  <div class="left-nav">
    <el-menu
      :default-active="activeRoute"
      :router="true"
      class="left-nav"
      collapse
    >
      <el-submenu
        v-for="route of routes"
        :key="route.index"
        :index="route.index"
      >
        <template slot="title">
          <i :class="route.icon" />
        </template>
        <el-menu-item-group>
          <span slot="title">{{ route.groupName }}</span>
          <el-menu-item
            v-for="item of route.menuItems"
            :key="item.index"
            :index="item.index"
          >
            {{ item.meta.title }}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { routesToNav } from '@/router/router-helpers';

export default {
  data() {
    return {

    };
  },

  computed: {
    activeRoute() {
      return this.$route.path;
    },

    routes() {
      return routesToNav(this.$router.options.routes);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.left-nav {
  border-right: 1px solid #e6e6e6;
}

:deep( .el-menu) {
  border-right: none;
}

.is-active {
  border-right: 2px solid $--color-primary;
}

</style>
