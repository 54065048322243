import Vue from 'vue';
import moment from 'moment';
import { settings as settingsApi } from '@/adonis-api';
import * as mutation from './mutation-types';

const now = new Date();
const tzOffset = moment().isDST()
  ? now.getTimezoneOffset() + 60
  : now.getTimezoneOffset();
export const rangeStart = moment()
  .utc()
  .startOf('month')
  .startOf('day')
  .subtract(12, 'months');

const state = {
  options: {},
};

const actions = {
  async init({ commit }) {
    const settings = await settingsApi.find();
    commit(mutation.SET_SETTINGS, settings);
  },

  mqttListen({ commit }, client) {
    client.on('message', (topic, message) => {
      if (topic === 'settings-updated') {
        commit(mutation.SET_SETTINGS, JSON.parse(message));
      }
    });
  },

};

const getters = {
  dspMatchPercent: state => state.options['attribution:dspMatchPercent'],
  eula: state => state.options.eula,
  eulaUpdated: state => state.options['eula:updated'],
  maintenance: state => state.options.maintenance,
  isHistoricalDate: date =>
    moment(date).isBefore(rangeStart.clone().startOf('day')),
  maximumArea: state => state.options['geoframes:maximumArea'],
  pageSizes: state => state.options['pagination:pageSizes'],
  rangeEnd: state => state.options['date:rangeEnd'],
  rangeEndDate(state) {
    return moment()
      .utc()
      .subtract(state.options['date:rangeEnd'], 'days')
      .valueOf();
  },
  rangeStart: () => rangeStart.add(tzOffset, 'minutes'),
  reverseAppendsTemplateUri: state => state.options['reverseAppends:reverseAppendsTemplateUri'],
  censusTractTemplateUri: state => state.options['censusTract:censusTractTemplateUri'],
  orderTemplateUri: state => state.options['orders:templateUri'],
  uniqueServedPercent: state => state.options['attribution:uniqueServedPercent'],
  latLngTemplateUri: state => state.options['latLng:latLngTemplateUri'],
  autopolygonTemplateUri: state => state.options['autopolygon:autopolygonTemplateUri'],
  destinationDeviceLimit: state => state.options['attribution:destinationDeviceLimit'],
  destinationGeoframeLimit: state => state.options['attribution:destinationGeoframeLimit'],
  targetDeviceLimit: state => state.options['attribution:targetDeviceLimit'],
  targetGeoframeLimit: state => state.options['attribution:targetGeoframeLimit'],
  tradeAreaGeoframeLimit: state => state.options['audience:tradeAreaGeoframeLimit'],
  predictedMoversZipcodeLimit: state => state.options['audience:predictedMoversZipcodeLimit'],
  journeyGeoframeLimit: state => state.options['audience:journeyGeoframeLimit'],
};

const mutations = {
  [mutation.SET_SETTINGS](state, settings) {
    for (const setting of settings) {
      Vue.set(state.options, setting.id, setting.value);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
