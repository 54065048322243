import apiCall from './api-call';

function createCreate(endpointName) {
  return data => apiCall('POST', `/${endpointName}`, null, data);
}

function createFind(endpointName, options = {}) {
  return options.single
    ? (id, queryParams) =>
      apiCall(
        'GET',
        `/${endpointName}/${id}`,
        queryParams || null,
        null,
        options,
      )
    : queryParams =>
      apiCall('GET', `/${endpointName}`, queryParams, null, options);
}

function createUpdate(endpointName) {
  return (id, data) => apiCall('PUT', `/${endpointName}/${id}`, null, data);
}

function createDelete(endpointName, options = {}) {
  return options.bulk
    ? (ids, opts) =>
      apiCall('DELETE', `/${endpointName}`, null, { ...opts, ids })
    : (id, opts) => apiCall('DELETE', `/${endpointName}/${id}`, null, opts);
}

export function createCrud(endpointName, options = {}) {
  return {
    create: createCreate(endpointName),
    findOne: createFind(endpointName, { ...options, single: true }),
    find: createFind(endpointName, options),
    update: createUpdate(endpointName),
    delete: createDelete(endpointName),
    bulkDelete: createDelete(endpointName, { bulk: true }),
  };
}
