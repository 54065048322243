import moment from 'moment';
import store from '../store';
import { partners } from '../constants';
import languageEncoding from 'detect-file-encoding-and-language';

/**
 * Return true if device-based audience, else false.
 * @returns {Boolean}
 */
export function isDeviceAudience(type) {
  return [
    'ATTRIBUTION_RESPONDER',
    'CARRIER_ROUTE',
    'CENSUS_TRACT',
    'DEMOGRAPHICS',
    'DEVICE_IMPORT',
    'FILTERED',
    'IP',
    'REVERSE_APPENDS',
    'MERGED',
    'PIXEL',
    'TRADE_AREA',
    'PREDICTED_MOVERS',
  ].includes(type);
}

export function isDestinationCompatible(type) {
  return [
    'PIXEL',
  ].includes(type);
}

/**
 * Return true if geoframes exceed limit, else false.
 * @returns {Boolean}
 */
export function geoframeLimit(geoframes, limit) {
  return geoframes > limit;
}

/**
 * Return true if specified file is UTF8 encoded, else false.
 * @returns {Boolean}
 */
export async function isUTF8(file) {
  const result = await languageEncoding(file);
  return result.encoding === 'UTF-8';
}

/**
 * Gets the user's current permissions
 * @returns {Array<string>} Array of permissions as strings
 */
export function getUserPerms() {
  return store.state.user.userDetails.permissions || [];
}

/**
 * Parse a string to a date using a specified format
 * @param {String} date date to be parse
 * @param {String} format date format to parse with
 * @returns {Date} JS date object
 */
export function parseDate(date, format) {
  return new Date(moment(String(date).trim(), format));
}

/**
 * Returns a formatted date
 * @param {Any} date JS date object OR unixtime number
 * @param {String} formatOverride optional override of date format
 * @returns {string} Formatted date
 */
export function dateFormat({ date, formatOverride, normalize }) {
  let offset = 0;

  if (normalize) {
    // TODO: This may not work for non-us timezones. May need to be
    //   updated if/when there are non-us customers.
    const d = moment(date);

    if (d.minute() === 0) {
      if (d.hour() !== 0) {
        if (d.hour() <= 12) {
          offset = 0 - d.hour();
        } else {
          offset = 24 - d.hour();
        }
      }
    }
    if (d.minute() === 59) {
      if (d.hour() !== 23) {
        if (d.hour() <= 12) {
          offset = d.hour() - 24;
        } else {
          offset = 23 - d.hour();
        }
      }
    }
  }

  if (formatOverride) {
    return moment(date)
      .add(offset, 'hours')
      .format(formatOverride);
  }
  return moment(date)
    .add(offset, 'hours')
    .format(process.env.VUE_APP_DATE_FORMAT);
}

/**
 * Splits an array of items into n arrays of items.
 * @param {array} arr Array of items to split into 'columns'
 * @param {integer} columns number of columns to create
 */
export function columnize(arr, columns) {
  const newArr = Array.isArray(arr) ? arr.slice(0) : [arr];
  columns = +columns | 2;

  const cols = [];
  const perColumn = Math.floor(newArr.length / columns);
  while (newArr.length) {
    if (newArr.length % perColumn) {
      cols.push(newArr.splice(0, perColumn + 1));
    } else {
      cols.push(newArr.splice(0, perColumn));
    }
  }

  return cols;
}

export function remap(x, inMin, inMax, outMin, outMax) {
  let val = ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  if (val < 0.001) val = outMin;
  if (val >= 0.001 && val <= 1) val = outMin + 1;
  if (val >= outMax) val = outMax;

  return Math.round(val);
}

export function audienceTypes(type) {
  switch (type) {
    case 'ATTRIBUTION_RESPONDER':
      return 'Attribution Responder';
    case 'AUTO_POLYGON':
      return 'Commercial Address';
    case 'CARRIER_ROUTE':
      return 'Carrier Route';
    case 'CENSUS_TRACT':
      return 'Census Tract';
    case 'DEMOGRAPHICS':
      return 'Demographic';
    case 'DEVICE_IMPORT':
      return 'Device Import';
    case 'FILTERED':
      return 'Filtered';
    case 'IP':
      return 'IP Address List';
    case 'LATLNG':
      return 'Lat/Long';
    case 'MERGED':
      return 'Merged';
    case 'MERGED_POLYGON':
      return 'Merged Geoframe';
    case 'PIXEL':
      return 'Pixel Snapshot';
    case 'POI':
      return 'Quick-Select';
    case 'REVERSE_APPENDS':
      return 'Residential Address';
    case 'TRADE_AREA':
      return 'Trade Area';
    case 'PREDICTED_MOVERS':
      return 'Predicted Movers';
    default:
      return 'Geoframe';
  }
}

export function audienceTypeClasses(type) {
  switch (type) {
    case 'ATTRIBUTION_RESPONDER':
      return 'fa-street-view';
    case 'AUTO_POLYGON':
      return 'fa-city';
    case 'CARRIER_ROUTE':
      return 'fa-shipping-fast';
    case 'CENSUS_TRACT':
      return 'fa-city';
    case 'DEMOGRAPHICS':
      return 'fa-users';
    case 'DEVICE_IMPORT':
      return 'fa-mobile-android-alt';
    case 'FILTERED':
      return 'fa-filter';
    case 'IP':
      return 'fa-network-wired';
    case 'LATLNG':
      return 'fa-drafting-compass';
    case 'MERGED':
      return 'fa-folder-tree';
    case 'MERGED_POLYGON':
      return 'fa-layer-group';
    case 'PIXEL':
      return 'fa-braille';
    case 'POI':
      return 'fa-map-marker';
    case 'REVERSE_APPENDS':
      return 'fa-home';
    case 'TRADE_AREA':
      return 'fa-bullseye';
    case 'PREDICTED_MOVERS':
      return 'fa-truck';
    default:
      return 'fa-map';
  }
}

export function dateToString(dateOrDates, { formatOverride } = {}) {
  const format = formatOverride || 'YYYY-MM-DD';

  if (Array.isArray(dateOrDates)) {
    const dates = [];

    for (const date of dateOrDates) {
      dates.push(date ? moment(date).format(format) : 'N/A');
    }

    return dates;
  }

  return dateOrDates ? moment(dateOrDates).format(format) : 'N/A';
}

export function inlineDateFormat(date, formatOverride) {
  return dateToString(date, { formatOverride });
}

export function sanitizeS3Key(s3Key) {
  return s3Key
    .replace(/[\s:,&@\\^<>]/g, '_')
    .replace(/[^A-Za-z0-9!\-_.*'()]/g, '');
}

export function sanitizeLiquidMAudienceName(name) {
  return (
    (name && name.replace(/\s/g, '-').replace(/[^A-Za-z0-9_-]/g, '')) || ''
  );
}

export function permittedPublishPartners() {
  const perms = getUserPerms();
  const permittedPartners = {};

  for (const partner in partners) {
    if (perms.includes(partner)) {
      permittedPartners[partner] = partners[partner];
    }
  }

  return permittedPartners;
}

export function isEmpty(val) {
  if (typeof val === 'object') {
    return Object.keys(val).length === 0;
  }
  // This function is mostly for objects, but ok..
  if (Array.isArray(val)) {
    return val.length === 0;
  }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function secondsToString(s) {
  if (s < 0) {
    return 'unknown';
  }
  const ms = s * 1000;

  const numberEnding = (number) => {
    return (number > 1) ? 's' : '';
  };

  let temp = Math.floor(ms / 1000);
  const years = Math.floor(temp / 31536000);
  if (years) {
    return years + ' year' + numberEnding(years);
  }

  const weeks = Math.floor((temp %= 31536000) / 604800);
  if (weeks) {
    return weeks + ' week' + numberEnding(weeks);
  }
  const days = Math.floor((temp %= 604800) / 86400);
  if (days) {
    return days + ' day' + numberEnding(days);
  }
  const hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + ' hour' + numberEnding(hours);
  }
  const minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + ' minute' + numberEnding(minutes);
  }
  const seconds = temp % 60;
  if (seconds) {
    return seconds + ' second' + numberEnding(seconds);
  }
  return 'a few moments';
}
