<template>
  <div>
    <div class="instructions">
      <slot />
    </div>
    <div class="box">
      <el-form
        ref="pwdForm"
        :model="pwdForm"
        :rules="formRules"
      >
        <el-form-item prop="password">
          <el-input
            v-model="pwdForm.password"
            class="password-input"
            type="password"
            placeholder="New Password"
            show-password
            @keypress.native.enter="handleSubmit"
          >
            <template slot="prepend">
              <i class="fa fa-lock" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="confirm">
          <el-input
            v-model="pwdForm.confirm"
            class="password-input"
            type="password"
            placeholder="Confirm New Password"
            show-password
            @keypress.native.enter="handleSubmit"
          >
            <template slot="prepend">
              <i class="fa fa-lock" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="busy"
            :disabled="badPassword"
            type="primary"
            @click="handleSubmit"
          >
            {{ buttonLabel }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="password-meter">
      <password-strength-meter
        :password="pwdForm.password"
        @bad-password="handleBadPassword"
        @password-score="handlePasswordScore"
      />
    </div>
  </div>
</template>

<script>
import PasswordStrengthMeter from './PasswordStrengthMeter.vue';

/**
 * Possible score values (0-4):
 *
 * 0 - too guessable: risky password. (guesses < 10^3)
 * 1 - very guessable: protection from throttled online attacks. (guesses < 10^6)
 * 2 - somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
 * 3 - safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
 * 4 - very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
 *
 */
const MINIMUM_SCORE = 2;

export default {
  components: { PasswordStrengthMeter },
  props: {
    busy: { type: Boolean, default: false },
    buttonLabel: { type: String, required: true },
  },

  data() {
    const confirmPass = (checkField, rule, value, callback) => {
      if (this.pwdForm[checkField] !== value) {
        if (checkField === 'confirm' && this.pwdForm[checkField] === '') {
          return callback();
        }
        return callback(new Error('The passwords do not match.'));
      }

      callback();
    };

    return {
      badPassword: true,
      formRules: Object.freeze({
        password: [
          {
            required: true,
            message: 'Password is required.',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.badPassword) {
                callback(new Error('Please choose a better password.'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
          {
            validator: confirmPass.bind(this, 'confirm'),
            trigger: 'blur',
          },
        ],
        confirm: [
          {
            required: true,
            message: 'You must confirm the password you entered.',
            trigger: 'blur',
          },
          {
            validator: confirmPass.bind(this, 'password'),
            trigger: 'blur',
          },
        ],
      }),
      pwdForm: {
        password: '',
        confirm: '',
      },
    };
  },

  methods: {
    handleBadPassword(bad) {
      this.badPassword = bad;
    },

    handlePasswordScore(score) {
      if (score < MINIMUM_SCORE) {
        this.badPassword = true;
      } else {
        this.badPassword = false;
      }
    },

    async handleSubmit() {
      try {
        await this.$refs.pwdForm.validate();
        this.$emit('choose', this.pwdForm.password);
      } catch (e) {}
    },

    reset() {
      this.pwdForm.password = this.pwdForm.confirm = '';
      this.badPassword = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.instructions {
  margin-bottom: 1em;
  max-width: 380px;
}

.box {
  width: 340px;
  padding: 20px 20px 5px;
  background: #efefef;
  border-radius: 8px;
  border: 1px solid #ccc;

  .el-button {
    width: 100%;
  }

  .el-button--text {
    margin-left: 0;
    font-size: 0.85rem;
  }
}

.password-meter {
  margin-top: 1em;
}

:deep( .el-input__inner) {
  font-family: Inconsolata, Courier, monospace;
  font-size: 1.2em;
}
</style>
