import apiCall from './api-call';

export default {
  getChanges(complete = false) {
    if (complete) return apiCall('GET', '/changes', { complete: true });
    return apiCall('GET', '/changes');
  },

  saveChanges(data) {
    return apiCall('POST', '/changes', null, data);
  },
};
