import apiCall from './api-call';
import { createCrud } from './common';

export default {
  name: 'attribution',
  ...createCrud('attribution'),

  createResponderAudience(data) {
    return apiCall('POST', '/audiences/attribution-responder', null, data);
  },

  createReport(data) {
    return apiCall('POST', '/attribution', null, data);
  },

  requestReport(attributionId, data) {
    return apiCall('PUT', `/attribution/${attributionId}`, null, data);
  },

  generateDeviceFiles(attributionId) {
    return apiCall('POST', `/attribution/${attributionId}`);
  },

  getReportUrl(hashid) {
    return apiCall('GET', `/attribution/report/${hashid}`, null, null, {
      noAuth: true,
    });
  },

  getFootfallReport(hashid) {
    return apiCall('GET', `/request-footfall-attribution-report/${hashid}`, null, null, {
      noAuth: true,
    });
  },

  getSummaryReport(hashid) {
    return apiCall('GET', `/request-attribution-summary-report/${hashid}`, null, null, {
      noAuth: true,
    });
  },

  getDigitalReport(hashid) {
    return apiCall('GET', `/request-digital-attribution-report/${hashid}`, null, null, {
      noAuth: true,
    });
  },

  getPdfReport(options) {
    return apiCall('GET', '/attribution/pdf', options);
  },

  getReportData(reportId) {
    return apiCall('GET', `/request-attribution/report-data/${reportId}`, null, null, {
      noAuth: true,
    });
  },

  search(options) {
    return apiCall('GET', '/attribution/search', options);
  },

  generatePostCampaign(data) {
    return apiCall('POST', '/attribution/postcampaign/generate', null, data);
  },

  createMatchbackReport(data) {
    return apiCall('POST', '/attribution/matchback-report', null, data);
  },

  getTopCity(options) {
    return apiCall('GET', '/request-attribution/top-city', options);
  },

  getTopZipcode(options) {
    return apiCall('GET', '/request-attribution/top-zipcode', options);
  },
};
