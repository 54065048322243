import apiCall from './api-call';

export default {
  name: 'facebook',

  async userLogin(payload) {
    return apiCall('POST', '/facebook/login', null, payload);
  },

  async userLogout() {
    return apiCall('GET', '/facebook/logout');
  },

  async getAdAccounts() {
    return apiCall('GET', '/facebook');
  },
};
