<template>
  <div>
    <el-dialog
      title="Invalid Date Range"
      :visible="showDialog === 'dateFixer'"
      :before-close="closeDialog"
    >
      <div>
        This audience has a date range that falls outside of our data retention
        range. Please fix the invalid date(s) below and click "Update Dates" to
        update the audience.
      </div>
      <div
        v-loading="busy"
        class="current-dates"
      >
        <div class="date">
          <div :class="validityClass('start', audience.start)">
            {{ invalidDate('start', audience.start) ? 'Invalid' : 'OK' }}
          </div>
          <div class="value">
            {{ audience.start }}
          </div>
          <div>
            <i :class="actionIcon('start', audience.start)" />
          </div>
          <div class="date-picker">
            <el-date-picker
              v-if="invalidDate('start', audience.start)"
              v-model="startDate"
              :clearable="false"
              :editable="false"
              :picker-options="startDateOptions"
              format="M/d/yyyy"
            />
          </div>
        </div>
        <div class="to">
          to
        </div>
        <div class="date">
          <div :class="validityClass('end', audience.end)">
            {{ invalidDate('end', audience.end) ? 'Invalid' : 'OK' }}
          </div>
          <div class="value">
            {{ audience.end }}
          </div>
          <div>
            <i :class="actionIcon('end', audience.end)" />
          </div>
          <div class="date-picker">
            <el-date-picker
              v-if="invalidDate('end', audience.end)"
              v-model="endDate"
              :clearable="false"
              :editable="false"
              :picker-options="endDateOptions"
              format="M/d/yyyy"
            />
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="busy"
          @click="closeDialog"
        >
          Cancel
        </el-button>
        <el-button
          :disabled="!canSubmit || busy"
          type="primary"
          @click="updateDates"
        >
          Update Dates
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { dateToString } from '@/helpers';
import { updateInvalidDates } from '../../methods/audiences';

export default {
  props: {
    audience: { type: Object, required: true },
    rangeStart: { type: Object, required: true },
    rangeEnd: { type: Number, required: true },
    showDialog: { type: String, required: true },
  },

  data() {
    return {
      busy: false,
      startDate: this.rangeStart
        .clone()
        .add(1, 'day')
        .toDate(),
      endDate: null,
    };
  },

  computed: {
    canSubmit() {
      const validStart = !this.invalidDate('start', this.audience.start);
      const validEnd = !this.invalidDate('end', this.audience.end);

      return (
        (validStart || (!validStart && this.startDate)) &&
        (validEnd || (!validEnd && this.endDate))
      );
    },

    startDateOptions() {
      return {
        disabledDate: date =>
          moment(date).isBefore(this.rangeStart.startOf('day')) ||
          moment(date).isAfter(
            this.invalidDate('end', this.audience.end)
              ? this.endDate || this.rangeEnd
              : this.audience.end,
          ),
      };
    },

    endDateOptions() {
      return {
        disabledDate: date =>
          moment(date).isAfter(this.rangeEnd) ||
          moment(date).isBefore(
            this.invalidDate('start', this.audience.start)
              ? this.startDate || this.rangeStart.startOf('day')
              : this.audience.start,
          ),
      };
    },
  },

  methods: {
    actionIcon(extent, value) {
      const classes = {
        fa: true,
        icon: true,
      };

      if (this.invalidDate(extent, value)) {
        classes['fa-arrow-down'] = true;
        classes.bad = true;
      } else {
        classes['fa-check-circle'] = true;
        classes.ok = true;
      }

      return classes;
    },

    closeDialog() {
      this.$emit('close');
    },

    invalidDate(extent, value) {
      return (
        (extent === 'start' && moment(value).isBefore(this.rangeStart.startOf('day'))) ||
        (extent === 'end' &&
          (moment(value).isAfter(this.rangeEnd) ||
            moment(value).isBefore(this.rangeStart.startOf('day'))))
      );
    },

    async updateDates() {
      const startDate = dateToString(
        this.invalidDate('start', this.audience.start)
          ? this.startDate
          : this.audience.start,
      );
      const endDate = dateToString(
        this.invalidDate('end', this.audience.end)
          ? this.endDate
          : this.audience.end,
      );

      try {
        this.busy = true;
        await updateInvalidDates.callPromise({
          audienceId: this.audience._id,
          startDate,
          endDate,
        });

        this.closeDialog();
        this.$notify.success('Date range has been updated!');
      } catch (e) {
        this.$notify.error('There was a problem while updating dates.');
        this.$reportError(e);
      } finally {
        this.busy = false;
      }
    },

    validityClass(extent, value) {
      return {
        state: true,
        invalid: this.invalidDate(extent, value),
        valid: !this.invalidDate(extent, value),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.current-dates {
  align-items: center;
  display: flex;
  margin-top: 20px;

  .date {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 200px;

    > .value {
      font-size: 24px;
      font-weight: 300;
    }
  }

  .state {
    height: 18px;
    text-align: center;
    width: 100%;

    &.invalid {
      border-bottom: 2px solid #c00;
    }

    &.valid {
      border-bottom: 2px solid #0c0;
    }
  }

  .to {
    margin: 0 12px 6px 12px;
  }

  .icon {
    font-size: 18px;

    &.ok {
      color: #0c0;
    }

    &.bad {
      color: #c00;
    }
  }

  .date-picker {
    height: 40px;
    margin-top: 8px;
  }
}
</style>
