export default [
  {
    path: '/data-source/pixels',
    meta: {
      group: 'Data Sources',
      title: 'Web Pixels',
      permissions: ['view_pixels'],
    },
    component: () => import(/* webpackChunkName: "pixel" */ '../components/pages/pixels/PagePixelLibrary.vue'),
  },
  {
    name: 'pixel-detail',
    path: '/data-source/pixels/:id',
    meta: {
      group: 'Data Sources',
      title: 'Web Pixel Detail',
      permissions: ['view_pixels'],
      exclude: true,
    },
    component: () =>
      import(/* webpackChunkName: "pixelDetail" */ '../components/pages/pixels/PagePixelDetail.vue'),
    beforeEnter: (to, from, next) => {
      if (isNaN(to.params.id)) {
        return next(false);
      }
      next();
    },
  },
  {
    path: '/data-source/ip',
    meta: {
      group: 'Data Sources',
      title: 'IP Addresses',
      permissions: ['view_ip2maid'],
    },
    component: () => import(/* webpackChunkName: "ip" */ '../components/pages/pixels/PageIpLibrary.vue'),
  },
  {
    name: 'ip-detail',
    path: '/data-source/ip/:id',
    meta: {
      group: 'Data Sources',
      title: 'IP Address List Detail',
      permissions: ['view_ip2maid'],
      exclude: true,
    },
    component: () =>
      import(/* webpackChunkName: "ipDetail" */ '../components/pages/pixels/PageIpDetail.vue'),
    beforeEnter: (to, from, next) => {
      if (isNaN(to.params.id)) {
        return next(false);
      }
      next();
    },
  },
];
