var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"Audience Reports","visible":_vm.showDialog === 'audienceReports',"before-close":_vm.handleClose,"center":""}},[_c('div',{staticClass:"instructions"},[(!_vm.started)?_c('span',[_vm._v("No reports have been generated for this audience yet.")]):(_vm.started && !_vm.completeHash)?_c('span',[_vm._v("Please wait while your report(s) are being prepared.")]):_c('span',[_vm._v(" Select the reports you wish to include (at least one), then copy the link to share with others. ")])]),(_vm.shouldShowAudienceConfig)?_c('audience-config',{attrs:{"audience-data":_vm.audience},on:{"configsSaved":_vm.handleConfigsSaved}}):_vm._e(),_c('div',[(!_vm.isReady('insight_report'))?_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","loading":_vm.generatingReport,"disabled":_vm.deviceLimitExceeded || _vm.disableReportButtons},on:{"click":_vm.handlePrepareReports}},[_vm._v(" "+_vm._s(_vm.reportButtonLabel)+" "+_vm._s(_vm.insightProgress)+" ")]):_vm._e(),(
        !_vm.isReady('heatmap_report') && _vm.canCreateHeatmap && !_vm.enabledAudience()
      )?_c('el-button',{staticStyle:{"margin-top":"10px"},attrs:{"type":"primary","loading":_vm.generatingHeatmap,"disabled":_vm.deviceLimitExceeded || _vm.disableReportButtons},on:{"click":_vm.handlePrepareHeatmap}},[_vm._v(" Prepare Heatmap "+_vm._s(_vm.heatmapProgress)+" ")]):_vm._e(),(!_vm.isReady('political_report') && _vm.canCreatePoliticalReports)?_c('el-button',{staticStyle:{"margin-top":"10px"},attrs:{"type":"primary","loading":_vm.generatingPoliticalReport,"disabled":_vm.deviceLimitExceeded || _vm.disableReportButtons},on:{"click":_vm.handlePreparePoliticalReport}},[_vm._v(" Prepare Political Report "+_vm._s(_vm.politicalProgress)+" ")]):_vm._e()],1),(_vm.disableReportButtons)?_c('div',{staticClass:"exceed-notice"},[_c('strong',[_vm._v("Note:")]),_vm._v(" You are required to save your settings before proceeding.. ")]):_vm._e(),_c('br'),(_vm.deviceLimitExceeded)?_c('div',{staticClass:"exceed-notice"},[_c('strong',[_vm._v("Note:")]),_vm._v(" Audience count cannot exceed "+_vm._s(_vm.formattedDeviceLimit)+". ")]):_vm._e(),_c('br'),(
      _vm.isReady('insight_report') ||
        _vm.isReady('heatmap_report') ||
        _vm.isReady('political_report')
    )?_c('el-checkbox-group',{staticStyle:{"display":"inline"},model:{value:(_vm.reportSelections),callback:function ($$v) {_vm.reportSelections=$$v},expression:"reportSelections"}},_vm._l((_vm.reportTypes),function(report){return _c('el-checkbox',{key:report.enum,attrs:{"label":report.enum}},[_vm._v(" "+_vm._s(report.label)+" ")])}),1):_vm._e(),(_vm.completeHash)?_c('div',{staticClass:"share-link"},[_c('div',{staticClass:"link-box"},[_c('el-input',{attrs:{"readonly":"","size":"small","value":_vm.shareUrl}})],1),_c('el-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.shareUrl),expression:"shareUrl",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.afterCopy),expression:"afterCopy",arg:"success"}],attrs:{"disabled":_vm.reportSelections.length === 0,"icon":"fa fa-lg fa-clipboard-list","plain":"","size":"small","type":"primary"}})],1):_vm._e(),(_vm.completeHash && _vm.reportSelections.length > 0)?_c('div',{staticClass:"view-report-button"},[_c('el-button',{attrs:{"plain":"","type":"primary"},on:{"click":_vm.handleViewReports}},[_vm._v(" Standard Reports ")]),(_vm.canCreateAndViewEnhancedInsight)?_c('el-button',{attrs:{"plain":"","type":"primary","disabled":_vm.showForm},on:{"click":_vm.handleViewEnhancedReports}},[_vm._v(" Enhanced Reports ")]):_vm._e(),(_vm.canCreateAndViewEnhancedInsight)?_c('el-button',{attrs:{"plain":"","type":"primary"},on:{"click":_vm.handleDownloadPDF}},[_vm._v(" Download PDF ")]):_vm._e()],1):_vm._e(),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("Done")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }