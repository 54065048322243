import apiCall from './api-call';
import { createCrud } from './common';

export default {
  name: 'job',
  ...createCrud('jobs'),

  async getLogs(id, options) {
    return apiCall('GET', `/logs/job/${id}`, options, null, { fullPath: true, noAuth: true });
  },

  async getRequests(id, options) {
    return apiCall('GET', `/jobs/${id}/requests`, options);
  },

  async retry(id, params) {
    return apiCall('GET', `/jobs/${id}/retry`, params);
  },

  async fetchJobs(data) {
    return apiCall('POST', '/jobs/fetch-jobs', null, data);
  },
  async resolveJob(data) {
    return apiCall('POST', '/jobs/resolve-job', null, data);
  },
  async searchJobs(data) {
    return apiCall('POST', '/jobs/search', null, data);
  },
};
