import apiCall from './api-call';

/**
 * Login a user
 * @param data
 * @param data.email
 * @param data.password
 * @param options API options
 */
export async function login({ email, password }, options) {
  return apiCall(
    'POST',
    '/auth/login',
    null,
    { email, password },
    {
      ...options,
      fullPath: true,
    },
  );
}

/**
 * Log out
 * @param options API options
 */
export async function logout(options) {
  return apiCall('GET', '/auth/logout', null, null, {
    ...options,
    fullPath: true,
  });
}

/**
 * Set a new password
 * @param data
 * @param data.token
 * @param data.password
 * @param data.passwordConfirm
 * @param options API options
 */
export async function resetPassword({ token, password }, options) {
  return apiCall(
    'POST',
    '/auth/password-reset',
    null,
    {
      token,
      password,
    },
    {
      ...options,
      fullPath: true,
    },
  );
}

/**
 *
 * @param {object} data
 * @param {string} data.email Email address
 * @param {object} options
 */
export async function forgotPassword({ email }, options) {
  return apiCall(
    'POST',
    '/auth/forgot-password',
    null,
    { email },
    {
      ...options,
      fullPath: true,
    },
  );
}

/**
 * Verifies an email change (initiated by user)
 * @param {object} data
 * @param {string} data.token Verification token
 * @param {object} options
 */
export async function verifyEmail({ token, password }, options) {
  return apiCall(
    'POST',
    '/auth/verify-email',
    null,
    { token, password },
    {
      ...options,
      fullPath: true,
    },
  );
}

/**
 * Activates a newly-created account
 * @param {object} data
 * @param {string} data.token Verification token
 * @param {string} data.password User's chosen password
 * @param {object} options
 */
export async function activateAccount({ token, password }, options) {
  return apiCall(
    'POST',
    '/auth/account-activation',
    null,
    { token, password },
    {
      ...options,
      fullPath: true,
    },
  );
}
