<template>
  <div class="password-strength-meter">
    <div class="explanation">
      Password strength
    </div>
    <div>
      <el-progress
        :percentage="scorePercentage"
        :status="passwordStatus"
        :stroke-width="18"
        text-inside
      />
    </div>
    <div
      v-show="!!warning"
      class="warning"
    >
      <el-alert
        :closable="false"
        show-icon
        :title="warning"
        type="warning"
      />
    </div>
    <div
      v-show="suggestions.length > 0"
      class="suggestions"
    >
      <div>Suggestions:</div>
      <ul>
        <li
          v-for="(sugg, index) in suggestions"
          :key="index"
        >
          {{ sugg }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    password: { type: String, default: '' },
  },

  data() {
    return {
      passwordScore: 0,
      suggestions: [],
      warning: '',
    };
  },

  computed: {
    passwordStatus() {
      if (this.passwordScore < 3) return 'exception';
      else if (this.passwordScore === 3) return null;
      return 'success';
    },

    scorePercentage() {
      return (this.passwordScore * 100) / 4;
    },
  },

  watch: {
    password(value) {
      if (!this.zxcvbn) return; // not imported yet

      const report = this.zxcvbn(this.password);
      const { score, feedback } = report;

      this.$emit('password-score', score);

      this.passwordScore = score;
      this.warning = feedback.warning ? feedback.warning : false;
      if (this.warning) {
        this.$emit('bad-password', true);
      }
      this.suggestions = feedback.suggestions.filter(
        s =>
          !/^Add another word/.test(s) &&
          !/^No need for symbols/.test(s) &&
          !/^Use a few words/.test(s),
      );
    },
  },

  async created() {
    this.zxcvbn = (await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn')).default;
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.password-strength-meter {
  width: 380px;
}

.explanation {
  font-size: 0.8em;
  margin-bottom: 0.4em;
}

.warning {
  margin-top: 1em;
}

.suggestions {
  font-size: 0.8em;
  margin-top: 1em;
}

ul {
  list-style-type: none;
  padding-left: 1em;

  li {
    line-height: 1.5;
  }

  li:before {
    color: $--color-primary;
    content: '\f05a';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 0.4em;
  }
}
</style>
