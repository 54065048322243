import apiCall from '@/adonis-api/api-call';
import * as mutation from './mutation-types';

const state = {
  geoframeOrder: null,
};

const actions = {
  clearGeoframeOrder({ commit }) {
    commit(mutation.CLEAR_GEOFRAME_ORDER);
  },

  async getCount(_, geoframe) {
    const method = geoframe.id ? 'PUT' : 'POST';
    const path = geoframe.id
      ? `/geoframes/${geoframe.id}/count`
      : '/geoframes/count';
    const data = {
      geojson: geoframe.geojson,
      name: geoframe.name,
      organizationId: geoframe.organizationId,
      startDate: geoframe.startDate,
      endDate: geoframe.endDate,
    };
    const result = await apiCall(method, path, null, data);

    return result.data.id; // job ID
  },

  populateOrderItem({ commit }, orderItem) {
    commit(mutation.SET_GEOFRAME_ORDER, orderItem);
  },

  save(_, geoframe) {
    apiCall(
      geoframe.id ? 'PUT' : 'POST',
      geoframe.id ? `/geoframes/${geoframe.id}` : '/geoframes',
      null,
      geoframe,
    );
  },
};

const mutations = {
  [mutation.CLEAR_GEOFRAME_ORDER](state) {
    state.geoframeOrder = null;
  },
  [mutation.SET_GEOFRAME_ORDER](state, orderItem) {
    state.geoframeOrder = orderItem;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
