<template>
  <div>
    <div class="tags">
      <el-tag
        v-for="tag in internalTags"
        :key="tag.id + tag.name"
        closable
        :disable-transitions="false"
        :size="tagSize"
        @close="removeTag(tag)"
      >
        {{ tag.name }}
      </el-tag>
    </div>

    <div class="tag-picker">
      <el-form
        ref="tagForm"
        :model="form"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item prop="tagName">
          <el-autocomplete
            v-if="inputVisible"
            ref="saveTagInput"
            v-model="form.tagName"
            class="full-width"
            placeholder="Please enter a keyword"
            :debounce="500"
            :fetch-suggestions="tagQuery"
            :loading="busy"
            select-when-unmatched
            :trigger-on-focus="false"
            @blur="blurred"
            @keyup.native.esc="handleEsc"
            @select="addTag"
          />
          <el-button
            v-else
            class="full-width"
            @click="showInput"
          >
            + New Tag
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { tag } from '@/adonis-api';
import { regexPatterns } from '@/constants';

export default {
  props: {
    tags: { type: Array, default: () => [] },
    tagSize: { type: String, default: 'small' },
  },

  data() {
    return {
      busy: false,
      form: {
        tagName: '',
      },
      internalTags: [],
      inputVisible: false,
      inputValue: '',
      rules: Object.freeze({
        tagName: [
          {
            validator(a, value, callback, d) {
              if (value && !regexPatterns.tagName.test(value)) {
                callback(
                  new Error(
                    'Name must contain 2-32 characters and consist of ' +
                      'letters, numbers, and spaces.',
                  ),
                );
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
      }),
    };
  },

  watch: {
    tags: {
      handler(newValue) {
        this.internalTags = [...newValue];
      },
      immediate: true,
    },
  },

  methods: {
    async addTag(tag) {
      try {
        await this.$refs.tagForm.validate();
      } catch (e) {
        return;
      }

      // Add tag if it's not already been added.
      const name = tag.data ? tag.data.name : tag.value ? tag.value : tag;
      const index = this.internalTags.findIndex(t => t.name === name);
      if (index < 0) {
        this.internalTags.push({ name });
        this.$emit('add-tag', name);
        this.$emit('update:tags', this.internalTags);
      }
      this.form.tagName = '';
    },

    async blurred(event) {
      try {
        await this.$refs.tagForm.validate();
      } catch (e) {
        return;
      }

      if (!this.$refs.saveTagInput) return;

      if (event.relatedTarget) {
        const field = this.$refs.saveTagInput.$refs.input;
        if (field.value) {
          this.addTag(field.value);
        }
        this.inputVisible = false;
      } else {
        this.$refs.saveTagInput.$refs.input.focus();
      }
    },

    handleEsc() {
      this.$refs.tagForm.resetFields();
      this.inputVisible = false;
    },

    removeTag({ name }) {
      const index = this.internalTags.findIndex(t => t.name === name);

      if (index >= 0) {
        this.$emit('remove-tag', this.internalTags[index].id);
        this.internalTags.splice(index, 1);
        this.$emit('update:tags', this.internalTags);
      }
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    async tagQuery(query, callback) {
      try {
        await this.$refs.tagForm.validate();
      } catch (e) {
        return callback([]);
      }

      if (!query || query.length < 2) {
        return;
      }

      this.busy = true;
      const result = await tag.find(
        {
          search: query,
        },
        true,
      );
      this.busy = false;

      callback(
        result.data.map(tag => ({
          value: tag.name,
          data: {
            id: tag.id,
            name: tag.name,
          },
        })),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tags > span {
  margin-bottom: 4px;

  &:not(:last-child) {
    margin-right: 4px;
  }
}

.tag-picker {
  margin-bottom: 2.2em;
  margin-top: 8px;
}

.full-width {
  width: 100%;
}
</style>
