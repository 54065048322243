export default [
  {
    path: '/import/residential-audience',
    meta: {
      group: 'Uploads',
      title: 'Residential Address Audience',
      permissions: ['create_reverse_appends_audiences'],
    },
    component: () => import(/* webpackChunkName: "residentialAudienceImport" */ '../components/pages/audiences/PageReverseAppendsCreate.vue'),
  },
  {
    path: '/import/commercial-audience',
    meta: {
      group: 'Uploads',
      title: 'Commercial Address Audience',
      permissions: ['import_address_audiences'],
    },
    component: () => import(/* webpackChunkName: "commercialAudienceImport" */ '../components/pages/audiences/PageAutopolygonCreate.vue'),
  },
  {
    path: '/import/carrier-route-audience',
    meta: {
      group: 'Uploads',
      title: 'Carrier Route Audience',
      permissions: ['create_carrier_route_audiences'],
    },
    component: () => import(/* webpackChunkName: "carrierRouteAudienceImport" */ '../components/pages/audiences/PageCarrierRoutesCreate.vue'),
  },
  {
    path: '/import/census-tract-audience',
    meta: {
      group: 'Uploads',
      title: 'Census Tract Audience',
      permissions: ['create_census_tract_audiences'],
    },
    component: () => import(/* webpackChunkName: "censusTractAudienceImport" */ '../components/pages/audiences/PageCensusTractCreate.vue'),
  },
  {
    path: '/import/lat-lng-audience',
    meta: {
      group: 'Uploads',
      title: 'Lat/Long Audience',
      permissions: ['import_latlng_audiences'],
    },
    component: () => import(/* webpackChunkName: "latLngAudienceImport" */ '../components/pages/audiences/PageLatLngCreate.vue'),
  },
  {
    path: '/upload/address-list',
    meta: {
      group: 'Uploads',
      title: 'Rapid Geoframe Builder',
      permissions: ['request_geoframes'],
    },
    component: () => import(/* webpackChunkName: "orderRequest" */ '../components/pages/orders/PageOrderRequest.vue'),
  },
  {
    path: '/process/address-list/:id',
    meta: {
      group: 'Uploads',
      title: 'Order Queue',
      permissions: ['process_orders'],
      exclude: true,
    },
    component: () => import(/* webpackChunkName: "orderQueue" */ '../components/pages/orders/PageOrderQueue.vue'),
  },
  {
    path: '/import/audiences',
    meta: {
      group: 'Uploads',
      title: 'Audience Import',
      permissions: ['import_device_audiences'],
    },
    component: () =>
      import(/* webpackChunkName: "audienceImport" */ '../components/pages/audiences/PageAudienceImport.vue'),
  },
  {
    path: '/import/geoframes',
    meta: {
      group: 'Uploads',
      title: 'Geoframe Import',
      permissions: ['import_geoframes'],
    },
    component: () => import(/* webpackChunkName: "geoframeImport" */ '../components/pages/geoframes/PageGeoframeImport'),
  },
];
