import mqtt from 'mqtt';
import apiCall from './adonis-api/api-call';

let client;

async function initMqtt(clientId = null) {
  const result = await apiCall('GET', '/secrets', {
    keys: ['mqtt'],
  });

  if (!result) return;

  const { host, port, username, password } = result.mqtt;

  client = mqtt.connect(
    `mqtts://${host}:${port}`,
    { username, password, clientId },
  );
}

function getMqttClient() {
  if (client && client.connected) return Promise.resolve(client);

  // Client isn't connected, let's wait for it
  /* eslint-disable no-async-promise-executor */
  return new Promise(async resolve => {
    if (!client) {
      await initMqtt();
    }
    client.on('connect', () => resolve(client));
    client.on('error', async (error) => {
      // Code 133: Client Identifier not valid
      //           we'll attempt to re-init
      if (error.code === 133) {
        await initMqtt();
      } else {
        // eslint-disable-next-line
        console.error('MQTT ERROR', error);
      }
    });
  });
}

export { getMqttClient, initMqtt };
