<template>
  <div>
    <div
      v-show="historicalAccessAllowed && historicalDatesChosen"
      class="historical-note"
    >
      (historical dates selected)
    </div>
    <el-date-picker
      ref="datePicker"
      v-model="selection"
      :clearable="false"
      :editable="false"
      :end-placeholder="placeholders[1]"
      :picker-options="datePickerOptions"
      :start-placeholder="placeholders[0]"
      align="right"
      format="M/d/yyyy"
      type="daterange"
      v-on="$listeners"
      @focus="minDate = null"
    />
    <el-tooltip
      v-if="tooltip"
      class="item"
      effect="light"
      :content="tooltip"
    >
      <i class="far fa-question-circle ht-icon" />
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { datePickerWindow } from '@/constants';

const NORMAL_WINDOW_SIZE = datePickerWindow.normal;
const HISTORICAL_WINDOW_SIZE = datePickerWindow.historical;

export default {
  props: {
    dateRange: { type: Array, required: true },
    from: { type: Date, default: null },
    organization: {
      type: Object,
      default() {
        return {
          historicalAccess: false,
        };
      },
    },
    openStartDate: { type: Boolean, default: false },
    placeholders: { type: Array, default: () => ['Start date', 'End date'] },
    to: { type: Date, default: null },
    tooltip: { type: String, default: null },
    rangeDateOverride: { type: Boolean, default: false },
  },

  data() {
    return {
      // TODO: clean up this code
      datePickerOptions: {
        disabledDate: date => {
          let disabled = false;

          // Check start date
          if (!this.openStartDate) {
            if (this.historicalAccessAllowed) {
              disabled = moment(date).isBefore(moment('2016-01-01'));
              disabled =
                disabled ||
                (moment(date).isAfter(
                  moment(this.minDate).add(HISTORICAL_WINDOW_SIZE, 'months'),
                ) ||
                  moment(date).isBefore(
                    moment(this.minDate).subtract(
                      HISTORICAL_WINDOW_SIZE,
                      'months',
                    ),
                  ));
            } else {
              disabled = moment(date).isBefore(
                this.from ||
                  moment(this.rangeStart)
                    .clone()
                    .startOf('day'),
              );

              disabled =
                disabled ||
                (!this.hasPermission('unrestricted_date_window') &&
                  (moment(date).isAfter(
                    moment(this.minDate).add(NORMAL_WINDOW_SIZE, 'months'),
                  ) ||
                    moment(date).isBefore(
                      moment(this.minDate).subtract(
                        NORMAL_WINDOW_SIZE,
                        'months',
                      ),
                    )));
            }
          }

          // Check end date
          if (moment(date).isAfter(this.to || this.endDate)) {
            disabled = true;
          }

          return disabled;
        },
        onPick: ({ minDate, maxDate }) => {
          this.minDate = minDate;
          this.maxDate = maxDate;
        },
        shortcuts: [
          {
            text: 'Latest week',
            onClick: picker => {
              const end = this.to ? this.to : new Date(this.endDate);
              const start = new Date();
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Latest 30 days',
            onClick: picker => {
              const end = this.to ? this.to : new Date(this.endDate);
              const start = new Date();
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Latest 90 days',
            onClick: picker => {
              const end = this.to ? this.to : new Date(this.endDate);
              const start = new Date();
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      maxDate: null,
      minDate: null,
      selection: this.dateRange,
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeStart', 'rangeEndDate']),
    ...mapGetters('user', ['hasPermission']),

    historicalAccessAllowed() {
      return (
        this.organization.historical_access &&
        this.hasPermission('historical_access')
      );
    },

    endDate() {
      if (this.rangeDateOverride) {
        return moment().subtract(1, 'days').valueOf();
      }
      return this.rangeEndDate;
    },

    historicalDatesChosen() {
      return moment(this.dateRange[0]).isBefore(
        moment(this.rangeStart)
          .clone()
          .startOf('day'),
      );
    },
  },

  watch: {
    dateRange(newVal) {
      this.selection = newVal;
    },

    historicalAccessAllowed(newVal) {
      if (!newVal) {
        // Check to make sure we don't have invalid dates selected
        if (
          moment(this.dateRange[0]).isBefore(
            this.from ||
              moment(this.rangeStart)
                .clone()
                .startOf('day'),
          )
        ) {
          this.$emit('update:dateRange', [this.rangeStart, this.rangeStart]);
          this.$notify.warning(
            "Your selected date range fell outside the allowable range. It's been adjusted.",
          );
        }
      }
    },

    selection(newVal) {
      this.$emit('update:dateRange', newVal);
    },
  },
};
</script>

<style>
.historical-note {
  font-size: 0.7em;
  color: #999;
  margin-bottom: 6px;
}
.el-picker-panel__shortcut {
  font-size: 13px !important;
}
</style>
