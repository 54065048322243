import apiCall from './api-call';

export default {
  name: 'tiktok',

  async getAuthUrl() {
    return apiCall('GET', '/tiktok-auth-url');
  },

  async getAdAccounts() {
    return apiCall('GET', '/tiktok');
  },

  async disconnect() {
    return apiCall('POST', '/tiktok/disconnect');
  },
};
