import _get from 'lodash/get';
import _pick from 'lodash/pick';
import * as mutation from './mutation-types';

const MAX_ACTIVITY_ENTRIES = 30;

const state = {
  activity: [],
  jobs: [],
};

const actions = {
  addActivity({ commit }, { orgId, userId, payload }) {
    commit(mutation.ADD_ACTIVITY, { orgId, userId, payload });
  },

  addJob({ commit }, { id, orgId, userId, payload }) {
    commit(mutation.ADD_JOB, { id, orgId, userId, payload });
  },

  addRawJob({ dispatch }, job) {
    dispatch('addJob', {
      id: job.id,
      createdAt: new Date(job.created_at),
      updatedAt: new Date(job.updated_at),
      orgId: job.organization_Id,
      userId: job.user_id,
      payload: {
        ..._pick(job, ['status', 'label', 'type']),
        eta: {
          eta: null,
          etah: 'unknown',
          progress:
            job.progress
              ? Number(job.progress)
              : job.__meta__ && Number(job.__meta__.requests_count) > 0
                ? Number(job.__meta__.completed_requests_count) /
                  Number(job.__meta__.requests_count)
                : 0,
        },
      },
    });
  },

  clearCompletedJobs({ commit }) {
    commit(mutation.CLEAR_COMPLETED_JOBS);
  },

  async parseRawMqtt({ dispatch }, { topic, message }) {
    let decoder;

    if (typeof TextDecoder === 'undefined') {
      const encoding = await import('text-encoder');

      decoder = new encoding.TextDecoder();
    } else {
      decoder = new TextDecoder();
    }

    const data = decoder.decode(message);
    const [topicName, orgId, userId, jobId] = topic.split('/');

    try {
      switch (topicName) {
        case 'activity':
          dispatch('addActivity', {
            orgId: Number(orgId),
            userId: Number(userId),
            payload: JSON.parse(data),
          });
          break;
        case 'job':
          dispatch('addJob', {
            id: Number(jobId),
            orgId: Number(orgId),
            userId: Number(userId),
            payload: JSON.parse(data),
          });
          break;
        case 'user-update':
          dispatch(
            'user/updateUserDetails',
            {
              payload: JSON.parse(data),
            },
            { root: true },
          );
          break;
        default:
          break;
      }
    } catch (e) {
      // invalid JSON? report error via Papertrail
    }
  },
};

const mutations = {
  [mutation.ADD_ACTIVITY](state, { orgId, userId, payload }) {
    state.activity.push({
      orgId,
      userId,
      payload,
      createdAt: new Date(),
    });

    if (state.activity.length > MAX_ACTIVITY_ENTRIES) {
      state.activity.splice(0, state.activity.length - MAX_ACTIVITY_ENTRIES);
    }
  },

  [mutation.ADD_JOB](
    state,
    { id, orgId, userId, payload, createdAt, updatedAt },
  ) {
    const jobIndex = state.jobs.findIndex(job => job.id === id);

    if (jobIndex >= 0) {
      // Job already exists - update it, unless already complete, then do nothing
      if (state.jobs[jobIndex].payload.status !== 'COMPLETE') {
        state.jobs[jobIndex].payload = payload;
        state.jobs[jobIndex].updatedAt = new Date();
      }
    } else {
      state.jobs.push({
        id,
        orgId,
        userId,
        payload,
        createdAt: createdAt || new Date(),
        updatedAt: updatedAt || new Date(),
      });
    }
  },

  [mutation.CLEAR_COMPLETED_JOBS](state) {
    state.jobs = state.jobs.filter(
      job =>
        job.payload.status !== 'COMPLETE' && job.payload.status !== 'FAILED',
    );
  },
};

const getters = {
  getJob: state => jobId => state.jobs.find(job => jobId === job.id),
  getJobs: state => jobIds => state.jobs.filter(job => jobIds.includes(job.id)),
  getJobsByType: state => jobType =>
    state.jobs.filter(job => _get(job, 'payload.type') === jobType),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
