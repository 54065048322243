import { getUserPerms } from '../helpers';

const icons = {
  Geoframes: 'fas fa-fw fa-lg fa-map',
  Audiences: 'fas fa-fw fa-lg fa-users',
  Administrative: 'fas fa-fw fa-lg fa-crown',
  Settings: 'fas fa-fw fa-lg fa-cogs',
  Uploads: 'fas fa-fw fa-lg fa-clipboard-list',
  Attribution: 'fas fa-fw fa-lg fa-chart-network',
  'Data Sources': 'fas fa-fw fa-lg fa-server',
};

function routeFilter(route, options = {}) {
  if (!options.overrideExclude && route.meta.exclude) return false;
  if (route.meta.partial) {
    return route.meta.permissions.some(p => getUserPerms().includes(p));
  }

  return (
    !route.meta.nonPage &&
    (!route.meta.permissions ||
      route.meta.permissions.every(p => getUserPerms().includes(p)))
  );
}

/**
 * Returns the first accessible path for the user.
 * @param {router} Vue-Router instance
 * @returns {string} first available route for user.
 */
export function userDefaultPath(router, redirect = false) {
  if (redirect) {
    return '/dashboard';
  }
  for (const route of router.options.routes) {
    if (routeFilter(route, { overrideExclude: true })) {
      return route.path;
    }
  }
}
window.defaultPath = userDefaultPath;

export function routesToNav(routes, routePath) {
  const navArray = [];

  for (const route of routes.filter(routeFilter)) {
    let childMenuItems;

    if (route.children) {
      childMenuItems = routesToNav(
        route.children,
        route.path,
      );
    }

    if (routePath) {
      navArray.push({
        index: `${routePath}/${route.path}`,
        meta: route.meta,
      });
    } else {
      const pathGroup = route.path.match(/^(.*)\//)[1];
      const groupName = route.meta.group;
      let navIndex = navArray.findIndex(
        navItem => navItem.groupName === groupName,
      );

      if (navIndex === -1) {
        navIndex =
          navArray.push({
            icon: icons[groupName],
            index: pathGroup,
            groupName: groupName,
            menuItems: [],
          }) - 1;
      }

      try {
        if (!route.children) {
          navArray[navIndex].menuItems.push({
            index: route.path,
            meta: route.meta,
          });
        } else {
          navArray[navIndex].menuItems.push(...childMenuItems);
        }
      } catch (e) {
        // handle it
      }
    }
  }

  return navArray;
}
