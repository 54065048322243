<template>
  <el-dialog
    :before-close="handleClose"
    :title="dialogTitle"
    :visible="visible"
    :width="width"
    :fullscreen="full"
  >
    <el-form
      ref="form"
      :label-position="labelPosition"
      :label-width="labelWidth"
      :model="form"
      :rules="elRules"
    >
      <slot
        :adding-item="addingItem"
        :error="error"
        :form-data="form"
      />
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel">Cancel</el-button>
      <el-button
        :disabled="saveDisabled"
        :loading="saveState"
        type="primary"
        @click="handleSubmit"
      >Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'EditDialog',
  props: {
    customRules: { type: Object, default: () => ({}) },
    data: { type: Object, required: true },
    // Element UI rules system
    full: { type: Boolean, default: false },
    elRules: { type: Object, default: () => ({}) },
    labelPosition: { type: String, default: 'right' },
    labelWidth: { type: String, default: '100px' },
    objectName: { type: String, default: 'Item' },
    overrideTitle: { type: String, default: null },
    visible: { type: Boolean, default: false },
    width: { type: String, default: '50%' },
    saveDisabled: { type: Boolean, default: false },
    saveState: { type: Boolean, default: false },
  },

  data() {
    return {
      error: '',
      form: {},
    };
  },

  computed: {
    addingItem() {
      // return (
      //   Object.values(this.form).filter(
      //     v => typeof v === 'string' || typeof v === 'number'
      //   ).length === 0
      // );
      return Object.keys(this.data).length === 0;
    },

    dialogTitle() {
      if (this.overrideTitle) {
        return this.overrideTitle;
      }
      return (this.addingItem ? 'Adding ' : 'Editing ') + this.objectName;
    },
  },

  watch: {
    visible: {
      handler(value) {
        this.form = { ...this.data };

        // If modal is closing, clear form errors
        if (!value) {
          this.$refs.form && this.$refs.form.clearValidate();
        }
      },
      immediate: true,
    },
  },

  methods: {
    checkCustomRules() {
      for (const prop in this.customRules) {
        for (const rule of this.customRules[prop]) {
          if (!rule.validate(this.form[prop])) {
            this.error = rule.errorMessage;
            return false;
          }
          this.error = '';
        }
      }

      return true;
    },

    async checkElementRules() {
      // Form may not be rendered yet
      if (!this.$refs.form) return true;

      try {
        await this.$refs.form.validate();
      } catch (e) {
        throw new Error('IgnoreMe');
      }

      return true;
    },

    handleCancel() {
      this.$emit('request-close');
      this.$emit('update:visible', false);
    },

    handleClose() {
      this.$emit('request-close');
      this.$emit('update:visible', false);
    },

    async handleSubmit() {
      try {
        await this.checkElementRules();
        if (this.checkCustomRules()) {
          this.$emit('save', this.form);
        }
      } catch (e) {
        // Invalid form - do nothing
      }
    },

    reset() {
      this.$refs.form && this.$refs.form.resetFields();
    },
  },
};
</script>

<style>
.el-dialog {
  min-width: 895px;
}
</style>
