<template>
  <div class="system-load">
    <div v-if="apiPressure">
      <el-dialog :visible.sync="showDetails">
        <div
          v-for="(queue, org) in groupedQueueData"
          :key="org"
          class="queue"
        >
          <div class="org-name">
            {{ org }}
          </div>
          <div
            v-for="(count, countType) in queue"
            :key="org + countType"
            class="count-group"
          >
            <div class="count-type">
              {{ countType }}
            </div>
            <div style="display:flex; align-items:center;">
              <el-progress
                :percentage="getPercent(count)"
                :show-text="false"
                :status="getProgressColor(count)"
                style="flex: 1 100%;"
              />
              <span style="margin-left: 1em; width: 8em;">{{ count | numeral }}</span>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-popover
        ref="queuePopover"
        trigger="hover"
      >
        <span v-if="queueCount < apiPressure[0]">
          Your jobs should complete in a normal amount of time.
        </span>
        <span
          v-else-if="queueCount >= apiPressure[0] && queueCount <= apiPressure[1]"
        >
          Your jobs may take up to 30-40 minutes longer than normal.
        </span>
        <span v-else>
          Your jobs may take over an hour longer than normal.
        </span>
        <span v-if="queueDetailPerms">Click button for details.</span>
      </el-popover>

      <el-tag
        slot="reference"
        v-popover:queuePopover
        :style="sysLoadBtnStyle"
        :type="apiStatus"
        @click="showLoadDetails"
      >
        System Load: {{ systemLoad }}
      </el-tag>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _sumBy from 'lodash/sumBy';
import _groupBy from 'lodash/groupBy';
import { remap } from '@/helpers';
import { system } from '@/adonis-api';

export default {
  data() {
    return {
      queueData: [],
      showDetails: false,
    };
  },

  computed: {
    ...mapState('settings', ['options']),
    ...mapGetters('user', ['hasPermission']),

    apiPressure() {
      return this.options['apiPressure:range'];
    },

    apiStatus() {
      if (this.queueCount === -1) return '';
      else if (this.queueCount > this.apiPressure[1]) return 'danger';
      else if (
        this.queueCount >= this.apiPressure[0] &&
        this.queueCount <= this.apiPressure[1]
      ) {
        return 'warning';
      }

      return 'success';
    },

    groupedQueueData() {
      const grouped = _groupBy(this.queueData, 'orgName');
      const orgQueueData = {};

      for (const org in grouped) {
        orgQueueData[org] = {};

        for (const count of grouped[org]) {
          orgQueueData[org][count.name] = count.count;
        }
      }

      return Object
        .keys(orgQueueData)
        .sort((a, b) => {
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          else if (b.toLowerCase() > a.toLowerCase()) return -1;
          return 0;
        })
        .reduce((result, key) => {
          result[key] = orgQueueData[key];
          return result;
        }, {});
    },

    queueCount() {
      return _sumBy(this.queueData, 'count');
    },

    queueDetailPerms() {
      return this.hasPermission('system_load');
    },

    sysLoadBtnStyle() {
      return {
        cursor: this.queueDetailPerms ? 'pointer' : 'default',
      };
    },

    systemLoad() {
      if (this.apiStatus === '') return 'Unknown';
      else if (this.apiStatus === 'danger') return 'Heavy';
      else if (this.apiStatus === 'warning') return 'Moderate';
      return 'Light';
    },
  },

  async created() {
    const getStatus = async () => {
      const queueData = await system.getApiQueueStatus();
      this.queueData = Object.freeze(queueData);
    };

    // Refresh every minute
    setInterval(getStatus, 60000);

    getStatus();
  },

  methods: {
    getPercent(count) {
      return remap(count / this.apiPressure[1], 0, 1, 0, 100);
    },

    getProgressColor(count) {
      return count < this.apiPressure[0]
        ? 'success'
        : count > this.apiPressure[1]
          ? 'exception'
          : '';
    },

    showLoadDetails() {
      if (this.queueDetailPerms) this.showDetails = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sys-load-btn {
  cursor: pointer;
}

.queue:not(:first-of-type) {
  margin-top: 1.4em;
}

.org-name {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0.6em;
}

.count-type {
  margin-bottom: 0.3em;
}

.count-group:not(:last-of-type) {
  margin-bottom: 0.7em;
}
</style>
