<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible="showDialog === 'clone'"
      :before-close="closeDialog"
    >
      <el-form>
        <el-form-item label="Audience Name">
          <el-input v-model="form.name" />
        </el-form-item>

        <el-tabs @tab-click="cloneMethodSet">
          <el-tab-pane label="One Time">
            <el-form-item label="Date Range">
              <date-picker
                :date-range.sync="form.dateRange"
                :organization="form.organization"
              />
              <span
                class="old-dates"
              >Source Audience: {{ audience.start_date }} &ndash;
                {{ audience.end_date }}</span>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Recurring">
            <clone-scheduler
              :clone-form="form"
              :schedule.sync="schedule"
            />
          </el-tab-pane>
        </el-tabs>
        <el-form-item>
          <tag-picker :tags.sync="form.tags" />
        </el-form-item>
        <el-form-item>
          <label>Organization</label>
          <org-picker :organization.sync="form.organization" />
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <div class="footer-container">
          <div class="options">
            <h5>After clone, initiate:</h5>
            <el-select
              v-model="reports"
              multiple
              collapse-tags
              placeholder="Reports"
            >
              <el-option
                v-if="hasPermission('demographic_report') || hasPermission('insight_report')"
                key="insight"
                value="insight"
                label="Demographics & Insights"
              />
              <el-option
                v-if="hasPermission('heatmap_report')"
                key="heatmap"
                value="heatmap"
                label="Heatmap"
              />
              <el-option
                v-if="hasPermission('political_report')"
                key="political"
                value="political"
                label="Political"
              />
            </el-select>
            <el-select
              v-model="downloads"
              multiple
              collapse-tags
              placeholder="Downloads"
              class="download-select"
            >
              <el-option
                v-for="item in downloadOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </div>
          <div>
            <el-button
              @click="closeDialog"
            >
              Cancel
            </el-button>
            <el-button
              :disabled="!canClone"
              :loading="busy"
              type="primary"
              @click="startClone"
            >
              Clone
            </el-button>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TagPicker from '../global/TagPicker';
import OrgPicker from '../global/OrgPicker';
import { dateToString, isDeviceAudience } from '@/helpers';
import DatePicker from '../global/DatePicker.vue';
import { audience } from '@/adonis-api';
import { getValidationErrors } from '@/helpers/validation-rules';
import CloneScheduler from './CloneScheduler.vue';
import { deviceDownloads, downloadTypes } from '@/constants';
import moment from 'moment';

export default {
  components: { DatePicker, TagPicker, OrgPicker, CloneScheduler },
  props: {
    audience: {
      type: Object,
      required: true,
    },
    showDialog: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      busy: false,
      cloneId: '',
      form: {
        name: '',
        dateRange: [],
        tags: [],
        organization: {},
        cloneMethod: 'standard',
      },
      historicalAccess: false,
      downloads: [],
      reports: [],
      schedule: {
        frequency: [30, 'days'],
        from: new Date(),
        until: moment().add(1, 'day').toDate(),
        lookback: [1, 'months'],
      },
      types: downloadTypes,
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeStart']),
    ...mapGetters('user', ['permissions', 'hasPermission']),
    canClone() {
      return (
        !!this.form.name &&
        ((this.form.dateRange.length > 0 &&
        this.form.dateRange.every(date => date !== null)) ||
        this.form.cloneMethod === 'scheduled')
      );
    },

    downloadOptions() {
      const perms = this.permissions
        .filter(p => p.startsWith('download_') && p !== 'download_unhashed')
        .map(p => p.replace('download_', '').toUpperCase());

      if (isDeviceAudience(this.audience.type)) {
        return this.types.filter(t => perms.includes(t.id)).filter(t => deviceDownloads.includes(t.id));
      }

      // Omit Addresses & Devices for polygon audiences.
      return this.types.filter(t => t.id !== 'ADDRESS_DEVICES' && perms.includes(t.id));
    },

    dialogTitle() {
      return `Clone "${this.audience.name}" Audience`;
    },
  },

  watch: {
    showDialog(value) {
      if (!value) return;

      this.form.name = `Clone of ${this.audience.name.replace(/Clone of/g, '').trim()}`;
      this.form.tags = this.audience.tags;
      this.form.organization = this.audience.organization;
      this.cloneId = this.audience._id;
    },
  },

  methods: {
    cloneMethodSet(tab) {
      switch (tab.paneName) {
        case '1':
          this.form.cloneMethod = 'scheduled';
          break;
        default:
        case '0':
          this.form.cloneMethod = 'standard';
          break;
      }
    },
    orgMetadata(metadata) {
      this.historicalAccess = !!metadata.historicalAccess;
    },

    async startClone() {
      this.busy = true;
      const params = {
        name: this.form.name.trim(),
        tags: this.form.tags,
        organization_id: this.form.organization.id,
        start_date: dateToString(this.form.dateRange[0]),
        end_date: dateToString(this.form.dateRange[1]),
        schedule: this.schedule,
        method: this.form.cloneMethod,
        heatmap: this.reports.includes('heatmap'),
        insight: this.reports.includes('insight') || this.reports.includes('demographic'),
        political: this.reports.includes('political'),
        downloads: this.downloads,
      };

      try {
        const job = await audience.clone(this.audience.id, params);

        if (this.form.cloneMethod === 'standard') {
          this.$store.dispatch('mqtt/addRawJob', job);

          this.$message({
            message: 'Clone initiated.',
            type: 'success',
          });
          this.$emit('clone', job.id);

          window.mixpanel.track('Clone Audience', {
            ID: this.audience.id,
            Name: this.audience.name,
            'Start Date': dateToString(this.form.dateRange[0]),
            'End Date': dateToString(this.form.dateRange[1]),
            'Job ID': job.id,
          });
        } else {
          if (job) {
            this.$store.dispatch('mqtt/addRawJob', job);
            this.$emit('clone', job.id);
          }

          this.$message({
            message: 'Clone schedule created.',
            type: 'success',
          });

          window.mixpanel.track('Schedule Audience Clone', {
            ID: this.audience.id,
            Name: this.audience.name,
            Schedule: this.schedule,
          });
        }
        this.$root.$emit('clone-scheduled');
      } catch (e) {
        if (e.response) {
          const errors = getValidationErrors(e);

          if (errors.name) {
            this.$notify.error({
              message: errors.name,
            });
            return;
          }
        }

        this.$notify.error({
          message:
            'There was a problem cloning this audience. This issue has been reported.',
        });
        this.$reportError(e, {
          componentName: this.$options.name,
          cloneAudienceId: this.audience.id,
          newGeoframe: {
            ...params,
            geojson: '[omitted]',
          },
        });
      } finally {
        this.busy = false;
      }

      this.closeDialog();
    },

    closeDialog() {
      this.$emit('close');

      // Reset form (except title)
      this.form.dateRange = [];
      this.form.tags = [];
      this.form.organization = {};
      this.schedule = {
        frequency: [30, 'days'],
        from: new Date(),
        until: moment().add(1, 'day').toDate(),
        lookback: [1, 'months'],
      };
      this.downloads = [];
      this.reports = [];
    },
  },
};
</script>

<style scoped>
.footer-container {
  display: flex;
  flex: 0 1 0%;
  align-items: center;
  justify-content: space-between;
 }

 .download-select {
   width: 320px;
   margin-left: .5em;
 }

.options h5 {
  display: inline;
  margin-right: .5em;
}

.old-dates {
  font-size: 0.8em;
  font-style: italic;
  text-align: center;
  max-width: 520px;
  min-width: 250px;
  width: auto;
  display: block;
}
</style>
