export default [
  {
    path: '/attribution-reports/library',
    meta: {
      group: 'Attribution',
      title: 'Attribution Report Library',
      permissions: ['view_attribution_reports'],
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PageAttributionLibrary.vue'),
  },
  {
    path: '/attribution-reports/create',
    meta: {
      group: 'Attribution',
      title: 'Create Attribution Report',
      permissions: ['create_attribution_reports'],
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PageAttributionCreate.vue'),
  },
  {
    path: '/attribution-reports/pdf',
    meta: {
      group: 'Attribution',
      title: 'Attribution PDF Page',
      permissions: ['create_attribution_reports'],
      noLayout: true,
      exclude: true,
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PageAttributionPdf.vue'),
  },
  {
    path: '/attribution-reports/scheduled',
    meta: {
      group: 'Attribution',
      title: 'Schedule Attribution Reports',
      permissions: ['create_attribution_reports'],
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PageAttributionScheduleLibrary.vue'),
  },
  {
    path: '/attribution-reports/scheduled/create',
    meta: {
      group: 'Attribution',
      title: 'Create Scheduled Attribution Report',
      exclude: true,
      permissions: ['create_attribution_reports'],
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PageRecurringAttributionCreate.vue'),
  },
  {
    path: '/attribution-reports/clone/:id',
    meta: {
      group: 'Attribution',
      title: 'Clone Attribution Report',
      permissions: ['create_attribution_reports'],
      exclude: true,
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PageAttributionCreate.vue'),
    beforeEnter: (to, from, next) => {
      if (isNaN(to.params.id)) {
        return next(false);
      }
      next();
    },
  },
  {
    path: '/attribution-reports/postCampaign',
    meta: {
      group: 'Attribution',
      title: 'Create Post Campaign Report',
      permissions: ['post_campaign'],
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PagePostCampaignCreate.vue'),
  },
  {
    path: '/attribution-reports/campaign-impact',
    meta: {
      group: 'Attribution',
      title: 'Create Campaign Impact Report',
      permissions: ['create_matchback_report'],
    },
    component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/PageMatchBackCreate.vue'),
  },
  {
    path: '/attribution/reports/:reportType',
    name: 'AttributionReport',
    meta: {
      group: 'Attribution',
      noLayout: true,
      exclude: true,
      public: true,

    },
    // component: () => import(/* webpackChunkName: "attribution" */ '../components/pages/attribution/AttributionReport.vue'),
    component: () => import(/* webpackChunkName: "attribution" */ '../components/attribution/reports/AttributionReportV2/AttributionReport.vue'),
  },
];
