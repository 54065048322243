import * as Sentry from '@sentry/browser';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import * as mutation from './mutation-types';
import {
  user as userApi,
  job as jobApi,
  system as systemApi,
} from '@/adonis-api';
import { getMqttClient } from '@/mqtt';
import { createDebugLogger } from '@/helpers/debug';

const debug = createDebugLogger();

const state = {
  orgDetails: {},
  userDetails: {},
  userLoaded: '',
};

const actions = {
  clearUserDetails({ commit }) {
    commit(mutation.CLEAR_USER_DETAILS);
  },

  async getUserDetails({ dispatch }, userId) {
    const userDetails = await userApi.findOne(userId);

    debug('Got user details:', userDetails);

    Sentry.configureScope(scope => {
      scope.setUser(
        _pick(userDetails, [
          'id',
          'email',
          'first_name',
          'last_name',
          'organization',
        ]),
      );
    });

    await dispatch('setUserDetails', userDetails);
  },

  async setUserDetails({ commit, dispatch }, user) {
    const client = await getMqttClient();

    client.subscribe(`job/+/${user.id}/+`); // orgId/userId/jobId
    client.subscribe(`activity/+/${user.id}`); // orgId/userId
    client.subscribe(`user-update/${user.id}`);
    client.on('message', (topic, message) => {
      debug(`Received MQTT msg: topic=${topic}; msg=${message}`);

      dispatch(
        'mqtt/parseRawMqtt',
        {
          topic,
          message,
        },
        { root: true },
      );
    });

    const { hash: intercomHash } = await systemApi.getIntercomHash();

    commit(mutation.SET_USER_DETAILS, {
      ...user,
      intercomHash,
    });
  },

  updateUserDetails({ commit, dispatch }, { payload }) {
    if (payload.userDetails.disabled) {
      dispatch('auth/logout', { invalidSession: true }, { root: true });
      return;
    }
    commit(mutation.UPDATE_USER_DETAILS, { payload });
  },

  async init({ state, dispatch }) {
    if (Object.keys(state.userDetails).length > 0) return;

    const jobs = await jobApi.find(null, { failed: true });
    const storedState = localStorage.getItem('onspot');

    if (jobs && Array.isArray(jobs)) {
      for (const job of jobs) {
        dispatch('mqtt/addRawJob', job, { root: true });
      }
    }

    if (storedState) {
      const { myUserId } = JSON.parse(storedState).auth;

      if (myUserId) {
        await dispatch('getUserDetails', myUserId);
      }
    }
  },
};

const mutations = {
  [mutation.CLEAR_USER_DETAILS](state) {
    state.userDetails = {};
    state.orgDetails = {};
  },
  [mutation.SET_USER_DETAILS](state, user) {
    state.userDetails = _omit(user, 'organization');
    state.orgDetails = user.organization;
  },
  [mutation.UPDATE_USER_DETAILS](state, { payload }) {
    state.orgDetails = payload.orgDetails;
    state.userDetails = Object.assign(state.userDetails, payload.userDetails);
  },
};

const getters = {
  apiKey(state) {
    return state.orgDetails && state.orgDetails.apiKey;
  },

  autoDedupe(state) {
    return _get(state.userDetails, 'preferences.options.auto_dedupe');
  },

  canViewActivity(state) {
    return _get(state.userDetails, 'view_activity');
  },

  paginationSize(state) {
    return _get(state.userDetails, 'preferences.options.pagination_size');
  },

  passResetRequest(state) {
    return state.passResetRequest;
  },

  fullName(state, _, rootState) {
    return rootState.auth.loggedIn && state.userDetails.first_name
      ? state.userDetails.first_name + ' ' + state.userDetails.last_name
      : '';
  },

  userInitials(state, _, rootState) {
    return rootState.auth.loggedIn && state.userDetails.first_name
      ? state.userDetails.first_name.charAt(0) +
          state.userDetails.last_name.charAt(0)
      : '';
  },

  hasPermission(state) {
    return perm => {
      if (typeof perm === 'string') {
        return state.userDetails.permissions
          ? state.userDetails.permissions.includes(perm)
          : false;
      }

      return state.userDetails.permissions.find(p => perm.test(p));
    };
  },

  hideChangelog(state) {
    return state.userDetails?.hide_changelog || state.orgDetails?.omit_changelog;
  },

  isInternal(state) {
    return state.userDetails.internal;
  },

  permissions(state) {
    return state.userDetails.permissions || [];
  },

  preferences(state) {
    return state.userDetails.preferences || {};
  },

  userCreatedAt(state) {
    return state.userDetails.createdAt;
  },

  email(state) {
    return state.loggedIn ? state.userDetails.emails[0].address : '';
  },

  facebookId(state) {
    return _get(state.userDetails, 'facebook_id');
  },

  tiktokConnected(state) {
    return !!_get(state.userDetails, 'tiktok_token');
  },

  orgInfo(state) {
    return state.orgDetails;
  },

  userId(state) {
    return state.userDetails.id;
  },

  slackIsDefined(state) {
    return !!state.orgDetails.slack_webhook;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
