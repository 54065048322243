import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import geoframeRoutes from './geoframe-routes';
import audienceRoutes from './audience-routes';
import attributionRoutes from './attribution-routes';
import orderRoutes from './order-routes';
import dataSourceRoutes from './datasource-routes';
import adminRoutes from './admin-routes';
import userRoutes from './user-routes';
import settingsRoutes from './settings-routes';
import { getUserPerms } from '../helpers';
import { userDefaultPath } from './router-helpers';

Vue.use(Router);

// TODO: Route permissions & beforeEach() guard to enforce them.
// TODO: Redirection to login for unauthenticated users
const router = new Router({
  mode: 'history',
  routes: [
    ...audienceRoutes,
    ...geoframeRoutes,
    ...orderRoutes,
    ...attributionRoutes,
    ...dataSourceRoutes,
    ...adminRoutes,
    ...userRoutes,
    {
      path: '/dashboard',
      meta: {
        title: 'Dashboard',
        exclude: true,
        internalOnly: true,
      },
      component: () => import(/* webpackChunkName: "dashboard" */ '../components/pages/admin/PageAdminDashboard.vue'),
    },
    {
      path: '/jobs-dashboard',
      meta: {
        title: 'Jobs Dashboard',
        exclude: true,
        internalOnly: true,
      },
      component: () => import(/* webpackChunkName: "dashboard" */ '../components/pages/admin/PageAdminJobDashboard.vue'),
    },
    {
      path: '/api/documentation',
      meta: {
        title: 'API Documentation',
        exclude: true,
        permissions: [
          'full_api_access',
        ],
        orInternal: true, // has perm, or is internal.
        partial: true, // Accessible as long as _some_ of the permissions match.
      },
      component: () =>
        import(/* webpackChunkName: "apiDocs" */ '../components/pages/api/PageApiDocumentation.vue'),
    },
    ...settingsRoutes,
    {
      path: '/auth',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ '../components/pages/misc/PageAuth.vue'),
    },
    {
      path: '/reset-password/*',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: true,
      },
      component: () =>
        import(/* webpackChunkName: "authResetPass" */ '../components/pages/misc/PageResetPassword.vue'),
    },
    {
      path: '/account-activation/*',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ '../components/pages/misc/PageAccountActivation.vue'),
    },
    {
      path: '/verify-email/*',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: false,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ '../components/pages/misc/PageEmailVerification.vue'),
    },
    {
      path: '/reset',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: false,
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ '../components/pages/misc/PageReset.vue'),
    },
    {
      path: '/changelog',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: false,
      },
      component: () =>
        import(/* webpackChunkName: "misc" */ '../components/pages/changelog/PageChangelog.vue'),
    },
    {
      path: '/audience-reports/:hashId',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: false,
        noLayout: true,
      },
      component: () =>
        import(/* webpackChunkName: "reports" */ '../components/pages/misc/PageAudienceReports.vue'),
    },
    {
      path: '/audience/journey/:uuid',
      name: 'AudienceJourneyReport',
      meta: {
        noLayout: true,
        exclude: true,
        public: true,
      },
      component: () => import(/* webpackChunkName: "reports" */ '../components/pages/misc/PageJourneyViewer.vue'),
    },
    {
      path: '/audience/reports/:reportType',
      name: 'AudienceReport',
      meta: {
        noLayout: true,
        exclude: true,
        public: true,
      },
      component: () => import(/* webpackChunkName: "reports" */ '../components/pages/audiences/InsightReport.vue'),
    },
    {
      path: '/campaign-proposal/:uuid',
      meta: {
        public: true,
        exclude: true,
        preventWhileLoggedIn: false,
        noLayout: true,
      },
      component: () =>
        import(/* webpackChunkName: "reports" */ '../components/pages/misc/PageCampaignProposalReports.vue'),
    },
    {
      path: '/report-viewer/:hashId',
      meta: {
        title: 'Report Viewer',
        exclude: true,
        public: true,
        noLayout: true,
      },
      component: () =>
        import(/* webpackChunkName: "reports" */ '../components/pages/misc/PageExternalViewer.vue'),
    },
    {
      path: '/jobs/:id',
      meta: {
        title: 'Job Detail',
        internalOnly: true,
        exclude: true,
        noLayout: true,
      },
      component: () =>
        import(/* webpackChunkName: "jobs" */ '../components/pages/misc/PageJobDetail.vue'),
    },
    {
      // This route must always be last!
      path: '*',
      meta: {
        public: true,
        exclude: true,
      },
      component: () =>
        import(/* webpackChunkName: "misc" */ '../components/pages/misc/PageNotFound.vue'),
    },
  ],
});

router.beforeEach(async function(to, from, next) {
  if (to.meta && to.meta.href) {
    window.open(to.meta.href, '_blank');
    return;
  }

  await store.dispatch('user/init');

  if (
    !store.state.auth.loggedIn &&
    ((to.path !== '/auth' && !to.meta.public) || to.path === '/')
  ) {
    return next(`/auth?redir=${to.fullPath}`);
  }

  // redirect to first accessible route
  if (
    store.state.auth.loggedIn &&
    (to.path === '/' || to.meta.preventWhileLoggedIn)
  ) {
    // next(userDefaultPath(router, true));
    next(userDefaultPath(router));
  }

  // Check to see if user has proper permissions to navigate here
  const hasAccess =
    !to.meta.permissions ||
    (to.meta.internalOnly && store.state.user.userDetails.internal) ||
    (to.meta.orInternal && store.state.user.userDetails.internal) ||
    (to.meta.partial &&
      to.meta.permissions.some(p => getUserPerms().includes(p))) ||
    to.meta.permissions.every(p => getUserPerms().includes(p));

  if (hasAccess) {
    // store.state.auth.userDetails.roles
    // Update page title on route change.
    if (!to.path.match(/\/user\//)) {
      // Don't update for /user/* routes.
      document.title =
        to.meta.title ||
        (/onspotdata\.com/.test(window.location.hostname)
          ? 'OnSpot'
          : 'Geo Targeting');
    }
    next();
  } else {
    Vue.prototype.$notify.warning({
      title: 'Not Authorized',
      message: 'You are not authorized to access this page.',
    });
  }
});

export default router;
