import axios from 'axios';
import { Exception, exceptions } from '@/exceptions';
import { login, logout } from '@/adonis-api/auth';
import * as mutation from './mutation-types';
import router from '@/router';
import { userDefaultPath } from '@/router/router-helpers';

const authStore = {
  namespaced: true,
  state: {
    actualUserId: null,
    authBusy: false,
    loggedIn: false,
    myUserId: null,
    passResetRequest: false,
    refreshToken: null,
    token: null,
  },

  actions: {
    async forceLogin({ commit, dispatch }, { credentials, user }) {
      commit(mutation.LOGIN, { credentials, user });
      return dispatch('user/setUserDetails', user, { root: true });
    },

    async login({ commit, dispatch }, { email, password }) {
      commit(mutation.AUTH_BUSY);

      try {
        const loginResults = await login({ email, password });

        commit(mutation.LOGIN, loginResults);
        dispatch('user/setUserDetails', loginResults.user, { root: true });
      } catch (e) {
        commit(mutation.AUTH_BUSY, false);
        let exception;

        switch (e.response.status) {
          case 400:
            exception = new Exception(exceptions.auth.InvalidLogin);
            break;
          case 403:
            exception = new Exception(exceptions.auth.LoginDisabled);
            break;
          default:
            exception = new Exception(exceptions.UnknownException, e);
            break;
        }

        throw exception;
      }
    },

    passResetRequest({ commit }, value) {
      commit('passResetRequest', value);
    },

    async logout({ commit, dispatch }, { invalidSession } = {}) {
      commit(mutation.AUTH_BUSY);

      try {
        if (!invalidSession) await logout();
      } catch (e) {
      } finally {
        dispatch('user/clearUserDetails', null, { root: true });
        commit(mutation.LOGOUT);
      }
    },

    async refreshToken({ commit, state }) {
      const response = await axios.post(
        `${process.env.VUE_APP_ADONIS_ENDPOINT}/auth/refresh-token`,
        {
          refresh_token: state.refreshToken,
        },
      );

      commit(mutation.SET_TOKENS, {
        token: response.data.token,
        refreshToken: response.data.refreshToken,
      });
    },

    updateTokens({ commit }, { token, refreshToken }) {
      commit(mutation.SET_TOKENS, { token, refreshToken });
    },

    impersonateUser({ commit, dispatch }, userId) {
      commit(mutation.IMPERSONATE_USER, userId);
      dispatch('user/getUserDetails', userId, { root: true });
      router.push(userDefaultPath(router));
    },

    stopImpersonation({ commit, dispatch, state }) {
      commit(mutation.STOP_IMPERSONATION);
      dispatch('user/getUserDetails', state.actualUserId, { root: true });
      router.push(userDefaultPath(router));
    },
  },

  mutations: {
    [mutation.AUTH_BUSY](state, busy) {
      if (typeof busy === 'undefined') {
        state.authBusy = true;
      } else {
        state.authBusy = busy;
      }
    },

    [mutation.IMPERSONATE_USER](state, userId) {
      state.myUserId = userId;
    },

    [mutation.STOP_IMPERSONATION](state) {
      state.myUserId = state.actualUserId;
    },

    [mutation.LOGIN](state, { credentials, user }) {
      state.token = credentials.token;
      state.refreshToken = credentials.refreshToken;
      state.authBusy = false;
      state.loggedIn = true;
      state.myUserId = state.actualUserId = user.id;
    },

    [mutation.LOGOUT](state) {
      state.token = state.refreshToken = null;
      state.loggedIn = false;
      state.authBusy = false;
      state.myUserId = null;
    },

    [mutation.SET_TOKENS](state, { token, refreshToken }) {
      state.token = token;
      state.refreshToken = refreshToken;
    },

    // @FIXME: the below should be mutation constants like above
    passResetRequest(state, value) {
      state.passResetRequest = value;
    },

    setImpersonating(state, value) {
      state.impersonating = value;
    },

    storePromise(state, promise) {
      state.promise = promise;
    },
  },

  getters: {
    impersonating(state) {
      return state.loggedIn && state.myUserId !== state.actualUserId;
    },

    loggedIn(state) {
      return state.loggedIn;
    },

    passResetRequest: () => false,
  },
};

export default authStore;
