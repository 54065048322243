export default [
  {
    path: '/settings/prefs',
    meta: {
      group: 'Settings',
      title: 'Preferences',
      permissions: ['create_geoframes'],
    },
    component: () => import(/* webpackChunkName: "settings" */ '../components/pages/settings/PageSettingsPrefs.vue'),
  },
  {
    path: '/settings/app',
    meta: {
      group: 'Settings',
      title: 'Application Settings',
      permissions: ['administer_app_settings'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../components/pages/admin/PageAdminSettings.vue'),
  },
];
