import Vue from 'vue';
import ElementUI from 'element-ui';
import L from 'leaflet';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
// import 'regenerator-runtime/runtime';
import VueIntercom from 'vue-intercom';
import VueVisible from 'vue-visible';
import VueClipboard from 'vue-clipboard2';
import VueTreeView from 'vue-json-tree-view';
import './bootstrap/polyfills';
import './bootstrap/error-reporting';
import registerComponents from './bootstrap/global-components';
import { initMqtt } from './mqtt';

// Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet.pm/dist/leaflet.pm.css';

// Mapbox
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

// FIXME: address Element UI theme stuff
import 'element-ui/lib/theme-chalk/index.css';
// import '../imports/fa-icons.scss';
import store from './store';
import router from './router';
import filters from './filters';
import directives from './directives';
import App from './App.vue';
import GlobalEventBus from '@/plugins/global-event-bus';

import 'vue-wysiwyg/dist/vueWysiwyg.css';
import VueWYSIWYG from 'vue-wysiwyg';
import VueScrollTo from 'vue-scrollto';

import VueApexCharts from 'vue-apexcharts';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

initMqtt();

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(VueVisible);
Vue.use(VueWYSIWYG, {});
Vue.use(VueScrollTo, {
  container: '.os-content-wrapper',
  duration: 500,
  easing: 'ease',
  offset: 0,
  cancelable: true,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.use(VueClipboard);
Vue.use(VueTreeView);

// Global event bus
// Usage:
//   this.eventBus.$emit('some-event', payload)
//   this.eventBus.$on('some-event', handlerFunc)
Vue.use(GlobalEventBus);

// Element UI
Vue.use(ElementUI);

// Element UI language
locale.use(lang);

Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID });

// Ignore custom redoc element provided by 3rd party library.
Vue.config.ignoredElements = ['redoc'];

// Set up filters
for (const name in filters) {
  Vue.filter(name, filters[name]);
}

// Set up directives
for (const name in directives) {
  Vue.directive(name, directives[name]);
}

registerComponents();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
