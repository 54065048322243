<template>
  <help-tip :style="margin">
    {{ typeHelpText }}
  </help-tip>
</template>

<script>
import HelpTip from '../global/HelpTip.vue';

export default {
  components: { HelpTip },
  props: {
    leftPadding: { type: Boolean, default: false },
    type: { type: String, required: true },
  },

  computed: {
    margin() {
      return {
        marginLeft: this.leftPadding ? '0.5em' : '0',
      };
    },

    typeHelpText() {
      switch (this.type) {
        case 'RENEW':
          return 'Keeps the publication from expiring';
        case 'REFRESH':
          return 'Publishes updated data to same publication';
        case 'REPUBLISH':
          return 'Publishes updated data to new publication';
        default:
          return 'Unknown type';
      }
    },
  },
};
</script>

<style>

</style>
