<template>
  <div
    class="version-root"
    @dblclick="handleDblClick"
  >
    v{{ version }}
  </div>
</template>

<script>
const { version } = require('../../../package.json');

export default {
  data() {
    return { version };
  },

  methods: {
    handleDblClick(event) {
      if (event.shiftKey) {
        this.noSuchProp.fail();
      }
    },
  },
};
</script>

<style scoped>
.version-root {
  user-select: none;
}
</style>
