<template>
  <el-dialog
    title="Audience Reports"
    :visible="showDialog === 'audienceReports'"
    :before-close="handleClose"
    center
  >
    <div class="instructions">
      <span
        v-if="!started"
      >No reports have been generated for this audience yet.</span>
      <span
        v-else-if="started && !completeHash"
      >Please wait while your report(s) are being prepared.</span>
      <span v-else>
        Select the reports you wish to include (at least one), then copy the
        link to share with others.
      </span>
    </div>
    <audience-config
      v-if="shouldShowAudienceConfig"
      :audience-data="audience"
      @configsSaved="handleConfigsSaved"
    />

    <div>
      <el-button
        v-if="!isReady('insight_report')"
        type="primary"
        :loading="generatingReport"
        style="margin-left: 10px"
        :disabled="deviceLimitExceeded || disableReportButtons"
        @click="handlePrepareReports"
      >
        {{ reportButtonLabel }}
        {{ insightProgress }}
      </el-button>
      <el-button
        v-if="
          !isReady('heatmap_report') && canCreateHeatmap && !enabledAudience()
        "
        type="primary"
        :loading="generatingHeatmap"
        style="margin-top: 10px"
        :disabled="deviceLimitExceeded || disableReportButtons"
        @click="handlePrepareHeatmap"
      >
        Prepare Heatmap
        {{ heatmapProgress }}
      </el-button>
      <el-button
        v-if="!isReady('political_report') && canCreatePoliticalReports"
        type="primary"
        :loading="generatingPoliticalReport"
        style="margin-top: 10px"
        :disabled="deviceLimitExceeded || disableReportButtons"
        @click="handlePreparePoliticalReport"
      >
        Prepare Political Report
        {{ politicalProgress }}
      </el-button>
    </div>
    <div
      v-if="disableReportButtons"
      class="exceed-notice"
    >
      <strong>Note:</strong>
      You are required to save your settings before proceeding..
    </div>
    <br>
    <div
      v-if="deviceLimitExceeded"
      class="exceed-notice"
    >
      <strong>Note:</strong>
      Audience count cannot exceed {{ formattedDeviceLimit }}.
    </div>
    <br>

    <el-checkbox-group
      v-if="
        isReady('insight_report') ||
          isReady('heatmap_report') ||
          isReady('political_report')
      "
      v-model="reportSelections"
      style="display: inline"
    >
      <el-checkbox
        v-for="report in reportTypes"
        :key="report.enum"
        :label="report.enum"
      >
        {{ report.label }}
      </el-checkbox>
    </el-checkbox-group>
    <div
      v-if="completeHash"
      class="share-link"
    >
      <div class="link-box">
        <el-input
          readonly
          size="small"
          :value="shareUrl"
        />
      </div>
      <el-button
        v-clipboard:copy="shareUrl"
        v-clipboard:success="afterCopy"
        :disabled="reportSelections.length === 0"
        icon="fa fa-lg fa-clipboard-list"
        plain
        size="small"
        type="primary"
      />
    </div>
    <div
      v-if="completeHash && reportSelections.length > 0"
      class="view-report-button"
    >
      <el-button
        plain
        type="primary"
        @click="handleViewReports"
      >
        Standard Reports
      </el-button>
      <el-button
        v-if="canCreateAndViewEnhancedInsight"
        plain
        type="primary"
        :disabled="showForm"
        @click="handleViewEnhancedReports"
      >
        Enhanced Reports
      </el-button>
      <el-button
        v-if="canCreateAndViewEnhancedInsight"
        plain
        type="primary"
        @click="handleDownloadPDF"
      >
        Download PDF
      </el-button>
    </div>
    <span slot="footer">
      <el-button @click="handleClose">Done</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Hashids from 'hashids';
import _get from 'lodash/get';
import { audienceReportTypes, audienceDeviceCountLimit } from '@/constants';
import { numericSort } from '@/helpers/sorters';
import { mapGetters } from 'vuex';
import { audience as audienceApi, reporting as reportingApi } from '@/adonis-api';
import jobWatcher from '@/mixins/job-watcher';
import { isDeviceAudience } from '@/helpers';
import AudienceConfig from './AudienceConfigReport.vue';
import axios from 'axios';

const hashids = new Hashids(
  process.env.VUE_APP_HASHIDS_SALT,
  Number(process.env.VUE_APP_HASHIDS_PADDING),
);

export default {
  components: {
    AudienceConfig,
  },
  mixins: [jobWatcher],
  props: {
    audience: { type: Object, required: true },
    showDialog: { type: String, required: true },
  },
  data() {
    return {
      audienceAppendData: {},
      initiatedHeatmapGen: false,
      initiatedReportGen: false,
      reportSelections: [],
      initiatedPoliticalReport: false,
      showForm: false,
    };
  },

  computed: {

    disableReportButtons() {
      if (!this.canCreateAndViewEnhancedInsight) {
        return false;
      }
      if (this.showForm) {
        return true;
      }
      return false;
    },
    shouldShowAudienceConfig() {
      if (!this.canCreateAndViewEnhancedInsight) {
        return false;
      }

      return this.showForm;
    },
    ...mapGetters('user', ['hasPermission']),

    deviceLimitExceeded() {
      return this.currentAudience.count > audienceDeviceCountLimit;
    },

    formattedDeviceLimit() {
      return audienceDeviceCountLimit.toLocaleString();
    },
    canCreateAndViewEnhancedInsight() {
      return this.hasPermission('create_and_view_enhanced_insight');
    },
    canCreateHeatmap() {
      return (
        this.hasPermission('heatmap_report') &&
        _get(this.currentAudience, 'organization.heatmap_types', []).length > 0
      );
    },

    canCreatePoliticalReports() {
      return this.hasPermission('political_report');
    },

    completeHash() {
      if (
        !_get(this.currentAudience, 'insight_report.id') &&
        !_get(this.currentAudience, 'heatmap_report.id') &&
        !_get(this.currentAudience, 'political_report.id')
      ) {
        return '';
      }
      return hashids.encode(
        this.currentAudience.id,
        ...this.sortedReportSelections,
      );
    },

    currentAudience() {
      return {
        ...this.audience,
        ...this.audienceAppendData,
      };
    },

    generatingPoliticalReport() {
      return (
        (this.currentAudience.political_job_id &&
          typeof this.currentAudience.political_job_id === 'number') ||
        this.initiatedPoliticalReport
      );
    },

    generatingHeatmap() {
      return (
        (this.currentAudience.heatmap_job_id &&
          typeof this.currentAudience.heatmap_job_id === 'number') ||
        this.initiatedHeatmapGen
      );
    },

    generatingReport() {
      return (
        (this.currentAudience.insight_job_id &&
          typeof this.currentAudience.insight_job_id === 'number') ||
        this.initiatedReportGen
      );
    },

    heatmapProgress() {
      const progress = parseFloat(
        _get(
          ...this.watchedJobs.filter((j) => j.payload.type === 'HEATMAP'),
          'payload.eta.progress',
        ),
      );
      return !Number.isNaN ? `${Number((progress * 100).toFixed(2))}%` : null;
    },

    insightProgress() {
      const progress = parseFloat(
        _get(
          ...this.watchedJobs.filter((j) => j.payload.type === 'ANALYTICS'),
          'payload.eta.progress',
        ),
      );
      return !Number.isNaN ? `${Number((progress * 100).toFixed(2))}%` : null;
    },

    politicalProgress() {
      const progress = parseFloat(
        _get(
          ...this.watchedJobs.filter((j) => j.payload.type === 'POLITICAL '),
          'payload.eta.progress',
        ),
      );
      return !Number.isNaN ? `${Number((progress * 100).toFixed(2))}%` : null;
    },

    reportButtonLabel() {
      const reports = [];
      if (this.hasPermission('demographic_report')) {
        reports.push('Demographic');
      }
      if (this.hasPermission('insight_report') && !this.enabledAudience()) {
        reports.push('Insights');
      }

      return `Prepare ${reports.join(' & ')} Report${
        reports.length > 1 ? 's' : ''
      }`;
    },

    reportTypes() {
      return audienceReportTypes.filter((type) => {
        if (type.type === 'heatmap') {
          return (
            _get(this.currentAudience, 'heatmap_report.id') &&
            this.hasPermission(type.permission)
          );
        }
        if (type.type === 'political') {
          return (
            _get(this.currentAudience, 'political_report.id') &&
            this.hasPermission(type.permission)
          );
        }
        if (type.type === 'insights') {
          return (
            _get(this.currentAudience, 'insight_report.id') &&
            this.hasPermission(type.permission) &&
            !this.enabledAudience()
          );
        }
        return (
          _get(this.currentAudience, 'insight_report.id') &&
          this.hasPermission(type.permission)
        );
      });
    },

    shareUrl() {
      if (this.reportSelections.length === 0) return '';

      const baseUrl = `${window.location.protocol}//${window.location.host}`;

      return `${baseUrl}/audience-reports/${this.completeHash}`;
    },

    sortedReportSelections() {
      return [...this.reportSelections].sort(numericSort);
    },

    started() {
      return (
        this.generatingHeatmap ||
        this.generatingReport ||
        this.generatingPoliticalReport ||
        !!_get(this.currentAudience, 'heatmap_report.id') ||
        !!_get(this.currentAudience, 'insight_report.id') ||
        !!_get(this.currentAudience, 'political_report.id')
      );
    },
  },

  watch: {
    audience(audience) {
      this.checkExistingConfigs();

      if (this.showDialog !== 'audienceReports' || !audience.id) {
        return;
      }
      if (audience.heatmap_job_id) {
        this.watchJob(audience.heatmap_job_id);
      }
      if (audience.insight_job_id) {
        this.watchJob(audience.insight_job_id);
      }
    },

    watchedJobs: {
      handler(jobs) {
        jobs.map((job) => {
          if (['ANALYTICS', 'HEATMAP'].includes(job.payload.type)) {
            if (job.payload.status === 'COMPLETE') {
              if (job.payload.result) {
                this.audienceAppendData = {
                  ...this.audienceAppendData,
                  ...job.payload.result,
                };
                if (job.payload.type === 'ANALYTICS') {
                  this.audienceAppendData.insight_job_id = null;
                } else {
                  this.audienceAppendData.heatmap_job_id = null;
                }
              }
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    afterCopy() {
      this.$message({
        message: 'URL copied to clipboard.',
        type: 'success',
      });
    },

    handleClose() {
      Object.assign(this, {
        audienceAppendData: {},
        initiatedHeatmapGen: false,
        initiatedReportGen: false,
        reportSelections: [],
        initiatedPoliticalReport: false,
      });
      this.clearWatchedJobs();
      this.$emit('close');
    },
    async handlePreparePoliticalReport() {
      this.eventBus.$emit('saveInsightConfigs');
      const response = await audienceApi.preparePoliticalReport(
        this.currentAudience.id,
      );

      if (response.status === 'success') {
        this.initiatedPoliticalReport = true;
        this.watchJob(response.data.job.id);
        this.$store.dispatch('mqtt/addRawJob', response.data.job);

        window.mixpanel.track('Prepare Audience Political Report', {
          ID: this.currentAudience.id,
          Name: this.currentAudience.name,
          'Job ID': response.data.job.id,
        });
      }
    },
    async handlePrepareHeatmap() {
      this.eventBus.$emit('saveInsightConfigs');
      const response = await audienceApi.prepareHeatmaps(
        this.currentAudience.id,
      );

      if (response.status === 'success') {
        this.initiatedHeatmapGen = true;
        this.watchJob(response.data.job.id);
        this.$store.dispatch('mqtt/addRawJob', response.data.job);

        window.mixpanel.track('Prepare Audience Heatmaps', {
          ID: this.currentAudience.id,
          Name: this.currentAudience.name,
          'Job ID': response.data.job.id,
        });
      }
    },

    async handleDownloadPDF() {
      try {
        const url = process.env.VUE_APP_ADONIS_ENDPOINT === 'https://app-backend-dev.onspotdata.com' ? `https://osd-v2-backend-development.s3.amazonaws.com/public/insightPdf/merged_${this.currentAudience.uuid}.pdf` : `https://osd-v2-backend-production.s3.amazonaws.com/public/insightPdf/merged_${this.currentAudience.uuid}.pdf`;

        const response = await axios.get(url, {
          responseType: 'blob', // Important for receiving binary data
        });

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.currentAudience.name}.pdf`;
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      } catch (e) {
        this.$notify.error({
          message:
                'There was a problem downloading your PDF.',
        });
      }
    },

    async handlePrepareReports() {
      this.eventBus.$emit('saveInsightConfigs');
      const response = await audienceApi.prepareInsightReport(
        this.currentAudience.id,
      );

      if (response.status === 'success') {
        this.initiatedReportGen = true;
        this.watchJob(response.data.job.id);
        this.$store.dispatch('mqtt/addRawJob', response.data.job);

        window.mixpanel.track('Prepare Audience Report', {
          ID: this.currentAudience.id,
          Name: this.currentAudience.name,
          'Job ID': response.data.job.id,
        });
      }
    },

    async checkExistingConfigs() {
      if (!this.currentAudience.id) {
        return false;
      }

      if (!this.canCreateAndViewEnhancedInsight) {
        this.showForm = false;
        return;
      }

      try {
        const response = await reportingApi.getConfigs(this.currentAudience.id);
        if (response.configs) {
          this.showForm = false;
        } else {
          this.showForm = true;
        }
      } catch (error) {
        this.showForm = true;
      }
    },
    handleViewReports() {
      window.open(this.shareUrl, '_blank');
    },
    handleViewEnhancedReports() {
      const reportSelectionsString = JSON.stringify(this.reportSelections);

      const reportRoute = this.$router.resolve({
        path: '/audience/reports/title_page',
        query: {
          reportId: this.audience.uuid,
          orientation: 'landscape',
          reportSelections: reportSelectionsString,
        },
      });

      window.open(reportRoute.href, '_blank');
    },

    enabledAudience() {
      return isDeviceAudience(this.currentAudience.type);
    },

    isReady(report) {
      return !!_get(this.currentAudience, report + '.id');
    },
    handleConfigsSaved() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.instructions {
  margin-bottom: 1em;
}

.share-link {
  align-items: center;
  display: flex;
  margin-top: 1em;

  .link-box {
    flex: 1;
    margin-right: 0.4em;

    :deep(input) {
      font-family: Courier, monospace;
    }
  }
}

.prepare-button {
  margin-top: 1em;
  text-align: center;
}

.gear-example {
  margin-top: 2em;
  text-align: center;

  > img {
    height: auto;
    max-width: 70%;
  }
}

.view-report-button {
  margin-top: 1em;
  text-align: center;
}

.flex-container {
  flex-wrap: wrap;
}

.exceed-notice {
  background: #ffffcc;
  padding: 1em;
  margin: 1em 0 0 1em;
  width: 552px;
  line-height: 1.2em;
}
</style>
