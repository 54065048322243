<template>
  <div
    v-loading="loading"
  >
    <el-dialog
      title="Campaign Proposals"
      :visible="showDialog === 'proposal'"
      :before-close="closeDialog"
    >
      <el-tabs
        v-model="activeTab"
        type="border-card"
        @tab-click="handleTabSelect"
      >
        <el-tab-pane
          label="Generate"
          name="generate"
        >
          <el-form>
            <el-form-item label="Proposal Name">
              <el-input v-model="form.name" />
            </el-form-item>
            Upload a logo (optional):<br><br>
            <uploader
              ref="proposalUploader"
              :accept-file-exts="fileExts"
              api-endpoint="/api/v1/audiences/proposals/proposal-logo"
              single-file
              upload-field-name="proposal-logo"
              @complete="uploadComplete"
              @file-change="handleFileChange"
              @upload-state-change="handleStateChange"
            />
            <el-form-item label="Campaign Length (# of days):">
              <el-input v-model="form.campaign_length" />
            </el-form-item>
            <el-form-item label="Budget ($ amount):">
              <el-input v-model="form.budget" />
            </el-form-item>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="Contact Name:">
                  <el-input v-model="form.contact_name" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Phone:">
                  <el-input v-model="form.contact_phone" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Email:">
                  <el-input v-model="form.contact_email" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              v-show="audienceScopeOptions.length > 1"
              label="Audience Scope:"
            >
              <div>
                <el-checkbox-group
                  v-model="audienceScope"
                  size="small"
                >
                  <el-checkbox-button
                    v-for="scope in audienceScopeOptions"
                    :key="scope"
                    :label="scope"
                  >
                    {{ scope }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </div>
            </el-form-item>
            <el-form-item label="Media Type:">
              <el-row :gutter="20">
                <el-col :span="4">
                  <el-select
                    v-model="mediaType"
                    placeholder="Media Type"
                  >
                    <el-option
                      v-for="mediaType1 in mediaTypeList"
                      :key="mediaType1"
                      :value="mediaType1"
                    />
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-input
                    v-model="cpm"
                    placeholder="CPM"
                  />
                </el-col>
                <el-col :span="4">
                  <el-input
                    v-model="campaignPercent"
                    placeholder="Percent"
                  />
                </el-col>
                <el-col :span="4">
                  <el-button
                    type="primary"
                    @click="addMediaType"
                  >
                    Add
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              v-if="isInternal || hasPermission('proposals_optional_section')"
              label="Optional Sections:"
            >
              <!-- <el-checkbox
                v-model="form.creative_explaination"
                label="Creative Explanation"
              /> -->
              <el-checkbox
                v-model="form.io"
                label="IO"
              />
            </el-form-item>
            <el-form-item v-if="form.creative_explaination">
              <el-input
                v-model="form.creative_text"
                type="textarea"
                :rows="2"
                placeholder="Please input a creative explaination."
              />
            </el-form-item>
          </el-form>
          <div v-if="form.media_types">
            <el-tag
              v-for="(obj, mediaType2) in form.media_types"
              :key="mediaType2"
              closable
              class="button-margin"
              @close="handleTagClose(mediaType2)"
            >
              {{ mediaType2 }} - {{ obj.cpm }} CPM - {{ obj.campaignPercent }}%
            </el-tag>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <el-button @click="closeDialog">
              Cancel
            </el-button>
            <el-button
              type="primary"
              :loading="proposalGenerating"
              @click="generateProposal"
            >
              Generate
            </el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="Campaign Proposals"
          name="proposals"
        >
          <el-table
            ref="filterTable"
            :data="proposalTableData"
            style="width: 100%"
          >
            <el-table-column
              prop="created_at"
              label="Date"
              width="180"
              column-key="created_at"
            />
            <el-table-column
              prop="name"
              label="Name"
              width="180"
            />
            <el-table-column
              prop="budget"
              label="Budget"
            />
            <el-table-column
              prop="campaign_length"
              label="Campaign Length"
            />
            <el-table-column width="200">
              <template slot-scope="props">
                <el-row>
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="!isReady('heatmap_report') || !isReady('insight_report')"
                    @click="handleViewProposal(props.row.uuid_endpoint, false)"
                  >
                    View
                  </el-button>
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-download"
                    :disabled="!isReady('heatmap_report') || !isReady('insight_report')"
                    @click="handleViewProposal(props.row.uuid_endpoint, true)"
                  />
                  <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    :disabled="!isReady('heatmap_report') || !isReady('insight_report')"
                    @click="handleDeleteProposal(props.row.uuid_endpoint)"
                  />
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog
      title="Confirm Delete"
      :visible.sync="deleteProposal"
    >
      <span>Are you sure you want to delete this proposal?</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelDelete()">Cancel</el-button>
        <el-button
          type="primary"
          @click="confirmDelete()"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Uploader from '../global/Uploader.vue';
import { isDeviceAudience } from '@/helpers';
import { audience as audienceApi } from '@/adonis-api';
import jobWatcher from '@/mixins/job-watcher';
import _get from 'lodash/get';

export default {
  components: { Uploader },
  mixins: [jobWatcher],
  props: {
    audience: {
      type: Object,
      required: true,
    },
    showDialog: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fileList: [],
      error: false,
      cpm: null,
      campaignPercent: null,
      initiatedHeatmapGen: false,
      proposalGenerating: false,
      initiatedReportGen: false,
      deleteProposal: false,
      deleteProposalUUID: null,
      activeTab: 'generate',
      audienceAppendData: {},
      proposalTableData: null,
      fileExts: ['.png', '.svg', '.jpg'],
      audienceScope: ['Regular'],
      mediaTypeList: [
        'Display/Banner',
        'Interstitial',
        'Video',
        'CTV',
        'Audio',
        'Native',
      ],
      mediaType: [],
      logo: null,
      form: {
        name: '',
        campaign_length: 0,
        budget: 0,
        media_types: {},
        creative_explaination: false,
        io: false,
        logo_url: null,
        creative_text: null,
        contact_name: null,
        contact_email: null,
        contact_phone: null,
      },
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeStart']),
    ...mapGetters('user', ['permissions', 'hasPermission', 'isInternal']),
    polygonAudience() {
      return !isDeviceAudience(this.currentAudience.type);
    },
    dialogTitle() {
      return `${this.currentAudience.name} Campaign Proposal`;
    },

    generatingProposal() {
      return this.generatingHeatmap || this.generatingReport;
    },
    currentAudience() {
      return {
        ...this.audience,
        ...this.currentAudienceAppendData,
      };
    },
    generatingHeatmap() {
      return (
        (this.currentAudience.heatmap_job_id &&
          typeof this.currentAudience.heatmap_job_id === 'number') ||
        this.initiatedHeatmapGen
      );
    },

    generatingReport() {
      return (
        (this.currentAudience.insight_job_id &&
          typeof this.currentAudience.insight_job_id === 'number') ||
        this.initiatedReportGen
      );
    },

    audienceScopeOptions() {
      const options = ['Regular'];

      if (
        this.hasPermission('extend_household') &&
        (this.polygonAudience || !this.neustar)
      ) {
        options.push('Household Extension');
      }

      if (!this.neustar) {
        if (this.hasPermission('extend_lookalike')) {
          options.push('Lookalike Extension');
        }

        if (
          this.hasPermission('extend_social') &&
          (this.polygonAudience ||
            parseInt(this.currentAudience.__meta__.files_count) < 2)
        ) {
          options.push('Social Extension');
        }

        // if (this.hasPermission('extend_legacy') && this.polygonAudience) {
        //   options.push('Legacy HH Extension');
        // }
      }

      return options;
    },
  },

  watch: {
    showDialog(value) {
      if (!value) return;

      this.form.name = this.currentAudience.name;
    },
    audience(audience) {
      if (this.showDialog !== 'audienceReports' || !audience.id) {
        return;
      }
      if (audience.heatmap_job_id) {
        this.watchJob(audience.heatmap_job_id);
      }
      if (audience.insight_job_id) {
        this.watchJob(audience.insight_job_id);
      }
    },

    watchedJobs: {
      handler(jobs) {
        jobs.map(job => {
          if (['ANALYTICS', 'HEATMAP'].includes(job.payload.type)) {
            if (job.payload.status === 'COMPLETE') {
              if (job.payload.result) {
                this.currentAudienceAppendData = {
                  ...this.currentAudienceAppendData,
                  ...job.payload.result,
                };
                if (job.payload.type === 'ANALYTICS') {
                  this.currentAudienceAppendData.insight_job_id = null;
                } else {
                  this.currentAudienceAppendData.heatmap_job_id = null;
                }
              }
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    verify() {
      if (Object.entries(this.form.media_types).length === 0) {
        return false;
      }
      let sum = 0;
      for (const idx in this.form.media_types) {
        sum += +this.form.media_types[idx].campaignPercent;
      }
      return sum === 100;
    },

    clearForm() {
      this.mediaType = [];
      this.campaignPercent = null;
      this.cpm = null;
      this.proposalTableData = null;
      this.form = {
        name: '',
        campaign_length: 0,
        budget: 0,
        media_types: {},
        creative_explaination: false,
        io: false,
        logo_url: null,
        creative_text: null,
      };
    },

    handleTagClose(mediaType) {
      const tmp = this.form.media_types;
      this.form.media_types = null;
      delete tmp[mediaType];
      this.form.media_types = tmp;
    },

    cancelDelete() {
      this.deleteProposalUUID = null;
      this.deleteProposal = false;
    },

    async confirmDelete() {
      await audienceApi.removeProposal({ uuid_endpoint: this.deleteProposalUUID, organization_id: this.currentAudience.organization.id });
      this.deleteProposalUUID = null;
      this.deleteProposal = false;
      const response = await audienceApi.getProposals({
        audience_id: this.currentAudience.id,
        organization_id: this.currentAudience.organization.id,
      });

      if (response.status === 'success') {
        this.proposalTableData = response.data;
      }
    },

    async handleDeleteProposal(uuid) {
      this.deleteProposalUUID = uuid;
      this.deleteProposal = true;
    },
    handleViewProposal(uuid, download) {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;

      window.open(`${baseUrl}/campaign-proposal/${uuid}?download=${download}`, '_blank');
    },

    async handleTabSelect(tab, event) {
      if (tab.label === 'Campaign Proposals') {
        const response = await audienceApi.getProposals({
          audience_id: this.currentAudience.id,
          organization_id: this.currentAudience.organization.id,
        });

        if (response.status === 'success') {
          this.proposalTableData = response.data;
        }
      }
    },

    isReady(report) {
      return !!_get(this.currentAudience, report + '.id');
    },

    handleFileChange(fileList) {
      this.logo = fileList[0];
    },

    addMediaType() {
      const tmp = this.form.media_types;
      tmp[this.mediaType] = {
        cpm: this.cpm,
        campaignPercent: this.campaignPercent,
      };
      this.form.media_types = null;
      this.form.media_types = tmp;
    },

    closeDialog() {
      this.clearForm();
      this.activeTab = 'generate';
      this.$emit('close');
    },
    handleStateChange(isUploading) {
      this.loading = isUploading;
    },
    uploadComplete(result) {
      this.form.logo_url = result.data.logo_url;
    },
    async generateProposal() {
      if (!this.verify()) {
        return this.$alert('Percentages of media types must add up to 100%.');
      }
      this.proposalGenerating = true;
      const data = this.form;
      data.audience_id = this.currentAudience.id;
      data.audience_scope = this.audienceScope;
      data.organization_id = this.currentAudience.organization.id;
      data.insight_complete = this.isReady('heatmap_report');
      data.heatmap_complete = this.isReady('insight_report');
      await this.$refs.proposalUploader.startUpload({
        organization_id: data.organization_id,
        name: this.form.name,
      });
      let proposalJobID = null;
      const proposalResponse = await audienceApi.generateProposal(data);
      if (proposalResponse.status === 'success') {
        this.$store.dispatch('mqtt/addRawJob', proposalResponse.data.job);
        proposalJobID = proposalResponse.data.job.id;
      }

      if (!this.isReady('insight_report')) {
        const response = await audienceApi.prepareInsightReport(
          this.currentAudience.id,
          { proposal_job_id: proposalJobID },
        );

        if (response.status === 'success') {
          this.initiatedReportGen = true;
          this.watchJob(response.data.job.id);
          this.$store.dispatch('mqtt/addRawJob', response.data.job);
          window.mixpanel.track('Prepare Audience Report', {
            ID: this.currentAudience.id,
            Name: this.currentAudience.name,
            'Job ID': response.data.job.id,
          });
        }
      }

      if (!this.isReady('heatmap_report')) {
        const heatResponse = await audienceApi.prepareHeatmaps(
          this.currentAudience.id,
          { proposal_job_id: proposalJobID },
        );

        if (heatResponse.status === 'success') {
          this.initiatedHeatmapGen = true;
          this.watchJob(heatResponse.data.job.id);
          this.$store.dispatch('mqtt/addRawJob', heatResponse.data.job);
          window.mixpanel.track('Prepare Audience Heatmaps', {
            ID: this.currentAudience.id,
            Name: this.currentAudience.name,
            'Job ID': heatResponse.data.job.id,
          });
        }
      }
      this.proposalGenerating = false;
      this.$emit('close');
      this.clearForm();
      this.$alert('Your Campaign Proposal is being generated.');
    },
  },
};
</script>

<style scoped>
.footer-container {
  display: flex;
  flex: 0 1 0%;
  align-items: center;
  justify-content: space-between;
}

.bordered {
  margin-top: 1rem;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.button-margin {
  margin-left: 1rem;
}
</style>
