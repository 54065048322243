export const partners = {
  advangelists: 'Advangelists',
  appnexus: 'Xandr (AppNexus)',
  appnexusbidder: 'AppNexus (Bidder)',
  centro: 'Centro',
  chalkdigital: 'Chalk Digital',
  eltoro: 'ElToro',
  facebook: 'Facebook',
  flowershop: 'Flower Shop Media',
  liquidm: 'LiquidM',
  liveramp: 'LiveRamp',
  loopme: 'LoopMe',
  lotame: 'Lotame',
  neustar: 'Neustar',
  resetdigital: 'Reset Digital',
  rtbiq: 'RTBiQ',
  thetradedesk: 'The TradeDesk',
  trafficroots: 'Traffic Roots',
};

export const pixelPartners = [
  'cadent',
  'onspot',
];

export const batchedPublishPartners = [
  'advangelists',
  'lotame',
];

export const meilisearchUrl = 'https://ms.onspotdata.com';
export const meilisearchToken = '32c9406dc06e4789f4f23be4b328d52339bbd908d4b5dade4553516bb2b166cd';

export const facebookAppId = '704960199978016';
export const lookalikeLimit = 150000;

export const audienceDeviceCountLimit = 25000000;

export const preferredHashAlgorithm = 'aes128'; // Default set on new orgs
export const hashAlgorithms = [
  'aes128',
  'sha1',
  'sha256',
  'md5',
];

export const rtbiqCpmDefault = 1.5;
export const datePickerWindow = {
  normal: 6,
  historical: 12,
};

export const audienceTypes = [
  'ATTRIBUTION_RESPONDER',
  'AUTO_POLYGON',
  'CARRIER_ROUTE',
  'CENSUS_TRACT',
  'DEMOGRAPHICS',
  'DEVICE_IMPORT',
  'GEOFRAME',
  'IP',
  'LARGEAREA',
  'LATLNG',
  'MERGED',
  'MERGED_POLYGON',
  'FILTERED',
  'PIXEL',
  'POI',
  'REVERSE_APPENDS',
  'TRADE_AREA',
  'PREDICTED_MOVERS',
];

// List of download types available to device-based audiences
export const deviceDownloads = [
  'ADDRESSES',
  'ADDRESS_DEVICES',
  'ADDRESS_CENSUS',
  'ADDRESS_CARRIER_ROUTES',
  'HOUSEHOLD',
  // 'HOUSEHOLD_CENSUS',
  'DEMOGRAPHICS',
  'POLITICAL',
  // 'WORKPLACES',
  // 'WORKPLACE_CENSUS',
];

export const dwellDownloadType = 'OOH';

export const DTRHeaders = [
  {
    value: ['unique visits per day', 'daily impressions'],
    label: 'Daily',
  },
  {
    value: ['unique visits per week', 'weekly impressions'],
    label: 'Weekly',
  },
  {
    value: ['unique visits per month', 'monthly impressions'],
    label: 'Monthly',
  },
];

export const downloadTypes = [
  {
    id: 'ADDRESSES',
    label: 'Addresses',
  },
  {
    id: 'ADDRESS_COUNTS',
    label: 'Addresses + Counts',
  },
  {
    id: 'ADDRESS_DEVICES',
    label: 'Addresses + Devices',
  },
  {
    id: 'ADDRESS_DEVICE_COUNTS',
    label: 'Addresses, Devices + Counts',
  },
  {
    id: 'ADDRESS_LOCATION_DATES',
    label: 'Addresses, Locations + Date Range',
  },
  {
    id: 'ADDRESS_CENSUS',
    label: 'Addresses + Census Tracts',
  },
  {
    id: 'ADDRESS_CARRIER_ROUTES',
    label: 'Addresses + Carrier Routes',
  },
  {
    id: 'DEMOGRAPHICS',
    label: 'Complete Demographics',
  },
  {
    id: 'DEVICES',
    label: 'Devices',
  },
  {
    id: 'DEVICE_COUNTS',
    label: 'Devices + Counts',
  },
  {
    id: 'DEVICE_OBSERVATIONS',
    label: 'Device Observations',
  },
  {
    id: 'GEOFRAME_OBSERVATIONS',
    label: 'Geoframe Observations',
  },
  {
    id: 'HOUSEHOLD',
    label: 'Household Extended',
  },
  // {
  //   id: 'HOUSEHOLD_CENSUS',
  //   label: 'Households, Devices + Census Blocks',
  // },
  {
    id: 'POLITICAL',
    label: 'Political Profile',
  },
  // {
  //   id: 'WORKPLACE_CENSUS',
  //   label: 'Workplaces, Devices + Census Blocks',
  // },
  {
    id: 'WORKPLACES',
    label: 'Workplaces, Devices + Counts',
  },
  {
    id: dwellDownloadType,
    label: 'OOH Measurement',
  },
];

export const regexPatterns = {
  tagName: /^[A-Za-z0-9 ]{2,32}$/,
};

export const audienceReportTypes = [
  {
    type: 'insights',
    enum: 0,
    label: 'Insights',
    permission: 'insight_report',
  },
  {
    type: 'demographic',
    enum: 1,
    label: 'Demographic',
    permission: 'demographic_report',
  },
  {
    type: 'heatmap',
    enum: 2,
    label: 'Heatmap',
    permission: 'heatmap_report',
  },
  {
    type: 'political',
    enum: 3,
    label: 'Political',
    permission: 'political_report',
  },
];

export const dspOptions = [
  { label: '4INFO', value: '4INFO' },
  { label: 'Account Insight', value: 'Account Insight' },
  { label: 'Xandr', value: 'Xandr' },
  { label: 'Acuity Ads', value: 'Acuity Ads' },
  { label: 'Adelphic', value: 'Adelphic' },
  { label: 'Adform', value: 'Adform' },
  { label: 'Adobe Advertising Cloud', value: 'Adobe Advertising Cloud' },
  { label: 'AdRoll', value: 'AdRoll' },
  { label: 'AdTheorent', value: 'AdTheorent' },
  { label: 'Affle', value: 'Affle' },
  { label: 'Amazon DSP', value: 'Amazon DSP' },
  { label: 'Amobee', value: 'Amobee' },
  { label: 'Appier', value: 'Appier' },
  { label: 'Basis DSP', value: 'Basis DSP' },
  { label: 'Beeswax', value: 'Beeswax' },
  { label: 'Bidswitch', value: 'Bidswitch' },
  { label: 'Blis', value: 'Blis' },
  { label: 'Bulbit', value: 'Bulbit' },
  { label: 'CDK Global', value: 'CDK Global' },
  { label: 'Chalk Digital', value: 'Chalk Digital' },
  { label: 'Chalk Digital (US)', value: 'Chalk Digital (US)' },
  { label: 'Cinarra Japan', value: 'Cinarra Japan' },
  { label: 'Conversant', value: 'Conversant' },
  { label: 'DeepIntent', value: 'DeepIntent' },
  { label: 'DV360', value: 'DV360' },
  { label: 'Dynadmic', value: 'Dynadmic' },
  { label: 'EASYmedia', value: 'EASYmedia' },
  { label: 'Eskimi', value: 'Eskimi' },
  { label: 'Exponential', value: 'Exponential' },
  { label: 'FreakOut', value: 'FreakOut' },
  { label: 'FreeBit (Admind)', value: 'FreeBit (Admind)' },
  { label: 'GroundTruth', value: 'GroundTruth' },
  { label: 'Hybrid.AI', value: 'Hybrid.AI' },
  { label: 'Interactive Offers', value: 'Interactive Offers' },
  { label: 'Knorex', value: 'Knorex' },
  { label: 'Leadsmatic', value: 'Leadsmatic' },
  { label: 'Liftoff', value: 'Liftoff' },
  { label: 'LiquidM', value: 'LiquidM' },
  { label: 'Market Resource Partners', value: 'Market Resource Partners' },
  { label: 'Martin.ai', value: 'Martin.ai' },
  { label: 'Media Math', value: 'Media Math' },
  { label: 'Mediasmart', value: 'Mediasmart' },
  { label: 'MicroAd (UNIVERSE Ads)', value: 'MicroAd (UNIVERSE Ads)' },
  { label: 'Moloco', value: 'Moloco' },
  { label: 'Nextperf', value: 'Nextperf' },
  { label: 'OneView', value: 'OneView' },
  { label: 'OwnerIQ', value: 'OwnerIQ' },
  { label: 'Publishers Internationalé', value: 'Publishers Internationalé' },
  { label: 'PulsePoint', value: 'PulsePoint' },
  { label: 'Quantcast', value: 'Quantcast' },
  { label: 'Recruit Communications', value: 'Recruit Communications' },
  { label: 'Remerge', value: 'Remerge' },
  { label: 'RTB House', value: 'RTB House' },
  { label: 'SharpSpring Reach', value: 'SharpSpring Reach' },
  { label: 'Sift Media', value: 'Sift Media' },
  { label: 'Simpli.fi', value: 'Simpli.fi' },
  { label: 'So-net Media Networks (Logicad)', value: 'So-net Media Networks (Logicad)' },
  { label: 'Splicky USD', value: 'Splicky USD' },
  { label: 'Sqreem', value: 'Sqreem' },
  { label: 'SundaySky', value: 'SundaySky' },
  { label: 'Supership (ScaleOut)', value: 'Supership (ScaleOut)' },
  { label: 'Surfside', value: 'Surfside' },
  { label: 'Taboola', value: 'Taboola' },
  { label: 'TapTap', value: 'TapTap' },
  { label: 'The Trade Desk', value: 'The Trade Desk' },
  { label: 'Thinknear', value: 'Thinknear' },
  { label: 'Transsion', value: 'Transsion' },
  { label: 'UberMedia', value: 'UberMedia' },
  { label: 'Vericast', value: 'Vericast' },
  { label: 'Verizon Media DSP', value: 'Verizon Media DSP' },
  { label: 'Yeahmobi', value: 'Yeahmobi' },
  { label: 'Zeta Global', value: 'Zeta Global' },
];
