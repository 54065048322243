import apiCall from './api-call';
import { createCrud } from './common';

export default {
  name: 'ipmaid',
  ...createCrud('ipmaid'),

  addTag(id, tag) {
    return apiCall('POST', `/pixels/${id}/tag`, null, { tag });
  },
  removeTag(id, tagId) {
    return apiCall('DELETE', `/pixels/${id}/tag/${tagId}`);
  },
  activate(ids) {
    return apiCall('POST', '/pixels/bulk', null, { action: 'enable', ids });
  },
  deactivate(ids) {
    return apiCall('POST', '/pixels/bulk', null, { action: 'disable', ids });
  },
  async verify(id) {
    return apiCall('POST', `/pixels/${id}/verify`);
  },
};
