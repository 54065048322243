<template>
  <div @click.stop>
    <el-popover
      ref="popover"
      v-model="menuVisible"
      placement="bottom"
      title="Job Activity"
      trigger="manual"
      :width="600"
    >
      <div class="job-controls">
        <el-button
          v-show="sortedJobs.length > 0"
          size="mini"
          @click="handleClear"
        >
          Clear Completed & Failed Jobs
        </el-button>
      </div>
      <div
        v-if="sortedJobs.length > 0"
        v-scroller:max-height="360"
      >
        <job-activity-job
          v-for="job in sortedJobs"
          :key="job.id"
          :job="job"
          @request-close="menuVisible = false"
        />
      </div>
      <div v-else>
        No jobs, currently. This will display long-running processes such as
        counts, publishes, and more.
      </div>
    </el-popover>
    <div
      v-popover:popover
      class="menu-button"
      @click="handleMenuClick"
    >
      <el-badge
        :hidden="!jobsRunning && !erroredJobs"
        :is-dot="erroredJobs"
        :value="runningJobCount"
      >
        <i :class="iconClasses" />
      </el-badge>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import JobActivityJob from './JobActivityJob.vue';

export default {
  components: { JobActivityJob },
  data() {
    return {
      menuVisible: false,
    };
  },

  computed: {
    ...mapState('mqtt', ['jobs']),

    erroredJobs() {
      return (
        !this.jobsRunning &&
        this.jobs.some(job => job.payload.status === 'FAILED')
      );
    },

    iconClasses() {
      return {
        fa: true,
        'fa-spin': this.jobsRunning,
        'fa-cog': true,
        'gear-icon': true,
        'jobs-running': this.jobsRunning,
      };
    },

    jobsRunning() {
      return this.jobs.some(
        job =>
          job.payload.status !== 'COMPLETE' && job.payload.status !== 'FAILED',
      );
    },

    runningJobCount() {
      return this.jobs.filter(
        job =>
          job.payload.status !== 'COMPLETE' && job.payload.status !== 'FAILED',
      ).length;
    },

    sortedJobs() {
      return [...this.jobs].sort((a, b) => {
        if (a.id > b.id) return -1;
        else if (b.id > a.id) return 1;
        return 0;
      });
    },
  },

  created() {
    window.addEventListener('click', this.handleClickOut);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOut);
  },

  methods: {
    handleClear() {
      this.$store.dispatch('mqtt/clearCompletedJobs');

      if (this.jobs.length === 0) this.menuVisible = false;
    },

    handleClickOut(event) {
      if (this.menuVisible) this.menuVisible = false;
    },

    handleMenuClick() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.menu-button {
  cursor: pointer;
  outline: none;
}

.gear-icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.6em;

  &.jobs-running {
    color: $--color-primary;
  }
}

.job-controls {
  margin-bottom: 1em;
}
</style>
