/* errors array:
[
  {
    "title": "uniqueCompound",
    "detail": "uniqueCompound validation failed on name",
    "source": {
      "pointer": "name"
    }
  }
]
*/
import { upperCaseFirst } from 'upper-case-first';

const validationRules = {
  alpha: '%f is invalid.',
  max: 'Too many characters in %f.',
  min: 'Not enough characters in %f.',
  required: '%f is required.',
  uniqueCompound: 'That %f already exists in this organization.',
  unique: 'That %f already exists in this organization.',
};

export function getValidationErrors(error) {
  const errors = error.response.data.errors;
  const errorObj = {};

  for (const err of errors) {
    const errText = validationRules[err.title];

    errorObj[err.source.pointer] = errText.replace(
      /%f/g,
      // Err text starts with %f? Capitalize the field name
      /^%f/.test(errText)
        ? upperCaseFirst(err.source.pointer)
        : err.source.pointer,
    );
  }

  return errorObj;
}
