<template>
  <el-dialog
    title="Change Password"
    :visible="passwordDialog"
    :before-close="closeDialog"
  >
    <div class="password-chooser">
      <password-chooser
        ref="pwdChooser"
        :busy="busy"
        button-label="Change Password"
        @choose="changePassword"
      >
        <el-input
          v-model="currentPassword"
          placeholder="Current password"
          type="password"
        />
      </password-chooser>
    </div>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        :loading="busy"
        @click="closeDialog"
      >
        Cancel
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import PasswordChooser from './PasswordChooser.vue';
import { user as userApi } from '@/adonis-api';

export default {
  components: { PasswordChooser },
  props: {
    passwordDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      busy: false,
      currentPassword: '',
    };
  },

  computed: {
    ...mapState('user', ['userDetails']),
  },

  methods: {
    async changePassword(password) {
      this.busy = true;

      try {
        await userApi.update(this.userDetails.id, {
          old_password: this.currentPassword,
          password,
        });
        this.closeDialog();
      } catch (e) {
        if (e.response.data[0].validation === 'mis_match') {
          this.$notify.error({
            title: 'Incorrect Password',
            message: 'The current password is incorrect, please try again.',
          });
        }
      } finally {
        this.busy = false;
      }
    },

    closeDialog() {
      this.currentPassword = '';
      this.$refs.pwdChooser.reset();
      this.$emit('update:passwordDialog', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.password-chooser {
  display: flex;
  justify-content: center;
}
</style>
