import { createCrud } from './common';
import apiCall from './api-call';

export default {
  name: 'organization',
  ...createCrud('organizations'),

  family(id) {
    return apiCall('GET', `/organizations/${id}/family`);
  },

  getPublishCredentials(organizationId) {
    return apiCall('GET', `/organizations/${organizationId}/publishing`);
  },

  generatePixelOrgId(organizationId) {
    return apiCall('POST', `/organizations/${organizationId}/pixel-org-id`);
  },
};
