<template>
  <div v-if="userInitials">
    <el-dropdown @command="$emit('action', $event)">
      <span class="circle-letters">
        {{ userInitials }}
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-if="impersonating"
          icon="fa fa-eye-slash"
          command="stopImpersonation"
        >
          Stop Impersonating
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isInternal || impersonating"
          icon="fa fa-tachometer"
          index="/dashboard"
          command="dashboard"
        >
          Dashboard
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isInternal || impersonating"
          icon="fa fa-tasks"
          index="/jobs-dashboard"
          command="jobs-dashboard"
        >
          Job Dashboard
        </el-dropdown-item>
        <el-dropdown-item
          icon="fa fa-book"
          index="/user/guide"
          command="downloadGuide"
        >
          User Guide
        </el-dropdown-item>
        <el-dropdown-item
          v-if="apiAccess"
          icon="fa fa-brackets-curly"
          index="/api/documentation"
          command="apiDoc"
        >
          API Documentation
        </el-dropdown-item>
        <el-dropdown-item
          icon="fa fa-key"
          index="/user/password"
          command="changePassword"
        >
          Change Password
        </el-dropdown-item>
        <el-dropdown-item
          icon="fa fa-sign-out"
          index="/"
          command="logout"
          divided
        >
          Logout
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheUserMenu',

  computed: {
    ...mapGetters('user', ['isInternal', 'permissions', 'userInitials']),
    ...mapGetters('auth', ['impersonating']),

    apiAccess() {
      return this.permissions
        .filter(p => /_api_access$/.test(p))
        .filter(p => !/^grant_/.test(p))
        .length > 0;
    },

    debugMode() {
      return !!process.env.VUE_APP_DEBUG;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.circle-letters {
  background-color: $--color-primary;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 1.4em;
  letter-spacing: -0.05em;
  padding: 6px;
}
</style>
