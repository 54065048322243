<template>
  <el-dialog
    :before-close="handleClose"
    :title="dialogTitle"
    :visible="visible"
    :size="width"
    :fullscreen="full"
    :loading="busy"
    top="20px"
  >
    <embed
      :src="file"
      :width="screenWidth"
      :height="screenHeight"
    >
  </el-dialog>
</template>

<script>
export default {
  name: 'PdfViewer',
  props: {
    file: { type: String, default: null },
    // Element UI rules system
    full: { type: Boolean, default: false },
    overrideTitle: { type: String, default: null },
    visible: { type: Boolean, default: false },
    width: { type: String, default: '50%' },
  },

  data() {
    return {
      busy: true,
      screenWidth: 0,
      screenHeight: 0,
    };
  },

  computed: {
    dialogTitle() {
      if (this.overrideTitle) {
        return this.overrideTitle;
      }
      return 'PDF Viewer';
    },
  },

  mounted() {
    this.screenWidth = this.full ? window.innerWidth - 40 : (window.innerWidth / 2) - 40;
    this.screenHeight = window.innerHeight - 160;
  },

  methods: {
    handleClose() {
      this.$emit('close', false);
    },
  },
};
</script>
