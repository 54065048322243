<template>
  <header
    v-if="activeRoute !== '/'"
    class="header"
  >
    <div
      v-if="environment !== 'production'"
      class="environment-tag"
    >
      {{ environment }}
    </div>

    <div
      v-if="appUpdate"
      class="update-notification"
    >
      <div class="icon">
        <i class="fa fa-sync fa-5x" />
      </div>
      <div>
        <div class="message">
          A new app update is available! Please finish your work as soon as
          possible, then click the button below to refresh the page. Any unsaved
          work will be lost.
        </div>
        <div class="button">
          <el-button @click="stopImpersonation">
            Update Now
          </el-button>
        </div>
      </div>
    </div>

    <div
      class="super-nav"
      :class="{ 'colorless': colorlessHeader }"
    >
      <div class="left">
        <router-link
          :to="logoLink"
          class="logo"
        >
          <img
            v-if="logoUrl"
            :src="logoUrl"
            :alt="logoAlt"
          >
          <!-- don't hardcode width, probably move away from using SVG -->
        </router-link>
      </div>
      <div class="right">
        <the-system-load v-if="isInternal" />
        <the-changelog v-if="!hideChangelog" />
        <job-activity />
        <the-user-menu @action="userMenuAction" />
      </div>
    </div>

    <change-password-dialog :password-dialog.sync="passwordDialog" />
    <pdf-viewer
      :visible="showPdfViewer"
      override-title="User Guide"
      :file="guideUrl"
      @close="showPdfViewer = false"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { routesToNav, userDefaultPath } from '@/router/router-helpers';
import ChangePasswordDialog from './ChangePasswordDialog.vue';
import TheUserMenu from './TheUserMenu.vue';
import TheSystemLoad from './TheSystemLoad.vue';
import TheChangelog from './TheChangelog.vue';
import JobActivity from './JobActivity';
import { general } from '@/adonis-api';
import PdfViewer from './PdfViewer.vue';

export default {
  components: {
    ChangePasswordDialog,
    JobActivity,
    TheChangelog,
    TheSystemLoad,
    TheUserMenu,
    PdfViewer,
  },

  props: {
    whitelabels: { type: Array, default: () => [] },
  },

  data() {
    return {
      passwordDialog: false,
      perms: [],
      showPdfViewer: false,
      guideUrl: null,
    };
  },

  computed: {
    activeRoute() {
      return this.$route.path;
    },
    ...mapGetters('user', ['apiKey', 'isInternal', 'hideChangelog']),
    ...mapGetters('settings/apiPressure', ['apiPressure']),
    ...mapGetters('app', ['appUpdate', 'environment']),

    apiStatus() {
      if (this.queueCount === -1) return '';
      else if (this.queueCount > this.apiPressure[1]) return 'danger';
      else if (
        this.queueCount >= this.apiPressure[0] &&
        this.queueCount <= this.apiPressure[1]
      ) {
        return 'warning';
      }

      return 'success';
    },

    colorlessHeader() {
      const index = this.whitelabels.findIndex(obj =>
        window.location.href.match(new RegExp(obj.domain)),
      );
      if (index > -1) {
        return this.whitelabels[index].colorless;
      }
      return false;
    },

    logoUrl() {
      const index = this.whitelabels.findIndex(obj =>
        window.location.href.match(new RegExp(obj.domain)),
      );
      if (index > -1) {
        try {
          this.$store.dispatch('app/setLogoUrl', this.whitelabels[index].logo);
        } catch (e) {
          this.$reportError(e);
        }
        return this.whitelabels[index].logo;
      }
      return '/images/onspot-logo.svg';
    },

    logoLink() {
      // return userDefaultPath(null, true);
      return userDefaultPath(this.$router);
    },

    logoAlt() {
      const index = this.whitelabels.findIndex(obj =>
        window.location.href.match(new RegExp(obj.domain)),
      );
      if (index > -1) {
        return this.whitelabels[index].organization;
      }

      return 'OnSpot';
    },

    routes() {
      return routesToNav(this.$router.options.routes);
    },

    systemLoad() {
      if (this.apiStatus === '') return 'Unknown';
      else if (this.apiStatus === 'danger') return 'Heavy';
      else if (this.apiStatus === 'warning') return 'Moderate';
      return 'Light';
    },
  },

  methods: {
    async userMenuAction(command) {
      switch (command) {
        case 'apiDoc':
          this.$router.push('/api/documentation');
          break;
        case 'dashboard':
          this.$router.push('/dashboard');
          break;
        case 'jobs-dashboard':
          this.$router.push('/jobs-dashboard');
          break;
        case 'stopImpersonation':
          this.$store.dispatch('auth/stopImpersonation');
          break;

        case 'changePassword':
          this.passwordDialog = true;
          break;

        case 'downloadGuide':
          this.guideUrl = process.env.VUE_APP_PUBLIC_BUCKET_PREFIX + '/User-Guide-v4.7.1.pdf';
          this.showPdfViewer = true;
          break;

        case 'logout':
          this.$store.dispatch('auth/logout');
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #eef1f6;
  position: relative;
}

.super-nav.colorless {
  background: #f9f9f9;
}

.environment-tag {
  background: rgba(255, 170, 0, 0.7);
  bottom: 0;
  font-size: 0.8em;
  font-weight: bold;
  opacity: 0.8;
  padding: 8px 16px;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-transform: uppercase;
  z-index: 10;
}

.logo {
  height: 100%;
  position: relative;

  > img {
    height: 40px;
  }
}

.update-notification {
  background: #20a0ff;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  left: 0;
  padding: 24px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  .icon {
    margin-right: 32px;
  }

  .button {
    margin-top: 16px;
  }
}

.nav-row {
  align-items: center;
  display: flex;
}

.nav-menu {
  background: transparent;
  border-bottom: none;
}

.el-menu--horizontal {
  display: block;
}

.user-actions {
  .el-submenu {
    .el-menu-item {
      min-width: auto;
    }
  }
}

.super-nav {
  align-items: center;
  background-color: hsl(218, 31%, 85%);
  box-sizing: border-box;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 12px 12px;

  > div {
    align-items: center;
    display: flex;

    > div:not(:last-of-type) {
      margin-right: 12px;
    }
  }
}
</style>
