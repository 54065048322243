<template>
  <div v-loading="busy">
    <div>
      <el-select
        v-model="chosenPartner"
        class="partner"
      >
        <el-option
          label="- Select Publish Destination -"
          value=""
          :selected="!chosenPartner"
        />
        <el-option
          v-for="(partner, partnerId) in partners"
          :key="partnerId"
          :label="partner.label"
          :value="partnerId"
        />
      </el-select>
    </div>
    <div v-if="chosenPartner && !singleCredential">
      <label>
        Credentials:
        <el-select
          v-model="credentialId"
          filterable
          @change="handleSelectCredentials"
        >
          <el-option
            v-for="credential in partners[chosenPartner].credentials"
            :key="credential.id"
            :label="credential.name"
            :value="credential.id"
          />
        </el-select>
      </label>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _pick from 'lodash/pick';
import { organization as organizationApi } from '@/adonis-api';
import { isEmpty, isDeviceAudience } from '@/helpers';

export default {
  props: {
    defaultCredentialId: { type: Number, default: 0 },
    defaultPartner: { type: String, default: '' },
    organization: { type: Object, default: () => ({}) },
    audienceType: { type: String, default: 'GEOFRAME' },
  },

  data() {
    return {
      busy: true,
      credentialId: 0,
      chosenPartner: '',
      partners: {},
    };
  },

  computed: {
    ...mapGetters('user', ['permissions']),
    ...mapState('user', ['userDetails']),

    singleCredential() {
      if (isEmpty(this.partners)) return false;

      return this.partners[this.chosenPartner].credentials.length === 1;
    },
  },

  watch: {
    organization: {
      handler(val) {
        if (val && !isEmpty(val)) {
          this.initCredentials();
        } else {
          this.credentialId = 0;
          this.chosenPartner = '';
          this.partners = {};
        }
      },
      immediate: true,
    },
    chosenPartner(value) {
      if (isEmpty(this.partners)) return;
      let defaultCred = this.partners[value].credentials.find(c => c.default);

      if (!defaultCred) {
        // no default set? here, take this!
        defaultCred = this.partners[value].credentials[0];
      }

      this.credentialId = defaultCred.id;
      this.handleSelectCredentials(defaultCred.id);
    },
  },

  methods: {
    // TODO: Cannot assume all publishers will have credentials (ie: neustar)
    handleSelectCredentials(credentialId) {
      const data = this.partners[this.chosenPartner].credentials
        .find(c => c.id === credentialId);

      this.$emit('select', { ..._pick(data, ['id', 'scheduleRules', 'ipPublishing']), partner: this.chosenPartner });
    },

    async initCredentials() {
      this.credentials = [];
      this.busy = true;

      const pubCredentials = await organizationApi.getPublishCredentials(
        this.organization.id,
      );
      this.busy = false;

      const allowedPublishers = this.permissions
        ? this.permissions
          .filter(i => i.substr(0, 8) === 'publish_')
          .map(i => i.slice(8))
        : [];
      const allowedCredentials = pubCredentials.filter(pub =>
        allowedPublishers.includes(pub.machine_name),
      );

      for (const cred of allowedCredentials) {
        // Disallow neustar for non-polygon audiences
        if (cred.machine_name === 'neustar' && isDeviceAudience(this.audienceType)) continue;
        if (cred.machine_name === 'rtbiq' && !this.userDetails.rtbiq_user_id) continue;

        if (!this.partners[cred.machine_name]) {
          this.partners[cred.machine_name] = {
            label: cred.partner,
            id: cred.partner_id,
            credentials: [],
          };
        }
        this.partners[cred.machine_name].credentials.push({
          ...cred.credentials,
          id: cred.id,
          scheduleRules: cred.schedule_rules,
          ipPublishing: cred.ip_publishing,
          ...(cred.is_default ? { default: true } : {}),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.el-select.partner .el-input input.el-input__inner {
  width: 240px !important;
}
</style>
