import moment from 'moment';
import filesize from 'filesize';

export default {
  dateString(value, { includeTime, includeSeconds, includeTZ } = {}) {
    return value
      ? moment(value).format('YYYY-MM-DD' + (includeTime ? includeSeconds ? ' h:mm:ssa' : ' h:mma' : '') + (includeTZ ? ' (UTCZZ)' : ''))
      : 'N/A';
  },
  filesize(value) {
    return filesize(value, { spacer: '' });
  },
  fullName(value) {
    if (!value || (!value.first_name && !value.last_name)) return '(unknown)';
    return `${value.first_name} ${value.last_name}`;
  },
  numeral(value, format) {
    return typeof value === 'number' ? value.toLocaleString() : value;
  },
  keyCount(value) {
    return Object.keys(value).length;
  },
  handleNull(value) {
    return value == null ? 0 : value;
  },
};
