// import DemographicSelector from '../components/demographics/DemographicSelector.vue';
import AudienceLibraryWrapper from '../components/audience/AudienceLibraryWrapper.vue';

export default [
  {
    path: '/audiences/library',
    component: AudienceLibraryWrapper,
    redirect: '/audiences/library/list',
    meta: {
      group: 'Audiences',
      permissions: ['view_audiences'],
    },
    children: [
      {
        path: 'list',
        meta: {
          group: 'Audiences',
          title: 'Audience Library',
          permissions: ['view_audiences'],
        },
        component: () =>
          import(/* webpackChunkName: "audienceLibrary" */ '../components/pages/audiences/PageAudienceLibrary.vue'),
      },
      {
        name: 'audience-detail',
        path: ':id',
        meta: {
          group: 'Audiences',
          title: 'Audience Detail',
          permissions: ['view_audiences'],
          exclude: true,
        },
        component: () =>
          import(/* webpackChunkName: "audienceDetail" */ '../components/pages/audiences/PageAudienceDetail.vue'),
        beforeEnter: (to, from, next) => {
          if (isNaN(to.params.id)) {
            return next(false);
          }
          next();
        },
      },
    ],
  },
  {
    path: '/audiences/create',
    meta: {
      group: 'Audiences',
      title: 'Create Geoframe Audience',
      permissions: ['create_geoframe_audiences'],
    },
    component: () =>
      import(/* webpackChunkName: "audienceCreate" */ '../components/pages/audiences/PageAudienceCreate.vue'),
  },
  {
    path: '/audiences/predicted-movers-create',
    meta: {
      group: 'Audiences',
      title: 'Create Predicted Movers',
      permissions: ['create_predicted_movers_audience'],
    },
    component: () =>
      import(/* webpackChunkName: "audienceCreate" */ '../components/pages/audiences/PageAudiencePredictedCreate.vue'),
  },
  {
    path: '/audiences/demographic-create',
    meta: {
      group: 'Audiences',
      title: 'Create Demographics Audience',
      permissions: ['create_demographic_audiences'],
    },
    component: () =>
      import(/* webpackChunkName: "demographicSelector" */ '../components/demographics/DemographicSelector.vue'),
  },
  {
    path: '/audiences/qs-create',
    meta: {
      group: 'Audiences',
      title: 'Create Quick Select Audience',
      permissions: ['create_quickselect_audiences'],
    },
    component: () =>
      import(/* webpackChunkName: "audiencePoiCreate" */ '../components/pages/audiences/PagePOIAudienceCreate.vue'),
  },
];
