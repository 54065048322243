import apiCall from './api-call';

export default {
  getApiQueueStatus() {
    return apiCall('GET', '/api-queue-status', null, null, { fullPath: true });
  },

  getIntercomHash() {
    return apiCall('GET', '/intercom-hash');
  },

  getJobTypes() {
    return apiCall('GET', '/job-types', null, null, { fullPath: true });
  },

  getNewestChangelogId() {
    return apiCall('GET', '/newest-changelog-id');
  },

  getNotificationEventList() {
    return apiCall('GET', '/notification-event-list');
  },

  reportAddress(data) {
    return apiCall('POST', '/bad-addresses', null, data);
  },
};
