import apiCall from './api-call';
import { createCrud } from './common';

export default {
  name: 'audience',
  ...createCrud('audiences'),

  addTag(audienceId, tag) {
    return apiCall('POST', `/audiences/${audienceId}/tag`, null, { tag });
  },

  cancelCloneSchedule(audienceId) {
    return apiCall('DELETE', `/audiences/${audienceId}/clone-schedule`);
  },

  clearPublishSchedule(audienceId, uuid) {
    return apiCall(
      'DELETE',
      `/audiences/${audienceId}/publish-schedule/${uuid}`,
    );
  },

  async clone(audienceId, data) {
    const result = await apiCall(
      'POST',
      `/audiences/${audienceId}/clone`,
      null,
      data,
    );

    return result.data.job;
  },

  createTradeAreaAudience(data) {
    return apiCall('POST', '/audiences/tradearea', null, data);
  },

  createDemographicAudience(data) {
    return apiCall('POST', '/demographics', null, data);
  },

  createPixelAudience(data) {
    return apiCall('POST', '/audiences/pixel', null, data);
  },

  deleteDownload(downloadId) {
    return apiCall('DELETE', `/downloads/${downloadId}`);
  },

  exportGeoframeInfo(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}/geoframe-info`);
  },

  exportGeojson(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}/export`);
  },

  extendPublishSchedule(audienceId, uuid, data) {
    return apiCall(
      'PUT',
      `/audiences/${audienceId}/publish-schedule/${uuid}`,
      null,
      data,
    );
  },
  getAudienceById(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}`);
  },
  getAudienceJourneyReport(uuid) {
    return apiCall('GET', `/audiences/journey/${uuid}`, null, null, {
      noAuth: true,
    });
  },
  getAvailableDownloads(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}/downloads`);
  },

  getCloneSchedule(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}/clone-schedule`);
  },

  getDemographicsCount(selections) {
    return apiCall('POST', '/demographics/count', null, selections);
  },

  getDemographicsQuery(selections) {
    return apiCall('POST', '/demographics/query', null, selections);
  },

  getDemographicSelectors() {
    return apiCall('GET', '/demographics/selectors');
  },

  getGeoframes(audienceId, options) {
    return apiCall('GET', `/audiences/${audienceId}/geoframes`, options);
  },

  getJobs(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}/jobs`);
  },

  getPoiCount(data) {
    return apiCall('POST', '/audiences/quick-select/preview', null, data);
  },

  getPublishSchedule(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}/publish-schedule`);
  },

  getReportUrls(hashId) {
    return apiCall('GET', `/audience-report/${hashId}`, null, null, {
      noAuth: true,
    });
  },

  getHeatmapUrl(audienceId) {
    return apiCall('GET', `/audiences/${audienceId}/heatmap-url`, null, null, {
      noAuth: true,
    });
  },

  importLatLong(data) {
    return apiCall('POST', '/audiences/import/latlng', null, data);
  },

  mergeAudiences(data) {
    return apiCall('POST', '/audiences/merge', null, data);
  },

  filterAudiences(data) {
    return apiCall('POST', '/audiences/filter', null, data);
  },

  prepareHeatmaps(audienceId, options) {
    return apiCall('POST', `/audiences/${audienceId}/prepare-heatmaps`, null, options);
  },

  prepareInsightReport(audienceId, options) {
    return apiCall('POST', `/audiences/${audienceId}/prepare-insight-report`, null, options);
  },

  generateProposal(data) {
    return apiCall('POST', '/audiences/proposals/generate', null, data);
  },

  getProposals(options) {
    return apiCall('POST', '/audiences/proposals/retrieve', null, options);
  },

  removeProposal(options) {
    return apiCall('POST', '/audiences/proposals/remove', null, options);
  },

  getProposalData(options) {
    return apiCall('POST', '/audiences/proposals/data', null, options);
  },

  preparePoliticalReport(audienceId) {
    return apiCall('POST', `/audiences/${audienceId}/prepare-political-report`);
  },

  async requestJourneyReport(audienceId) {
    return apiCall('POST', `/audiences/${audienceId}/prepare-journey-report`);
  },

  createPredictedMovers(options) {
    return apiCall('POST', '/audiences/predictedmovers', null, options);
  },

  async bulkPublish(options) {
    const result = await apiCall(
      'POST',
      '/audiences/bulk-publish',
      null,
      options,
    );

    return result.data;
  },

  async publish(audienceId, options) {
    const result = await apiCall(
      'POST',
      `/audiences/${audienceId}/publish`,
      null,
      options,
    );

    return result.data.jobs;
  },

  reprepare(audienceId, options) {
    return apiCall('GET', `/audiences/quick-select/refresh/${audienceId}`);
  },

  async splitAndPublish(audienceId, options) {
    const result = await apiCall(
      'POST',
      `/audiences/${audienceId}/split-publish`,
      null,
      options,
    );

    return result.data.jobs;
  },

  quickSelectSearch(options) {
    return apiCall('POST', '/audiences/quick-select/search', null, options);
  },

  removeTag(audienceId, tagId) {
    return apiCall('DELETE', `/audiences/${audienceId}/tag/${tagId}`);
  },

  async requestDownload(
    audienceId,
    { hashed, chunked, type, averageDwellTimeDistribution, adDuration, headers, visitSpan, visitMultiplier },
  ) {
    const result = await apiCall(
      'POST',
      `/audiences/${audienceId}/download`,
      null,
      { hashed, chunked, type, averageDwellTimeDistribution, adDuration, headers, visitSpan, visitMultiplier },
    );

    return result.data.job;
  },

  savePoiAudience(data) {
    return apiCall('POST', '/audiences/quick-select', null, data);
  },
};
