<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible="showDialog === 'extend'"
      :before-close="closeDialog"
    >
      <p>To discover the number of additional devices associated with this audience, please click the Household Extension button below.</p>
      <p v-if="progress > 0 || extensionCountPending">
        <strong>Status:</strong>
        <span v-if="progress < 100">
          <i class="fa fa-sync fa-spin" /> Working...
          <el-progress
            v-if="audience.geoframeCount > 1"
            :percentage="progress"
          />
        </span>
        <span v-show="progress >= 100">
          Audience can be extended by <span ref="extensionCountContainer">
            0
          </span> device(s).<br>
          <span v-if="extensionCount > 0">
            To add these devices to your audience please contact your Administrator.
          </span>
        </span>
      </p>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeDialog">
          {{ cancelBtnLabel }}
        </el-button>
        <el-button
          type="primary"
          :disabled="progress >= 100"
          :loading="extensionCountPending"
          @click="extend"
        >
          Household Extension
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { extendAudience } from '../../methods/audiences';
import { CountUp } from 'countup.js';

let handle;

export default {
  props: {
    audience: { type: Object, required: true },
    showDialog: { type: String, required: true },
  },

  data() {
    return {
      extensionCountPending: false,
      extensionCount: 0,
      progress: 0,
      asyncIds: [],
    };
  },

  computed: {
    cancelBtnLabel() {
      return this.progress >= 100 ? 'Close' : 'Cancel';
    },
    dialogTitle() {
      return `Extend "${this.audience.title}" Audience`;
    },
  },

  watch: {
    extensionCount(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (oldValue === null) oldValue = 0;
        const numberAnimation = new CountUp(
          this.$refs.extensionCountContainer,
          newValue,
          {
            startVal: oldValue,
            duration: 1,
          },
        );
        numberAnimation.start();
      }
    },
  },

  methods: {
    extend() {
      this.extensionCountPending = true;

      extendAudience
        .callPromise({
          audienceId: this.audience._id,
        })
        .then(asyncIds => {
          this.asyncIds = asyncIds;
          handle = this.$subscribe('apiRequests', this.asyncIds);
        })
        .catch(err => {
          this.$reportError(err);
        });
    },

    closeDialog() {
      this.extensionCount = 0;
      this.progress = 0;
      this.extensionCountPending = false;
      if (handle) handle.stop();
      this.$emit('close');
    },
  },

  // meteor: {
  //   apiRequests: {
  //     params() {
  //       return {
  //         asyncIds: this.asyncIds,
  //       };
  //     },
  //     update({ asyncIds }) {
  //       const apiRequests = ApiRequests.find({
  //         _id: { $in: asyncIds },
  //         ready: true,
  //       }).fetch();

  //       if (apiRequests.length > 0) {
  //         this.progress = +(apiRequests.length *
  //           100 /
  //           this.asyncIds.length
  //         ).toFixed(2);

  //         if (this.progress >= 100) {
  //           handle.stop();
  //           this.asyncIds = [];
  //           this.extensionCountPending = false;
  //           this.extensionCount = _sumBy(apiRequests.map(item => item.responsePayload[0]), 'count');
  //         }
  //       }
  //     },
  //   },
  // },
};
</script>

<style>
.el-progress-bar {
  padding-right: 85px;
  margin-right: -75px;
}
</style>
