<template>
  <el-dialog
    title="Create Trade Area Audience"
    :visible="showDialog === 'tradeArea'"
    :before-close="handleClose"
    center
  >
    <el-form>
      <el-form-item label="Audience Name:">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="Radius (miles):">
        <br>
        <el-radio
          v-for="option in radius_options"
          :key="option"
          v-model="form.radius"
          :label="option"
        >
          {{ option }}
        </el-radio>
      </el-form-item>
      <el-form-item label="Location Type(s):">
        <br>
        <el-checkbox-group v-model="options">
          <el-checkbox
            label="Households"
          />
          <el-checkbox
            label="Workplaces"
          />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <label>Organization: </label>
        <org-picker :organization.sync="organization" />
      </el-form-item>
      <el-form-item>
        <div class="form-control">
          <label>
            Tags
          </label>
          <tag-picker :tags.sync="form.tags" />
        </div>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          :loading="busy"
          @click="onSubmit"
        >
          Create
        </el-button>
        <el-button @click="handleClose">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import TagPicker from '../global/TagPicker';
import OrgPicker from '../global/OrgPicker';
import { audience as audienceApi } from '@/adonis-api';

export default {
  name: 'AudienceCreationForm',
  components: {
    TagPicker,
    OrgPicker,
  },
  props: {
    audience: { type: Object, required: true },
    showDialog: { type: String, required: true },
  },

  data() {
    return {
      radius_options: [1, 2, 5, 10],
      busy: false,
      options: ['Households', 'Workplaces'],
      organization: null,
      form: {
        name: '',
        tags: [],
        radius: 1,
        includeHouseholds: null,
        includeWorkplaces: null,
        audience_id: null,
        count: null,
      },
    };
  },

  computed: {
  },

  watch: {
    showDialog(value) {
      if (!value) return;
      this.organization = this.audience.organization;

      this.form.name = this.audience.name + ' Trade Area';
    },
  },

  methods: {
    handleClose() {
      this.form = {
        name: '',
        tags: [],
        radius: 1,
        includeHouseholds: null,
        includeWorkplaces: null,
        audience_id: null,
        count: null,
        organization_id: null,
      };
      this.organization = null;
      this.options = ['Households', 'Workplaces'];
      this.busy = false;
      this.$emit('close');
    },

    async onSubmit() {
      if (this.options.length === 0) {
        this.$alert('You must select one or both location types.');
        return;
      }
      this.form.includeHouseholds = this.options.includes('Households');
      this.form.includeWorkplaces = this.options.includes('Workplaces');
      this.form.audience_id = this.audience.id;
      this.form.organization_id = this.organization.id;
      this.form.count = this.audience.count;
      this.busy = true;
      const res = await audienceApi.createTradeAreaAudience(this.form);
      if (res.status === 'success') {
        this.$store.dispatch('mqtt/addRawJob', res.data.job);
        this.$store.dispatch('event', {
          eventType: 'ADDED_AUDIENCE',
        });
        this.handleClose();
      } else {
        this.$alert('There was a problem creating your Trade Area Audience please contact admin.');
      }
      this.busy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  :deep( .el-tag) {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
  :deep( .el-select) {
    width: 60%;
  }
  :deep( .el-switch) {
    margin-left: 1em;
  }
}
:deep( .el-upload) {
  width: 100% !important;
  .el-upload-dragger {
    width: 100% !important;
  }
}

.bordered {
  margin-top: 1rem;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.help-text p {
  font-size: 0.85rem;
  pre {
    font-size: 1rem;
    background: #ddd;
    padding: 5px;
    border: 1px solid #666;
  }
}
</style>
