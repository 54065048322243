import apiCall from './api-call';
import { createCrud } from './common';

export default {
  name: 'attribution',
  ...createCrud('attribution-schedule'),

  archive(id) {
    return apiCall('DELETE', `/attribution-schedule/${id}/archive`, null, {});
  },

  createReport(data) {
    return apiCall('POST', '/attribution-schedule', null, data);
  },

  search(options) {
    return apiCall('GET', '/attribution/search', {
      ...options,
      recur: true,
    });
  },
  extendSchedule(id, value) {
    return apiCall('POST', '/attribution-schedule/extend/' + id, null, { value });
  },
};
