<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible="showDialog === 'download'"
      :before-close="handleClose"
      width="75%"
    >
      <div
        v-if="(accessibleTypes.length <= 0)"
      >
        You do not have access to any type of download. If you believe this is an error, please contact the system administrator.
      </div>
      <div v-else>
        <el-table
          v-loading="busy"
          :data="downloadData"
          :row-class-name="tableRowClassName"
          size="mini"
          style="width: 100%"
        >
          <el-table-column
            prop="type"
            label="Download Type"
          >
            <template slot-scope="{ row }">
              {{ row | typeLabel }}
            </template>
          </el-table-column>
          <el-table-column
            label="Created"
          >
            <template slot-scope="{ row }">
              {{ row.created_at | dateString({ includeTime: true }) }}
            </template>
          </el-table-column>
          <!-- only show if user has download_unhashed perm -->
          <el-table-column
            v-if="unhashedAccess"
            label="Hashed"
          >
            <template slot-scope="{ row }">
              <i
                v-if="row.hashed"
                class="fa fa-check"
              />
              <i
                v-else
                class="fa fa-times"
              />
            </template>
          </el-table-column>
          <el-table-column label="Status">
            <template slot-scope="{ row }">
              {{ row.status || 'COMPLETE' }}
            </template>
          </el-table-column>
          <el-table-column label="Operations">
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template slot-scope="{ row }">
              <div v-if="!row.status">
                <el-button
                  size="mini"
                  @click="downloadFile(row.file.signed_url)"
                >
                  Download
                </el-button>
                <el-button
                  class="danger"
                  size="mini"
                  type="text"
                  @click="deleteDownload(row.id)"
                >
                  Delete
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-form
          inline
          class="new-download"
        >
          <div>
            <el-checkbox
              v-if="unhashedAccess"
              v-model="requestUnhashed"
            >
              Unhashed IDs
            </el-checkbox>
            <el-checkbox
              v-model="requestChunked"
            >
              Chunked Output
              <help-tip ref="chunkedTip">
                If selected, the resulting output will be a zip file containing one or
                more csv files, each with at most one million lines so that it can be
                opened in common spreadsheet software.
              </help-tip>
            </el-checkbox>
          </div>
          <div>
            <el-form-item>
              <el-select
                v-model="downloadType"
                placeholder="Choose download type"
              >
                <el-option
                  v-for="atype in accessibleTypes"
                  :key="atype.id"
                  :label="atype.label"
                  :value="atype.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-popover
                ref="busyPopover"
                v-model="showNoDownloadMsg"
                content="This is lame"
                placement="top-start"
                trigger="manual"
                :width="320"
              >
                A download of that type is already either running or has been processed and
                is ready for download. You must delete the download before re-running it.
              </el-popover>
              <el-button
                v-popover:busyPopover
                type="primary"
                @blur.native="showNoDownloadMsg = false"
                @click="requestDownload"
              >
                Process
              </el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <span>
          <small>
            <strong>Note:</strong> You may close this window before requested download processing completes.
          </small>
        </span>

        <el-button @click="handleClose">
          Close
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { audience as audienceApi } from '@/adonis-api';
import { isDeviceAudience } from '@/helpers';
import { deviceDownloads, downloadTypes, dwellDownloadType } from '@/constants';
import jobWatcher from '@/mixins/job-watcher';
import HelpTip from '../global/HelpTip.vue';

export default {
  components: { HelpTip },
  filters: {
    typeLabel(record) {
      return downloadTypes.find(t => t.id === record.type).label;
    },
  },
  mixins: [jobWatcher],
  props: {
    audience: { type: Object, required: true },
    showDialog: { type: String, required: true },
  },

  data() {
    return {
      availableDownloads: [],
      busy: false,
      downloadJobs: [],
      downloadType: '',
      requestChunked: false,
      requestUnhashed: false,
      showNoDownloadMsg: false,
      types: downloadTypes,
    };
  },

  computed: {
    ...mapGetters('mqtt', ['getJobs']),
    ...mapGetters('user', ['permissions']),

    accessibleTypes() {
      const perms = this.permissions
        .filter(p => p.startsWith('download_') && p !== 'download_unhashed')
        .map(p => p.replace('download_', '').toUpperCase());

      if (isDeviceAudience(this.audience.type)) {
        return this.types.filter(t => perms.includes(t.id)).filter(t => deviceDownloads.includes(t.id));
      }

      // Omit Addresses & Devices for polygon audiences.
      return this.types.filter(t => t.id !== 'ADDRESS_DEVICES' && perms.includes(t.id));
    },

    alreadyRequested() {
      const download =
        this.availableDownloads.find(d => d.type === this.downloadType) ||
        this.downloadJobs.find(j => j.type === this.downloadType);

      return download && download.hashed === !this.requestUnhashed;
    },

    dialogTitle() {
      return `Download "${this.audience.name}" Audience Data`;
    },

    downloadData() {
      const data = [...this.availableDownloads, ...this.downloadJobs];
      return data.filter(i => i.type !== dwellDownloadType);
    },

    unhashedAccess() {
      return this.permissions.includes('download_unhashed');
    },

    watchedDownloadJobs() {
      return this.getJobs(this.watchJobIds);
    },
  },

  watch: {
    async audience(audience) {
      if (this.showDialog !== 'download' || !audience.id) return;
      this.getAvailableDownloads(audience.id);
    },

    downloadType() {
      this.showNoDownloadMsg = false;
    },

    watchedJobs: {
      handler(jobs) {
        const removeJobIds = [];

        for (const jobDetails of jobs) {
          const job = this.downloadJobs.find(j => j.id === jobDetails.id);

          if (job) {
            const { payload } = jobDetails;

            job.status = payload.status;

            if (payload.status === 'COMPLETE') {
              this.availableDownloads.push(payload.result.download);
              removeJobIds.push(job.id);
            }
          }
        }

        if (removeJobIds.length > 0) {
          this.downloadJobs = this.downloadJobs.filter(
            j => !removeJobIds.includes(j.id),
          );
        }
      },
      deep: true,
    },
  },

  methods: {
    async getAvailableDownloads(audienceId) {
      this.busy = true;
      const result = await audienceApi.getAvailableDownloads(audienceId);
      this.availableDownloads = result.downloads;
      this.downloadJobs = result.jobs;
      this.busy = false;

      if (this.downloadJobs.length > 0) {
        this.setWatchedJobs(this.downloadJobs.map(j => j.id));
      }
    },

    handleClose() {
      // this.availableDownloads = [];
      this.downloadType = '';
      this.$emit('close');
    },

    async deleteDownload(downloadId) {
      try {
        await this.$confirm(
          'Are you sure you want to delete this download?',
          'Delete',
        );
        await audienceApi.deleteDownload(downloadId);
        this.availableDownloads = this.availableDownloads.filter(
          d => d.id !== downloadId,
        );
        this.$message('Download has been deleted.');
      } catch (e) {
        if (e !== 'cancel') {
          this.$reportError(e);
        }
      }
    },

    downloadFile(url) {
      window.location.href = url;
    },

    async requestDownload() {
      if (this.alreadyRequested) {
        this.showNoDownloadMsg = true;
        return;
      }

      this.showNoDownloadMsg = false;

      try {
        // Always hashed, unless user has appropriate
        // permission, and explicitly requests UNhashed
        let hashed = true;
        if (this.unhashedAccess) {
          hashed = !this.requestUnhashed;
        }
        const job = await audienceApi.requestDownload(this.audience.id, {
          hashed,
          chunked: this.requestChunked,
          type: this.downloadType,
        });

        this.$store.dispatch('mqtt/addRawJob', job);
        this.downloadJobs.push({
          ...job,
          hashed,
          chunked: this.requestChunked,
          type: this.downloadType,
        });
        this.watchJob(job.id);

        window.mixpanel.track('Download Audience', {
          ID: this.audience.id,
          Name: this.audience.name,
          Hashed: hashed,
          Chunked: this.requestChunked,
          Type: this.downloadType,
        });
      } catch (e) {
        switch (e.error) {
          case 'no-such-audience':
            this.$notify.error({
              title: 'No Such Audience',
              message:
                "That audience doesn't exist, or it used to but is no longer available.",
            });
            break;
          case 'date-range-error':
            this.$notify.error({
              title: 'Date Range Error',
              message:
                'Unable to process download on this audience because the dates are out of range.',
              duration: 60,
            });
            break;
          default:
            break;
        }

        this.handleClose();
      }
    },

    tableRowClassName({ row }) {
      if (!row.hashed) {
        return 'unhashed-download';
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
@import '~$element';

.new-download {
  margin-top: 1.5em;
}

.dialog-footer small {
  margin-right: 10px;
}

.danger {
  color: $--color-danger;
}

.unhashed-download {
  background: lighten($--color-warning, 35%) !important;
}
</style>
