import apiCall from './api-call';
import { createCrud } from './common';

export default {
  name: 'order',
  ...createCrud('orders'),

  getItems(orderId, options) {
    return apiCall('GET', `/orders/${orderId}/items`, options);
  },

  reportOrderItem(itemId, { orderId, status, reason }) {
    return apiCall('PUT', `/orders/${orderId}/items/${itemId}`, null, {
      status,
      reason,
    });
  },

  updateOrderItem(itemId, data) {
    return apiCall(
      'PUT',
      `/orders/${data.orderId}/items/${itemId}`,
      null,
      data,
    );
  },
};
