<template>
  <div class="changelog">
    <el-badge
      :hidden="!newItems"
      is-dot
    >
      <i
        :class="iconClasses"
        @click="showChangelog"
      />
    </el-badge>
  </div>
</template>

<script>
import { system } from '@/adonis-api';
import { getMqttClient } from '@/mqtt';

export default {
  name: 'TheChangelog',
  data() {
    return {
      newestId: null,
    };
  },

  computed: {
    iconClasses() {
      return {
        fal: true,
        'fa-newspaper': true,
        'changelog-icon': true,
      };
    },

    logClasses() {
      return {
        log: true,
        visible: this.showChangelog,
      };
    },

    newItems() {
      return Number(this.newestId) > Number(this.$store.state.user.userDetails.changelog);
    },
  },

  async mounted() {
    this.newestId = await system.getNewestChangelogId();

    const client = await getMqttClient();

    client.subscribe('changelog-updated');
    client.on('message', (topic, message) => {
      this.newestId = Number(message);
    });
  },

  methods: {
    showChangelog() {
      this.$router.push('/changelog');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.changelog {
  cursor: pointer;
  position: relative;
}

.changelog-icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.6em;
}

.log {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  height: 320px;
  position: absolute;
  right: 0;
  transition: 0.3s ease;
  width: 420px;
  z-index: 900;

  &.visible {
    margin-top: 10px;
    opacity: 1;
  }
}
</style>
